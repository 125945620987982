import { NgModule } from "@angular/core";

import {
    SideNavModule,
    IconModule,
    ThemeModule,
    UIShellModule,
    GridModule,
    TableModule,
    PaginationModule,
    InputModule,
    ButtonModule,
    CheckboxModule,
    NumberModule,
} from "carbon-components-angular";

@NgModule({
    imports: [
        SideNavModule,
        ThemeModule,
        SideNavModule,
        UIShellModule,
        IconModule,
        TableModule,
        GridModule,
        PaginationModule,
        InputModule,
        ButtonModule,
        CheckboxModule,
        NumberModule
    ],
    exports: [
        SideNavModule,
        ThemeModule,
        SideNavModule,
        UIShellModule,
        IconModule,
        GridModule,
        TableModule,
        PaginationModule,
        InputModule,
        ButtonModule,
        CheckboxModule,
        NumberModule
    ]
})
// !!!!!!
// use this with precaution as it may not be the best practice (you might end up with a lot of UNWANTED imports in your module)
// !!!!!!
export class CarbonModule { }
