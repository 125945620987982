import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, HostBinding, TemplateRef, ContentChildren, forwardRef, HostListener, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from 'carbon-components-angular/icon';
import { IconModule } from 'carbon-components-angular/icon';

/**
 * Used to emit changes performed on a `Radio`.
 */
function Radio_input_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "input", 4);
    i0.ɵɵlistener("change", function Radio_input_0_Template_input_change_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onChange($event));
    })("click", function Radio_input_0_Template_input_click_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.onClick($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("checked", ctx_r0.checked)("disabled", ctx_r0.disabled || ctx_r0.disabledFromGroup)("name", ctx_r0.name)("id", ctx_r0.id)("required", ctx_r0.required);
    i0.ɵɵattribute("value", ctx_r0.value)("aria-labelledby", ctx_r0.ariaLabelledby);
  }
}
function Radio_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 5);
  }
}
const _c0 = a0 => ({
  "cds--skeleton": a0
});
const _c1 = ["*"];
function RadioGroup_legend_1_1_ng_template_0_Template(rf, ctx) {}
function RadioGroup_legend_1_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RadioGroup_legend_1_1_ng_template_0_Template, 0, 0, "ng-template", 8);
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r4.legend);
  }
}
function RadioGroup_legend_1_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵtextInterpolate(ctx_r5.legend);
  }
}
function RadioGroup_legend_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "legend", 5);
    i0.ɵɵtemplate(1, RadioGroup_legend_1_1_Template, 1, 1, null, 6)(2, RadioGroup_legend_1_ng_template_2_Template, 1, 1, "ng-template", null, 7, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const _r6 = i0.ɵɵreference(3);
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.isTemplate(ctx_r0.legend))("ngIfElse", _r6);
  }
}
function RadioGroup_ng_container_4_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r8 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r8.invalidText);
  }
}
function RadioGroup_ng_container_4_4_ng_template_0_Template(rf, ctx) {}
function RadioGroup_ng_container_4_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RadioGroup_ng_container_4_4_ng_template_0_Template, 0, 0, "ng-template", 8);
  }
  if (rf & 2) {
    const ctx_r9 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r9.invalidText);
  }
}
function RadioGroup_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 9);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(2, "div", 10);
    i0.ɵɵtemplate(3, RadioGroup_ng_container_4_ng_container_3_Template, 2, 1, "ng-container", 3)(4, RadioGroup_ng_container_4_4_Template, 1, 1, null, 3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", !ctx_r1.isTemplate(ctx_r1.invalidText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.isTemplate(ctx_r1.invalidText));
  }
}
function RadioGroup_ng_container_5_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r11 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r11.warnText);
  }
}
function RadioGroup_ng_container_5_4_ng_template_0_Template(rf, ctx) {}
function RadioGroup_ng_container_5_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RadioGroup_ng_container_5_4_ng_template_0_Template, 0, 0, "ng-template", 8);
  }
  if (rf & 2) {
    const ctx_r12 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r12.warnText);
  }
}
function RadioGroup_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 11);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(2, "div", 10);
    i0.ɵɵtemplate(3, RadioGroup_ng_container_5_ng_container_3_Template, 2, 1, "ng-container", 3)(4, RadioGroup_ng_container_5_4_Template, 1, 1, null, 3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", !ctx_r2.isTemplate(ctx_r2.warnText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.isTemplate(ctx_r2.warnText));
  }
}
function RadioGroup_div_6_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r14 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r14.helperText);
  }
}
function RadioGroup_div_6_2_ng_template_0_Template(rf, ctx) {}
function RadioGroup_div_6_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RadioGroup_div_6_2_ng_template_0_Template, 0, 0, "ng-template", 8);
  }
  if (rf & 2) {
    const ctx_r15 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r15.helperText);
  }
}
const _c2 = a0 => ({
  "cds--form__helper-text--disabled": a0
});
function RadioGroup_div_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵtemplate(1, RadioGroup_div_6_ng_container_1_Template, 2, 1, "ng-container", 3)(2, RadioGroup_div_6_2_Template, 1, 1, null, 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c2, ctx_r3.disabled));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r3.isTemplate(ctx_r3.helperText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r3.isTemplate(ctx_r3.helperText));
  }
}
const _c3 = (a0, a1, a2, a3) => ({
  "cds--radio-button-group--vertical": a0,
  "cds--radio-button-group--label-left": a1,
  "cds--radio-button-group--invalid": a2,
  "cds--radio-button-group--warning": a3
});
class RadioChange {
  constructor(source, value) {
    this.source = source;
    this.value = value;
  }
}

/**
 * class: Radio (extends Checkbox)
 *
 * selector: `n-radio`
 *
 * source: `src/forms/radio.component.ts`
 *
 * ```html
 *	<cds-radio [(ngModel)]="radioState">Radio</cds-radio>
 * ```
 *
 * Also see: [`RadioGroup`](#cds-radio-group)
 */
class Radio {
  constructor() {
    this.checked = false;
    this.name = "";
    this.disabled = false;
    this.labelPlacement = "right";
    /**
     * Sets the HTML required attribute
     */
    this.required = false;
    /**
     * Set to `true` for a loading table.
     */
    this.skeleton = false;
    /**
     * The id for the `Radio`.
     */
    this.id = `radio-${Radio.radioCount++}`;
    /**
     * emits when the state of the radio changes
     */
    this.change = new EventEmitter();
    this.hostClass = true;
    /**
     * Reflects whether or not the input is disabled at `RadioGroup` level.
     */
    this.disabledFromGroup = false;
    this._labelledby = "";
    /**
     * Handler provided by the `RadioGroup` to bubble events up
     */
    this.radioChangeHandler = event => {};
  }
  set ariaLabelledby(value) {
    this._labelledby = value;
  }
  get ariaLabelledby() {
    if (this._labelledby) {
      return this._labelledby;
    }
    return `label-${this.id}`;
  }
  get labelLeft() {
    return this.labelPlacement === "left";
  }
  /**
   * Synchronizes with the `RadioGroup` in the event of a changed `Radio`.
   * Emits the changes of both the `RadioGroup` and `Radio`.
   */
  onChange(event) {
    event.stopPropagation();
  }
  onClick(event) {
    this.checked = event.target.checked;
    const radioEvent = new RadioChange(this, this.value);
    this.change.emit(radioEvent);
    this.radioChangeHandler(radioEvent);
  }
  /**
   * Method called by `RadioGroup` with a callback function to bubble `RadioChange` events
   * @param fn callback that expects a `RadioChange` as an argument
   */
  registerRadioChangeHandler(fn) {
    this.radioChangeHandler = fn;
  }
  setDisabledFromGroup(disabled) {
    this.disabledFromGroup = disabled;
  }
}
/**
 * Used to dynamically create unique ids for the `Radio`.
 */
Radio.radioCount = 0;
Radio.ɵfac = function Radio_Factory(t) {
  return new (t || Radio)();
};
Radio.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Radio,
  selectors: [["cds-radio"], ["ibm-radio"]],
  hostVars: 4,
  hostBindings: function Radio_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--radio-button-wrapper", ctx.hostClass)("cds--radio-button-wrapper--label-left", ctx.labelLeft);
    }
  },
  inputs: {
    checked: "checked",
    name: "name",
    disabled: "disabled",
    labelPlacement: "labelPlacement",
    ariaLabelledby: "ariaLabelledby",
    ariaLabel: "ariaLabel",
    required: "required",
    value: "value",
    skeleton: "skeleton",
    id: "id"
  },
  outputs: {
    change: "change"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: Radio,
    multi: true
  }])],
  ngContentSelectors: _c1,
  decls: 5,
  vars: 8,
  consts: [["class", "cds--radio-button", "type", "radio", 3, "checked", "disabled", "name", "id", "required", "change", "click", 4, "ngIf"], ["class", "cds--radio-button cds--skeleton", 4, "ngIf"], [1, "cds--radio-button__label", 3, "ngClass", "for", "id"], [1, "cds--radio-button__appearance"], ["type", "radio", 1, "cds--radio-button", 3, "checked", "disabled", "name", "id", "required", "change", "click"], [1, "cds--radio-button", "cds--skeleton"]],
  template: function Radio_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵtemplate(0, Radio_input_0_Template, 1, 7, "input", 0)(1, Radio_div_1_Template, 1, 0, "div", 1);
      i0.ɵɵelementStart(2, "label", 2);
      i0.ɵɵelement(3, "span", 3);
      i0.ɵɵprojection(4);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", !ctx.skeleton);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.skeleton);
      i0.ɵɵadvance();
      i0.ɵɵpropertyInterpolate1("id", "label-", ctx.id, "");
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(6, _c0, ctx.skeleton))("for", ctx.id);
      i0.ɵɵattribute("aria-label", ctx.ariaLabel);
    }
  },
  dependencies: [i1.NgClass, i1.NgIf],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Radio, [{
    type: Component,
    args: [{
      selector: "cds-radio, ibm-radio",
      template: `
		<input
			*ngIf="!skeleton"
			class="cds--radio-button"
			type="radio"
			[checked]="checked"
			[disabled]="disabled || disabledFromGroup"
			[name]="name"
			[id]="id"
			[required]="required"
			[attr.value]="value"
			[attr.aria-labelledby]="ariaLabelledby"
			(change)="onChange($event)"
			(click)="onClick($event)">
		<div *ngIf="skeleton" class="cds--radio-button cds--skeleton"></div>
		<label
			class="cds--radio-button__label"
			[attr.aria-label]="ariaLabel"
			[ngClass]="{
				'cds--skeleton': skeleton
			}"
			[for]="id"
			id="label-{{id}}">
			<span class="cds--radio-button__appearance"></span>
			<ng-content></ng-content>
		</label>
	`,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: Radio,
        multi: true
      }]
    }]
  }], null, {
    checked: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    labelPlacement: [{
      type: Input
    }],
    ariaLabelledby: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    required: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    skeleton: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    change: [{
      type: Output
    }],
    hostClass: [{
      type: HostBinding,
      args: ["class.cds--radio-button-wrapper"]
    }],
    labelLeft: [{
      type: HostBinding,
      args: ["class.cds--radio-button-wrapper--label-left"]
    }]
  });
})();

/**
 * Get started with importing the module:
 *
 * ```typescript
 * import { RadioModule } from 'carbon-components-angular';
 * ```
 *
 * Ex:
 *
 * ```html
 * <cds-radio-group [(ngModel)]="radio">
 * 	<cds-radio *ngFor="let one of manyRadios" [value]="one">
 *		Radio {{one}}
 * 	</cds-radio>
 * </cds-radio-group>
 *
 * Radio selected: {{radio}}
 * ```
 *
 * ```ts
 *	const manyRadios = ["one", "two", "three", "four", "five", "six"];
 * ```
 *
 * [See demo](../../?path=/story/components-radio--basic)
 */
class RadioGroup {
  constructor() {
    this.orientation = "horizontal";
    this.labelPlacement = "right";
    /**
     * Set to `true` to show the invalid state.
     */
    this.invalid = false;
    /**
    * Set to `true` to show a warning (contents set by warnText)
    */
    this.warn = false;
    /**
     * Emits event notifying other classes of a change using a `RadioChange` class.
     */
    this.change = new EventEmitter();
    /**
     * Binds 'cds--form-item' value to the class for `RadioGroup`.
     */
    this.radioButtonGroupClass = true;
    /**
     * To track whether the `RadioGroup` has been initialized.
     */
    this.isInitialized = false;
    /**
     * Reflects whether or not the input is disabled and cannot be selected.
     */
    this._disabled = false;
    /**
     * Reflects whether or not the dropdown is loading.
     */
    this._skeleton = false;
    /**
     * The value of the selected option within the `RadioGroup`.
     */
    this._value = null;
    /**
     * The `Radio` within the `RadioGroup` that is selected.
     */
    this._selected = null;
    /**
     * The name attribute associated with the `RadioGroup`.
     */
    this._name = `radio-group-${RadioGroup.radioGroupCount++}`;
    /**
     * Needed to properly implement ControlValueAccessor.
     */
    this.onTouched = () => {};
    /**
     * Method set in registerOnChange to propagate changes back to the form.
     */
    this.propagateChange = _ => {};
  }
  /**
   * Sets the passed in `Radio` item as the selected input within the `RadioGroup`.
   */
  set selected(selected) {
    const alreadySelected = (this._selected && this._selected.value) === (selected && selected.value);
    if (alreadySelected) {
      // no need to redo
      return;
    }
    if (this._selected) {
      this._selected.checked = false;
    }
    this._selected = selected;
    this.value = selected ? selected.value : null;
    this.checkSelectedRadio();
  }
  /**
   * Returns the `Radio` that is selected within the `RadioGroup`.
   */
  get selected() {
    return this._selected;
  }
  /**
   * Sets the value/state of the selected `Radio` within the `RadioGroup` to the passed in value.
   */
  set value(newValue) {
    if (this._value !== newValue) {
      this._value = newValue;
      this.updateSelectedRadioFromValue();
      this.checkSelectedRadio();
    }
  }
  /**
   * Returns the value/state of the selected `Radio` within the `RadioGroup`.
   */
  get value() {
    return this._value;
  }
  /**
   * Replaces the name associated with the `RadioGroup` with the provided parameter.
   */
  set name(name) {
    this._name = name;
    this.updateRadios();
  }
  /**
   * Returns the associated name of the `RadioGroup`.
   */
  get name() {
    return this._name;
  }
  /**
   * Set to true to disable the whole radio group
   */
  set disabled(disabled) {
    this._disabled = disabled;
    this.updateRadios();
  }
  /**
   * Returns the disabled value for the `RadioGroup`.
   */
  get disabled() {
    return this._disabled;
  }
  /**
   * Returns the skeleton value in the `RadioGroup` if there is one.
   */
  get skeleton() {
    return this._skeleton;
  }
  /**
   * Sets the skeleton value for all `Radio` to the skeleton value of `RadioGroup`.
   */
  set skeleton(value) {
    this._skeleton = value;
    this.updateChildren();
  }
  /**
   * Updates the selected `Radio` to be checked (selected).
   */
  checkSelectedRadio() {
    if (this.selected && !this._selected.checked) {
      this.selected.checked = true;
    }
  }
  /**
   * Use the value of the `RadioGroup` to update the selected radio to the right state (selected state).
   */
  updateSelectedRadioFromValue() {
    let alreadySelected = this._selected != null && this._selected.value === this._value;
    if (this.radios && !alreadySelected) {
      if (this.selected && this.value) {
        this.selected.checked = false;
      }
      this._selected = null;
      this.radios.forEach(radio => {
        if (radio.checked || radio.value === this._value) {
          this._selected = radio;
        }
      });
      if (this.selected && !this.value) {
        this._value = this.selected.value;
      }
    }
  }
  /**
   * `ControlValueAccessor` method to programmatically disable the `RadioGroup`.
   *
   * ex: `this.formGroup.get("myRadioGroup").disable();`
   *
   * @param isDisabled `true` to disable the inputs
   */
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  /**
   * Creates a class of `RadioChange` to emit the change in the `RadioGroup`.
   */
  emitChangeEvent(event) {
    this.change.emit(event);
    this.propagateChange(event.value);
    this.onTouched();
  }
  /**
   * Synchronizes radio properties.
   */
  updateRadios() {
    if (this.radios) {
      setTimeout(() => {
        this.radios.forEach(radio => {
          radio.name = this.name;
          radio.setDisabledFromGroup(this.disabled);
          if (this.labelPlacement === "left") {
            radio.labelPlacement = "left";
          }
        });
      });
    }
  }
  /**
   * Updates the value of the `RadioGroup` using the provided parameter.
   */
  writeValue(value) {
    this.value = value;
    setTimeout(() => {
      this.updateSelectedRadioFromValue();
      this.checkSelectedRadio();
    });
  }
  ngAfterContentInit() {
    this.radios.changes.subscribe(() => {
      this.updateRadios();
      this.updateRadioChangeHandler();
    });
    this.updateChildren();
    this.updateRadioChangeHandler();
  }
  ngAfterViewInit() {
    this.updateRadios();
  }
  /**
   * Used to set method to propagate changes back to the form.
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  /**
   * Registers a callback to be triggered when the control has been touched.
   * @param fn Callback to be triggered when the checkbox is touched.
   */
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  focusOut() {
    this.onTouched();
  }
  isTemplate(value) {
    return value instanceof TemplateRef;
  }
  updateChildren() {
    if (this.radios) {
      this.radios.forEach(child => child.skeleton = this.skeleton);
    }
  }
  updateRadioChangeHandler() {
    this.radios.forEach(radio => {
      radio.registerRadioChangeHandler(event => {
        if ((this.selected && this.selected.value) === event.value) {
          // no need to redo
          return;
        }
        // deselect previous radio
        if (this.selected) {
          this.selected.checked = false;
        }
        // update selected and value from the event
        this._selected = event.source;
        this._value = event.value;
        // bubble the event
        this.emitChangeEvent(event);
      });
    });
  }
}
/**
 * Used for creating the `RadioGroup` 'name' property dynamically.
 */
RadioGroup.radioGroupCount = 0;
RadioGroup.ɵfac = function RadioGroup_Factory(t) {
  return new (t || RadioGroup)();
};
RadioGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: RadioGroup,
  selectors: [["cds-radio-group"], ["ibm-radio-group"]],
  contentQueries: function RadioGroup_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, Radio, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.radios = _t);
    }
  },
  hostVars: 2,
  hostBindings: function RadioGroup_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("focusout", function RadioGroup_focusout_HostBindingHandler() {
        return ctx.focusOut();
      });
    }
    if (rf & 2) {
      i0.ɵɵclassProp("cds--form-item", ctx.radioButtonGroupClass);
    }
  },
  inputs: {
    selected: "selected",
    value: "value",
    name: "name",
    disabled: "disabled",
    skeleton: "skeleton",
    orientation: "orientation",
    labelPlacement: "labelPlacement",
    legend: "legend",
    ariaLabel: "ariaLabel",
    ariaLabelledby: "ariaLabelledby",
    helperText: "helperText",
    invalid: "invalid",
    invalidText: "invalidText",
    warn: "warn",
    warnText: "warnText"
  },
  outputs: {
    change: "change"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: RadioGroup,
    multi: true
  }])],
  ngContentSelectors: _c1,
  decls: 7,
  vars: 13,
  consts: [[1, "cds--radio-button-group", 3, "ngClass"], ["class", "cds--label", 4, "ngIf"], [1, "cds--radio-button__validation-msg"], [4, "ngIf"], ["class", "cds--form__helper-text", 3, "ngClass", 4, "ngIf"], [1, "cds--label"], [4, "ngIf", "ngIfElse"], ["legendLabel", ""], [3, "ngTemplateOutlet"], ["cdsIcon", "warning--filled", "size", "16", 1, "cds--radio-button__invalid-icon"], [1, "cds--form-requirement"], ["cdsIcon", "warning--alt--filled", "size", "16", 1, "cds--radio-button__invalid-icon", "cds--radio-button__invalid-icon--warning"], [1, "cds--form__helper-text", 3, "ngClass"]],
  template: function RadioGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "fieldset", 0);
      i0.ɵɵtemplate(1, RadioGroup_legend_1_Template, 4, 2, "legend", 1);
      i0.ɵɵprojection(2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(3, "div", 2);
      i0.ɵɵtemplate(4, RadioGroup_ng_container_4_Template, 5, 2, "ng-container", 3)(5, RadioGroup_ng_container_5_Template, 5, 2, "ng-container", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(6, RadioGroup_div_6_Template, 3, 5, "div", 4);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction4(8, _c3, ctx.orientation === "vertical", ctx.labelPlacement === "left", !ctx.warn && ctx.invalid, !ctx.invalid && ctx.warn));
      i0.ɵɵattribute("aria-label", ctx.ariaLabel)("aria-labelledby", ctx.ariaLabelledby)("data-invalid", ctx.invalid ? true : null);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.legend);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", !ctx.warn && ctx.invalid);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.invalid && ctx.warn);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.helperText && !ctx.invalid && !ctx.warn);
    }
  },
  dependencies: [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i2.IconDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadioGroup, [{
    type: Component,
    args: [{
      selector: "cds-radio-group, ibm-radio-group",
      template: `
		<fieldset
			class="cds--radio-button-group"
			[attr.aria-label]="ariaLabel"
			[attr.aria-labelledby]="ariaLabelledby"
			[ngClass]="{
				'cds--radio-button-group--vertical': orientation === 'vertical',
				'cds--radio-button-group--label-left': labelPlacement === 'left',
				'cds--radio-button-group--invalid' : !warn && invalid,
				'cds--radio-button-group--warning': !invalid && warn
			}"
			[attr.data-invalid]="invalid ? true : null">
			<legend *ngIf="legend" class="cds--label">
				<ng-template *ngIf="isTemplate(legend); else legendLabel;" [ngTemplateOutlet]="legend"></ng-template>
				<ng-template #legendLabel>{{legend}}</ng-template>
			</legend>
			<ng-content></ng-content>
		</fieldset>
		<div class="cds--radio-button__validation-msg">
			<ng-container *ngIf="!warn && invalid">
				<svg
					cdsIcon="warning--filled"
					size="16"
					class="cds--radio-button__invalid-icon">
				</svg>
				<div class="cds--form-requirement">
					<ng-container *ngIf="!isTemplate(invalidText)">{{ invalidText }}</ng-container>
					<ng-template *ngIf="isTemplate(invalidText)" [ngTemplateOutlet]="invalidText"></ng-template>
				</div>
			</ng-container>
			<ng-container *ngIf="!invalid && warn">
				<svg
					cdsIcon="warning--alt--filled"
					class="cds--radio-button__invalid-icon cds--radio-button__invalid-icon--warning"
					size="16">
				</svg>
				<div class="cds--form-requirement">
					<ng-container *ngIf="!isTemplate(warnText)">{{warnText}}</ng-container>
					<ng-template *ngIf="isTemplate(warnText)" [ngTemplateOutlet]="warnText"></ng-template>
				</div>
			</ng-container>
		</div>
		<div
			*ngIf="helperText && !invalid && !warn"
			class="cds--form__helper-text"
			[ngClass]="{'cds--form__helper-text--disabled': disabled}">
			<ng-container *ngIf="!isTemplate(helperText)">{{helperText}}</ng-container>
			<ng-template *ngIf="isTemplate(helperText)" [ngTemplateOutlet]="helperText"></ng-template>
		</div>
	`,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: RadioGroup,
        multi: true
      }]
    }]
  }], null, {
    selected: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    skeleton: [{
      type: Input
    }],
    orientation: [{
      type: Input
    }],
    labelPlacement: [{
      type: Input
    }],
    legend: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    ariaLabelledby: [{
      type: Input
    }],
    helperText: [{
      type: Input
    }],
    invalid: [{
      type: Input
    }],
    invalidText: [{
      type: Input
    }],
    warn: [{
      type: Input
    }],
    warnText: [{
      type: Input
    }],
    change: [{
      type: Output
    }],
    radios: [{
      type: ContentChildren,
      args: [forwardRef(() => Radio)]
    }],
    radioButtonGroupClass: [{
      type: HostBinding,
      args: ["class.cds--form-item"]
    }],
    focusOut: [{
      type: HostListener,
      args: ["focusout"]
    }]
  });
})();

// modules
class RadioModule {}
RadioModule.ɵfac = function RadioModule_Factory(t) {
  return new (t || RadioModule)();
};
RadioModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: RadioModule
});
RadioModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, FormsModule, IconModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadioModule, [{
    type: NgModule,
    args: [{
      declarations: [Radio, RadioGroup],
      exports: [Radio, RadioGroup],
      imports: [CommonModule, FormsModule, IconModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Radio, RadioChange, RadioGroup, RadioModule };
