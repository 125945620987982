import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'carbon-components-angular/checkbox';
export { Checkbox, CheckboxModule, CheckboxState } from 'carbon-components-angular/checkbox';
import { ToggleModule } from 'carbon-components-angular/toggle';
export { Toggle, ToggleModule, ToggleState } from 'carbon-components-angular/toggle';
import { RadioModule } from 'carbon-components-angular/radio';
export { Radio, RadioChange, RadioGroup, RadioModule } from 'carbon-components-angular/radio';
import { InputModule } from 'carbon-components-angular/input';
export { InputModule, Label, TextArea, TextInput, TextInputLabelComponent, TextareaLabelComponent } from 'carbon-components-angular/input';
import { ButtonModule } from 'carbon-components-angular/button';
export { Button, ButtonModule, ButtonSet, IconButton } from 'carbon-components-angular/button';

// modules
class NFormsModule {}
NFormsModule.ɵfac = function NFormsModule_Factory(t) {
  return new (t || NFormsModule)();
};
NFormsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NFormsModule
});
NFormsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, FormsModule, CheckboxModule, ToggleModule, RadioModule, InputModule, ButtonModule, CheckboxModule, ToggleModule, RadioModule, InputModule, ButtonModule, InputModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NFormsModule, [{
    type: NgModule,
    args: [{
      exports: [CheckboxModule, ToggleModule, RadioModule, InputModule, ButtonModule, InputModule],
      imports: [CommonModule, FormsModule, CheckboxModule, ToggleModule, RadioModule, InputModule, ButtonModule]
    }]
  }], null, null);
})();

// export the forms module

/**
 * Generated bundle index. Do not edit.
 */

export { NFormsModule };
