import * as i0 from '@angular/core';
import { Injectable, Directive, Optional, HostBinding, Input, SkipSelf, ContentChildren, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Subscription } from 'rxjs';
class GridService {
  constructor() {
    this.gridSubject = new BehaviorSubject(false);
    this.cssGridEnabled = false;
    this.gridObservable = this.gridSubject.asObservable();
  }
  /**
   * Ping all subscribers to update to use Css Grid
   * @param enableCssGrid
   */
  updateGridType(enableCssGrid) {
    if (this.cssGridEnabled === enableCssGrid) {
      return;
    }
    this.cssGridEnabled = true;
    this.gridSubject.next(enableCssGrid);
  }
}
GridService.ɵfac = function GridService_Factory(t) {
  return new (t || GridService)();
};
GridService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: GridService,
  factory: GridService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GridService, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();
class ColumnDirective {
  constructor(gridService) {
    this.gridService = gridService;
    this.class = "";
    /**
     * Defines columns width for specified breakpoint
     * Accepts the following formats:
     * - {[breakpoint]: number}
     * - {[breakpoint]: "auto"} - css only
     * - {[breakpoint]: {[start|end]: number}} - css only
     *
     * Example:
     * <div cdsCol [columnNumbers]={md: 3, lg: 4}></div>
     */
    this.columnNumbers = {};
    /**
     * Defines columns offset, which increases the left margin of the column.
     * This field will only work with flexbox grid.
     *
     * Accepts the following formats:
     * - {[breakpoint]: number}
     *
     * Example:
     * <div cdsCol [offsets]={md: 3, lg: 4}></div>
     */
    this.offsets = {};
    /**
     * Set to `true` to use css grid column hang class
     * This will only work when `isCss` property is set to true
     *
     * Useful when trying to align content across css grid/subgrid
     */
    this.columnHang = false;
    this._columnClasses = [];
    this.isCssGrid = false;
    this.subscription = new Subscription();
  }
  get columnClasses() {
    return this._columnClasses.join(" ");
  }
  set columnClasses(classes) {
    this._columnClasses = classes.split(" ");
  }
  ngOnInit() {
    if (this.gridService) {
      this.subscription = this.gridService.gridObservable.subscribe(isCssGrid => {
        this.isCssGrid = isCssGrid;
        this.updateColumnClasses();
      });
    } else {
      this.updateColumnClasses();
    }
  }
  ngOnChanges() {
    this.updateColumnClasses();
  }
  /**
   * Unsubscribe from subscription
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  updateColumnClasses() {
    try {
      this._columnClasses = [];
      const columnKeys = Object.keys(this.columnNumbers);
      // Assign classes based on the type of grid used.
      if (this.isCssGrid) {
        // Default css grid class
        this._columnClasses.push("cds--css-grid-column");
        if (this.columnHang) {
          this._columnClasses.push("cds--grid-column-hang");
        }
        columnKeys.forEach(key => {
          /**
           * Passing in `auto` to a breakpoint as such: {'md': 'auto'}
           * will assign the element which will automatically determine the width of the column
           * for the breakpoint passed
           */
          if (this.columnNumbers[key] === "auto") {
            this._columnClasses.push(`cds--${key}:col-span-auto`);
          } else if (typeof this.columnNumbers[key] === "object") {
            /**
             * In css grid, objects can be passed to the keys in the following format:
             * {'md': {'start': 3}}
             *
             * These objects are used to position the column
             */
            if (this.columnNumbers[key]["start"]) {
              // col-start is simular equivalent of flex offset
              this._columnClasses.push(`cds--${key}:col-start-${this.columnNumbers[key].start}`);
            }
            if (this.columnNumbers[key]["end"]) {
              this._columnClasses.push(`cds--${key}:col-end-${this.columnNumbers[key].end}`);
            }
            if (this.columnNumbers[key]["span"]) {
              this._columnClasses.push(`cds--${key}:col-span-${this.columnNumbers[key].span}`);
            }
          } else {
            this._columnClasses.push(`cds--${key}:col-span-${this.columnNumbers[key]}`);
          }
        });
        Object.keys(this.offsets).forEach(key => {
          this._columnClasses.push(`cds--${key}:col-start${this.offsets[key] + 1}`);
        });
      } else {
        // Set column classes for flex grid
        if (columnKeys.length <= 0) {
          this._columnClasses.push("cds--col");
        }
        columnKeys.forEach(key => {
          if (this.columnNumbers[key] === "nobreak") {
            this._columnClasses.push(`cds--col-${key}`);
          } else {
            this._columnClasses.push(`cds--col-${key}-${this.columnNumbers[key]}`);
          }
        });
        Object.keys(this.offsets).forEach(key => {
          this._columnClasses.push(`cds--offset-${key}-${this.offsets[key]}`);
        });
      }
    } catch (err) {
      console.error(`Malformed \`offsets\` or \`columnNumbers\`: ${err}`);
    }
    /**
     * Append the classes passed so they aren't overriden when we set the column classes
     * from host binding
     */
    if (this.class) {
      this._columnClasses.push(this.class);
    }
  }
}
ColumnDirective.ɵfac = function ColumnDirective_Factory(t) {
  return new (t || ColumnDirective)(i0.ɵɵdirectiveInject(GridService, 8));
};
ColumnDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ColumnDirective,
  selectors: [["", "cdsCol", ""], ["", "ibmCol", ""]],
  hostVars: 2,
  hostBindings: function ColumnDirective_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassMap(ctx.columnClasses);
    }
  },
  inputs: {
    class: "class",
    columnNumbers: "columnNumbers",
    offsets: "offsets",
    columnHang: "columnHang"
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColumnDirective, [{
    type: Directive,
    args: [{
      selector: "[cdsCol], [ibmCol]"
    }]
  }], function () {
    return [{
      type: GridService,
      decorators: [{
        type: Optional
      }]
    }];
  }, {
    columnClasses: [{
      type: HostBinding,
      args: ["class"]
    }],
    class: [{
      type: Input
    }],
    columnNumbers: [{
      type: Input
    }],
    offsets: [{
      type: Input
    }],
    columnHang: [{
      type: Input
    }]
  });
})();
class RowDirective {
  constructor() {
    this.baseClass = true;
    this.condensed = false;
    this.narrow = false;
  }
}
RowDirective.ɵfac = function RowDirective_Factory(t) {
  return new (t || RowDirective)();
};
RowDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RowDirective,
  selectors: [["", "cdsRow", ""], ["", "ibmRow", ""]],
  hostVars: 6,
  hostBindings: function RowDirective_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--row", ctx.baseClass)("cds--row--condensed", ctx.condensed)("cds--row--narrow", ctx.narrow);
    }
  },
  inputs: {
    condensed: "condensed",
    narrow: "narrow"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RowDirective, [{
    type: Directive,
    args: [{
      selector: "[cdsRow], [ibmRow]"
    }]
  }], null, {
    baseClass: [{
      type: HostBinding,
      args: ["class.cds--row"]
    }],
    condensed: [{
      type: HostBinding,
      args: ["class.cds--row--condensed"]
    }, {
      type: Input
    }],
    narrow: [{
      type: HostBinding,
      args: ["class.cds--row--narrow"]
    }, {
      type: Input
    }]
  });
})();

/**
 * Get started with importing the module:
 *
 * ```typescript
 * import { GridModule } from 'carbon-components-angular';
 * ```
 *
 * [See demo](../../?path=/story/components-grid--basic)
 */
class GridDirective {
  constructor(gridService) {
    this.gridService = gridService;
    /**
     * Set to `true` to condense the grid
     */
    this.condensed = false;
    /**
     * Set to `true` to use narrow grid
     */
    this.narrow = false;
    /**
     * Set to `true` to use the full width
     */
    this.fullWidth = false;
    this.cssGridEnabled = false;
    this.isSubgrid = false;
    this.subscription = new Subscription();
  }
  /**
   * Set to `true` to use css grid
   */
  set useCssGrid(enable) {
    this.cssGridEnabled = enable;
    this.gridService.updateGridType(enable);
  }
  // Flex grid
  get flexGrid() {
    return !this.cssGridEnabled;
  }
  get flexCondensed() {
    return !this.cssGridEnabled && this.condensed;
  }
  get flexNarrow() {
    return !this.cssGridEnabled && this.narrow;
  }
  get flexFullWidth() {
    return !this.cssGridEnabled && this.fullWidth;
  }
  // CSS Grid
  get ccsGrid() {
    return this.cssGridEnabled && !this.isSubgrid;
  }
  get ccsCondensed() {
    return this.cssGridEnabled && !this.isSubgrid && this.condensed;
  }
  get ccsNarrow() {
    return this.cssGridEnabled && !this.isSubgrid && this.narrow;
  }
  get ccsFullWidth() {
    return this.cssGridEnabled && !this.isSubgrid && this.fullWidth;
  }
  // CSS Sub Grid
  get subGrid() {
    return this.cssGridEnabled && this.isSubgrid;
  }
  get subCondensed() {
    return this.cssGridEnabled && this.isSubgrid && this.condensed;
  }
  get subNarrow() {
    return this.cssGridEnabled && this.isSubgrid && this.narrow;
  }
  get subFullWidth() {
    return this.cssGridEnabled && this.isSubgrid && this.fullWidth;
  }
  ngOnInit() {
    this.subscription = this.gridService.gridObservable.subscribe(isCssGrid => {
      this.cssGridEnabled = isCssGrid;
    });
  }
  // Make all children grids a sub grid
  set cssGridChildren(list) {
    if (this.cssGridEnabled) {
      list.forEach(grid => {
        // Prevents initial (parent) grid element from being turned into a subgrid
        if (grid === this) {
          return;
        }
        grid.isSubgrid = true;
      });
    }
  }
  /**
   * Unsubscribe from Grid Service subscription
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
GridDirective.ɵfac = function GridDirective_Factory(t) {
  return new (t || GridDirective)(i0.ɵɵdirectiveInject(GridService));
};
GridDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: GridDirective,
  selectors: [["", "cdsGrid", ""], ["", "ibmGrid", ""]],
  contentQueries: function GridDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, GridDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.cssGridChildren = _t);
    }
  },
  hostVars: 24,
  hostBindings: function GridDirective_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--grid", ctx.flexGrid)("cds--grid--condensed", ctx.flexCondensed)("cds--grid--narrow", ctx.flexNarrow)("cds--grid--full-width", ctx.flexFullWidth)("cds--css-grid", ctx.ccsGrid)("cds--css-grid--condensed", ctx.ccsCondensed)("cds--css-grid--narrow", ctx.ccsNarrow)("cds--css-grid--full-width", ctx.ccsFullWidth)("cds--subgrid", ctx.subGrid)("cds--subgrid--condensed", ctx.subCondensed)("cds--subgrid--narrow", ctx.subNarrow)("cds--subgrid--wide", ctx.subFullWidth);
    }
  },
  inputs: {
    condensed: "condensed",
    narrow: "narrow",
    fullWidth: "fullWidth",
    useCssGrid: "useCssGrid"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: GridService,
    deps: [[new Optional(), new SkipSelf(), GridService]],
    useFactory: parentService => {
      return parentService || new GridService();
    }
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GridDirective, [{
    type: Directive,
    args: [{
      selector: "[cdsGrid], [ibmGrid]",
      providers: [{
        provide: GridService,
        deps: [[new Optional(), new SkipSelf(), GridService]],
        useFactory: parentService => {
          return parentService || new GridService();
        }
      }]
    }]
  }], function () {
    return [{
      type: GridService
    }];
  }, {
    condensed: [{
      type: Input
    }],
    narrow: [{
      type: Input
    }],
    fullWidth: [{
      type: Input
    }],
    useCssGrid: [{
      type: Input
    }],
    flexGrid: [{
      type: HostBinding,
      args: ["class.cds--grid"]
    }],
    flexCondensed: [{
      type: HostBinding,
      args: ["class.cds--grid--condensed"]
    }],
    flexNarrow: [{
      type: HostBinding,
      args: ["class.cds--grid--narrow"]
    }],
    flexFullWidth: [{
      type: HostBinding,
      args: ["class.cds--grid--full-width"]
    }],
    ccsGrid: [{
      type: HostBinding,
      args: ["class.cds--css-grid"]
    }],
    ccsCondensed: [{
      type: HostBinding,
      args: ["class.cds--css-grid--condensed"]
    }],
    ccsNarrow: [{
      type: HostBinding,
      args: ["class.cds--css-grid--narrow"]
    }],
    ccsFullWidth: [{
      type: HostBinding,
      args: ["class.cds--css-grid--full-width"]
    }],
    subGrid: [{
      type: HostBinding,
      args: ["class.cds--subgrid"]
    }],
    subCondensed: [{
      type: HostBinding,
      args: ["class.cds--subgrid--condensed"]
    }],
    subNarrow: [{
      type: HostBinding,
      args: ["class.cds--subgrid--narrow"]
    }],
    subFullWidth: [{
      type: HostBinding,
      args: ["class.cds--subgrid--wide"]
    }],
    cssGridChildren: [{
      type: ContentChildren,
      args: [GridDirective, {
        descendants: true
      }]
    }]
  });
})();
class GridModule {}
GridModule.ɵfac = function GridModule_Factory(t) {
  return new (t || GridModule)();
};
GridModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: GridModule
});
GridModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [GridService],
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GridModule, [{
    type: NgModule,
    args: [{
      declarations: [ColumnDirective, GridDirective, RowDirective],
      exports: [ColumnDirective, GridDirective, RowDirective],
      providers: [GridService],
      imports: [CommonModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ColumnDirective, GridDirective, GridModule, GridService, RowDirective };
