let tabbableSelector = "a[href], area[href], input:not([disabled]):not([tabindex=\'-1\']), " +
    "button:not([disabled]):not([tabindex=\'-1\']),select:not([disabled]):not([tabindex=\'-1\']), " +
    "textarea:not([disabled]):not([tabindex=\'-1\']), " +
    "iframe, object, embed, *[tabindex]:not([tabindex=\'-1\']), *[contenteditable=true]";
let tabbableSelectorIgnoreTabIndex = "a[href], area[href], input:not([disabled]), " +
    "button:not([disabled]),select:not([disabled]), " +
    "textarea:not([disabled]), " +
    "iframe, object, embed, *[tabindex], *[contenteditable=true]";
function getFocusElementList(element, selector = tabbableSelector) {
    let elements = element.querySelectorAll(selector);
    return elements ? Array.prototype.filter.call(elements, el => isVisible(el)) : elements;
}
function isFocusInFirstItem(event, list) {
    if (list.length > 0) {
        return (event.target || event.srcElement) === list[0];
    }
    return false;
}
function isFocusInLastItem(event, list) {
    if (list.length > 0) {
        return (event.target || event.srcElement) === list[list.length - 1];
    }
    return false;
}
function isElementFocused(event, element) {
    return (event.target || event.srcElement) === element;
}
function focusFirstFocusableElement(list) {
    if (list.length > 0) {
        list[0].focus();
        return true;
    }
    return false;
}
function focusLastFocusableElement(list) {
    if (list.length > 0) {
        list[list.length - 1].focus();
        return true;
    }
    return false;
}
function isVisible(element) {
    return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
}
function cycleTabs(event, element) {
    if (event.key === "Tab") {
        let list = getFocusElementList(element);
        let focusChanged = false;
        if (event.shiftKey) {
            if (isFocusInFirstItem(event, list) || isElementFocused(event, element)) {
                focusChanged = focusLastFocusableElement(list);
            }
        }
        else {
            if (isFocusInLastItem(event, list)) {
                focusChanged = focusFirstFocusableElement(list);
            }
        }
        if (focusChanged) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
}

/**
 * Does what python's `range` function does, with a slightly different
 * signature because of Typescript limitations.
 *
 * Useful for numbered loops in angular templates, since we can do
 * a normal for loop.
 *
 * @export
 * @param stop Generate numbers up to, but not including this number
 * @param [start=0] Starting number of the sequence
 * @param [step=1] Difference between each number in the sequence
 * @returns an array with resulting numbers
 */
function range(stop, start = 0, step = 1) {
    return Array(Math.ceil((stop - start) / step)).fill(0).map((x, i) => i * step + start);
}

/**
 * Generated bundle index. Do not edit.
 */

export { cycleTabs, focusFirstFocusableElement, focusLastFocusableElement, getFocusElementList, isElementFocused, isFocusInFirstItem, isFocusInLastItem, isVisible, range, tabbableSelector, tabbableSelectorIgnoreTabIndex };

