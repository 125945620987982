import * as i0 from '@angular/core';
import { Directive, Input, ContentChildren, HostBinding, NgModule } from '@angular/core';
import { LayerDirective, LayerModule } from 'carbon-components-angular/layer';
import { CommonModule } from '@angular/common';

/**
 * Applies theme styles to the div container it is applied to. Get started with importing the module:
 *
 * ```typescript
 * import { ThemeModule } from 'carbon-components-angular';
 * ```
 *
 * [See demo](../../?path=/story/components-theme--basic)
 */
class ThemeDirective {
  constructor() {
    /**
     * Sets the theme for the content
     * Accepts `ThemeType` or nothing (empty string which is equivalent to "white")
     * Empty string has been added as an option for Angular 16+ to resolve type errors
     */
    this.cdsTheme = "white";
    this.layerClass = true;
  }
  /**
   * @deprecated as of v5 - Use `cdsTheme` input property instead
   */
  set ibmTheme(type) {
    this.cdsTheme = type;
  }
  /**
   * Using host bindings with classes to ensure we do not
   * overwrite user added classes
   */
  get whiteThemeClass() {
    return this.cdsTheme === "white" || !this.cdsTheme;
  }
  get g10ThemeClass() {
    return this.cdsTheme === "g10";
  }
  get g90ThemeClass() {
    return this.cdsTheme === "g90";
  }
  get g100ThemeClass() {
    return this.cdsTheme === "g100";
  }
  ngAfterContentChecked() {
    /**
     * Resets next layer level in theme
     * If not found, the layer will be 1 by default
     */
    this.layerChildren.toArray().forEach(layer => {
      if (typeof layer.cdsLayer !== "number") {
        layer.cdsLayer = 1;
      }
    });
  }
}
ThemeDirective.ɵfac = function ThemeDirective_Factory(t) {
  return new (t || ThemeDirective)();
};
ThemeDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ThemeDirective,
  selectors: [["", "cdsTheme", ""], ["", "ibmTheme", ""]],
  contentQueries: function ThemeDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, LayerDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.layerChildren = _t);
    }
  },
  hostVars: 10,
  hostBindings: function ThemeDirective_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--white", ctx.whiteThemeClass)("cds--g10", ctx.g10ThemeClass)("cds--g90", ctx.g90ThemeClass)("cds--g100", ctx.g100ThemeClass)("cds--layer-one", ctx.layerClass);
    }
  },
  inputs: {
    ibmTheme: "ibmTheme",
    cdsTheme: "cdsTheme"
  },
  exportAs: ["theme"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeDirective, [{
    type: Directive,
    args: [{
      selector: "[cdsTheme], [ibmTheme]",
      exportAs: "theme"
    }]
  }], null, {
    ibmTheme: [{
      type: Input
    }],
    cdsTheme: [{
      type: Input
    }],
    layerChildren: [{
      type: ContentChildren,
      args: [LayerDirective, {
        descendants: false
      }]
    }],
    whiteThemeClass: [{
      type: HostBinding,
      args: ["class.cds--white"]
    }],
    g10ThemeClass: [{
      type: HostBinding,
      args: ["class.cds--g10"]
    }],
    g90ThemeClass: [{
      type: HostBinding,
      args: ["class.cds--g90"]
    }],
    g100ThemeClass: [{
      type: HostBinding,
      args: ["class.cds--g100"]
    }],
    layerClass: [{
      type: HostBinding,
      args: ["class.cds--layer-one"]
    }]
  });
})();
class ThemeModule {}
ThemeModule.ɵfac = function ThemeModule_Factory(t) {
  return new (t || ThemeModule)();
};
ThemeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ThemeModule
});
ThemeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, LayerModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeModule, [{
    type: NgModule,
    args: [{
      declarations: [ThemeDirective],
      exports: [ThemeDirective],
      imports: [CommonModule, LayerModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ThemeDirective, ThemeModule };
