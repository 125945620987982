import * as i0 from '@angular/core';
import { EventEmitter, TemplateRef, Component, HostBinding, Input, Output, HostListener, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import * as i1 from 'carbon-components-angular/i18n';
import { I18nModule } from 'carbon-components-angular/i18n';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from 'carbon-components-angular/icon';
import { IconModule } from 'carbon-components-angular/icon';

/**
 * Used to emit changes performed on number input components.
 */
function NumberComponent_label_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "label", 10);
  }
}
function NumberComponent_label_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r8 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r8.label);
  }
}
function NumberComponent_label_2_2_ng_template_0_Template(rf, ctx) {}
function NumberComponent_label_2_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, NumberComponent_label_2_2_ng_template_0_Template, 0, 0, "ng-template", 13);
  }
  if (rf & 2) {
    const ctx_r9 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r9.label);
  }
}
const _c0 = a0 => ({
  "cds--label--disabled": a0
});
function NumberComponent_label_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 11);
    i0.ɵɵtemplate(1, NumberComponent_label_2_ng_container_1_Template, 2, 1, "ng-container", 12)(2, NumberComponent_label_2_2_Template, 1, 1, null, 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("for", ctx_r1.id)("ngClass", i0.ɵɵpureFunction1(4, _c0, ctx_r1.disabled));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.isTemplate(ctx_r1.label));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.isTemplate(ctx_r1.label));
  }
}
function NumberComponent__svg_svg_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 14);
  }
}
function NumberComponent__svg_svg_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 15);
  }
}
function NumberComponent_div_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 16)(1, "button", 17);
    i0.ɵɵlistener("click", function NumberComponent_div_7_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r12);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.onDecrement());
    });
    i0.ɵɵpipe(2, "async");
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(3, "svg", 18);
    i0.ɵɵelementEnd();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelement(4, "div", 19);
    i0.ɵɵelementStart(5, "button", 20);
    i0.ɵɵlistener("click", function NumberComponent_div_7_Template_button_click_5_listener() {
      i0.ɵɵrestoreView(_r12);
      const ctx_r13 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r13.onIncrement());
    });
    i0.ɵɵpipe(6, "async");
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(7, "svg", 21);
    i0.ɵɵelementEnd();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelement(8, "div", 19);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵattribute("disabled", ctx_r4.disabled ? true : null)("aria-label", i0.ɵɵpipeBind1(2, 4, ctx_r4.getDecrementLabel()));
    i0.ɵɵadvance(4);
    i0.ɵɵattribute("disabled", ctx_r4.disabled ? true : null)("aria-label", i0.ɵɵpipeBind1(6, 6, ctx_r4.getIncrementLabel()));
  }
}
function NumberComponent_div_8_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r14 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r14.helperText);
  }
}
function NumberComponent_div_8_2_ng_template_0_Template(rf, ctx) {}
function NumberComponent_div_8_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, NumberComponent_div_8_2_ng_template_0_Template, 0, 0, "ng-template", 13);
  }
  if (rf & 2) {
    const ctx_r15 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r15.helperText);
  }
}
const _c1 = a0 => ({
  "cds--form__helper-text--disabled": a0
});
function NumberComponent_div_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 22);
    i0.ɵɵtemplate(1, NumberComponent_div_8_ng_container_1_Template, 2, 1, "ng-container", 12)(2, NumberComponent_div_8_2_Template, 1, 1, null, 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c1, ctx_r5.disabled));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r5.isTemplate(ctx_r5.helperText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r5.isTemplate(ctx_r5.helperText));
  }
}
function NumberComponent_div_9_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r17 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r17.invalidText);
  }
}
function NumberComponent_div_9_2_ng_template_0_Template(rf, ctx) {}
function NumberComponent_div_9_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, NumberComponent_div_9_2_ng_template_0_Template, 0, 0, "ng-template", 13);
  }
  if (rf & 2) {
    const ctx_r18 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r18.invalidText);
  }
}
function NumberComponent_div_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 23);
    i0.ɵɵtemplate(1, NumberComponent_div_9_ng_container_1_Template, 2, 1, "ng-container", 12)(2, NumberComponent_div_9_2_Template, 1, 1, null, 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r6.isTemplate(ctx_r6.invalidText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r6.isTemplate(ctx_r6.invalidText));
  }
}
function NumberComponent_div_10_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r20 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r20.warnText);
  }
}
function NumberComponent_div_10_2_ng_template_0_Template(rf, ctx) {}
function NumberComponent_div_10_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, NumberComponent_div_10_2_ng_template_0_Template, 0, 0, "ng-template", 13);
  }
  if (rf & 2) {
    const ctx_r21 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r21.warnText);
  }
}
function NumberComponent_div_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 23);
    i0.ɵɵtemplate(1, NumberComponent_div_10_ng_container_1_Template, 2, 1, "ng-container", 12)(2, NumberComponent_div_10_2_Template, 1, 1, null, 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r7.isTemplate(ctx_r7.warnText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r7.isTemplate(ctx_r7.warnText));
  }
}
const _c2 = (a0, a1, a2, a3, a4, a5, a6) => ({
  "cds--number--light": a0,
  "cds--number--nolabel": a1,
  "cds--number--helpertext": a2,
  "cds--skeleton": a3,
  "cds--number--sm": a4,
  "cds--number--md": a5,
  "cds--number--lg": a6
});
const _c3 = a0 => ({
  "cds--number__input-wrapper--warning": a0
});
class NumberChange {}
/**
 * Get started with importing the module:
 *
 * ```typescript
 * import { NumberModule } from 'carbon-components-angular';
 * ```
 *
 * [See demo](../../?path=/story/components-number--basic)
 */
class NumberComponent {
  /**
   * Creates an instance of `Number`.
   */
  constructor(i18n) {
    this.i18n = i18n;
    this.containerClass = true;
    /**
     * @deprecated since v5 - Use `cdsLayer` directive instead
     * `light` or `dark` number input theme.
     */
    this.theme = "dark";
    /**
     * Set to `true` for a disabled number input.
     */
    this.disabled = false;
    /**
     * Set to `true` for a loading number component.
     */
    this.skeleton = false;
    /**
     * Set to `true` for an invalid number component.
     */
    this.invalid = false;
    /**
     * The unique id for the number component.
     */
    this.id = `number-${NumberComponent.numberCount}`;
    /**
     * Sets the placeholder attribute on the `input` element.
     */
    this.placeholder = "";
    /**
     * Number input field render size
     */
    this.size = "md";
    /**
     * Sets the min attribute on the `input` element.
     */
    this.min = null;
    /**
     * Sets the max attribute on the `input` element.
     */
    this.max = null;
    /**
     * Sets the amount the number controls increment and decrement by.
     */
    this.step = 1;
    /**
     * Set to `true` to show a warning (contents set by warningText)
     */
    this.warn = false;
    /**
     * Emits event notifying other classes when a change in state occurs in the input.
     */
    this.change = new EventEmitter();
    this._value = 0;
    this._decrementLabel = this.i18n.getOverridable("NUMBER.DECREMENT");
    this._incrementLabel = this.i18n.getOverridable("NUMBER.INCREMENT");
    /**
     * Called when number input is blurred. Needed to properly implement `ControlValueAccessor`.
     */
    this.onTouched = () => {};
    /**
     * Method set in `registerOnChange` to propagate changes back to the form.
     */
    this.propagateChange = _ => {};
    NumberComponent.numberCount++;
  }
  /**
   * Sets the value attribute on the `input` element.
   */
  set value(v) {
    if (v === "" || v === null) {
      this._value = null;
      return;
    }
    this._value = Number(v);
  }
  get value() {
    return this._value;
  }
  set decrementLabel(value) {
    this._decrementLabel.override(value);
  }
  get decrementLabel() {
    return this._decrementLabel.value;
  }
  set incrementLabel(value) {
    this._incrementLabel.override(value);
  }
  get incrementLabel() {
    return this._incrementLabel.value;
  }
  /**
   * This is the initial value set to the component
   * @param value The input value.
   */
  writeValue(value) {
    this.value = value;
  }
  /**
   * Sets a method in order to propagate changes back to the form.
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  /**
   * Registers a callback to be triggered when the control has been touched.
   * @param fn Callback to be triggered when the number input is touched.
   */
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  focusOut() {
    this.onTouched();
  }
  /**
   * Sets the disabled state through the model
   */
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  /**
   * Adds `step` to the current `value`.
   */
  onIncrement() {
    if (this.max === null || this.value + this.step <= this.max) {
      this.value += this.step;
      this.value = parseFloat(this.value.toPrecision(this.precision));
      this.emitChangeEvent();
    }
  }
  /**
   * Subtracts `step` to the current `value`.
   */
  onDecrement() {
    if (this.min === null || this.value - this.step >= this.min) {
      this.value -= this.step;
      this.value = parseFloat(this.value.toPrecision(this.precision));
      this.emitChangeEvent();
    }
  }
  getDecrementLabel() {
    return this._decrementLabel.subject;
  }
  getIncrementLabel() {
    return this._incrementLabel.subject;
  }
  /**
   * Creates a class of `NumberChange` to emit the change in the `Number`.
   */
  emitChangeEvent() {
    let event = new NumberChange();
    event.source = this;
    event.value = this.value;
    this.change.emit(event);
    this.propagateChange(this.value);
  }
  onNumberInputChange(event) {
    this.value = event.target.value;
    this.emitChangeEvent();
  }
  isTemplate(value) {
    return value instanceof TemplateRef;
  }
}
/**
 * Variable used for creating unique ids for number input components.
 */
NumberComponent.numberCount = 0;
NumberComponent.ɵfac = function NumberComponent_Factory(t) {
  return new (t || NumberComponent)(i0.ɵɵdirectiveInject(i1.I18n));
};
NumberComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NumberComponent,
  selectors: [["cds-number"], ["ibm-number"]],
  hostVars: 2,
  hostBindings: function NumberComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("focusout", function NumberComponent_focusout_HostBindingHandler() {
        return ctx.focusOut();
      });
    }
    if (rf & 2) {
      i0.ɵɵclassProp("cds--form-item", ctx.containerClass);
    }
  },
  inputs: {
    theme: "theme",
    disabled: "disabled",
    skeleton: "skeleton",
    invalid: "invalid",
    id: "id",
    placeholder: "placeholder",
    size: "size",
    required: "required",
    value: "value",
    min: "min",
    max: "max",
    label: "label",
    helperText: "helperText",
    invalidText: "invalidText",
    step: "step",
    precision: "precision",
    warn: "warn",
    warnText: "warnText",
    ariaLabel: "ariaLabel",
    decrementLabel: "decrementLabel",
    incrementLabel: "incrementLabel"
  },
  outputs: {
    change: "change"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: NumberComponent,
    multi: true
  }])],
  decls: 11,
  vars: 31,
  consts: [["data-numberinput", "", 1, "cds--number", 3, "ngClass"], ["class", "cds--label cds--skeleton", 4, "ngIf"], ["class", "cds--label", 3, "for", "ngClass", 4, "ngIf"], [1, "cds--number__input-wrapper", 3, "ngClass"], ["type", "number", 3, "id", "value", "disabled", "required", "placeholder", "change"], ["cdsIcon", "warning--filled", "size", "16", "class", "cds--number__invalid", 4, "ngIf"], ["cdsIcon", "warning--alt--filled", "size", "16", "class", "cds--number__invalid cds--number__invalid--warning", 4, "ngIf"], ["class", "cds--number__controls", 4, "ngIf"], ["class", "cds--form__helper-text", 3, "ngClass", 4, "ngIf"], ["class", "cds--form-requirement", 4, "ngIf"], [1, "cds--label", "cds--skeleton"], [1, "cds--label", 3, "for", "ngClass"], [4, "ngIf"], [3, "ngTemplateOutlet"], ["cdsIcon", "warning--filled", "size", "16", 1, "cds--number__invalid"], ["cdsIcon", "warning--alt--filled", "size", "16", 1, "cds--number__invalid", "cds--number__invalid--warning"], [1, "cds--number__controls"], ["type", "button", "aria-live", "polite", "aria-atomic", "true", 1, "cds--number__control-btn", "down-icon", 3, "click"], ["cdsIcon", "subtract", "size", "16"], [1, "cds--number__rule-divider"], ["type", "button", "aria-live", "polite", "aria-atomic", "true", 1, "cds--number__control-btn", "up-icon", 3, "click"], ["cdsIcon", "add", "size", "16"], [1, "cds--form__helper-text", 3, "ngClass"], [1, "cds--form-requirement"]],
  template: function NumberComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵtemplate(1, NumberComponent_label_1_Template, 1, 0, "label", 1)(2, NumberComponent_label_2_Template, 3, 6, "label", 2);
      i0.ɵɵelementStart(3, "div", 3)(4, "input", 4);
      i0.ɵɵlistener("change", function NumberComponent_Template_input_change_4_listener($event) {
        return ctx.onNumberInputChange($event);
      });
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(5, NumberComponent__svg_svg_5_Template, 1, 0, "svg", 5)(6, NumberComponent__svg_svg_6_Template, 1, 0, "svg", 6)(7, NumberComponent_div_7_Template, 9, 8, "div", 7);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(8, NumberComponent_div_8_Template, 3, 5, "div", 8)(9, NumberComponent_div_9_Template, 3, 2, "div", 9)(10, NumberComponent_div_10_Template, 3, 2, "div", 9);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction7(21, _c2, ctx.theme === "light", !ctx.label, ctx.helperText, ctx.skeleton, ctx.size === "sm", ctx.size === "md", ctx.size === "lg"));
      i0.ɵɵattribute("data-invalid", ctx.invalid ? true : null);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.skeleton && ctx.label);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.skeleton && ctx.label);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(29, _c3, ctx.warn));
      i0.ɵɵadvance();
      i0.ɵɵproperty("id", ctx.id)("value", ctx.value)("disabled", ctx.disabled)("required", ctx.required)("placeholder", ctx.placeholder);
      i0.ɵɵattribute("min", ctx.min)("max", ctx.max)("step", ctx.step)("aria-label", ctx.ariaLabel)("data-invalid", ctx.invalid ? ctx.invalid : null);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.skeleton && !ctx.warn && ctx.invalid);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.skeleton && !ctx.invalid && ctx.warn);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.skeleton);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.helperText && !ctx.invalid && !ctx.warn);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.warn && ctx.invalid);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.invalid && ctx.warn);
    }
  },
  dependencies: [i2.NgClass, i2.NgIf, i2.NgTemplateOutlet, i3.IconDirective, i2.AsyncPipe],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumberComponent, [{
    type: Component,
    args: [{
      selector: "cds-number, ibm-number",
      template: `
		<div
			data-numberinput
			[attr.data-invalid]="(invalid ? true : null)"
			class="cds--number"
			[ngClass]="{
				'cds--number--light': theme === 'light',
				'cds--number--nolabel': !label,
				'cds--number--helpertext': helperText,
				'cds--skeleton' : skeleton,
				'cds--number--sm': size === 'sm',
				'cds--number--md': size === 'md',
				'cds--number--lg': size === 'lg'
			}">
			<label *ngIf="skeleton && label" class="cds--label cds--skeleton"></label>
			<label
				*ngIf="!skeleton && label"
				[for]="id"
				class="cds--label"
				[ngClass]="{'cds--label--disabled': disabled}">
				<ng-container *ngIf="!isTemplate(label)">{{label}}</ng-container>
				<ng-template *ngIf="isTemplate(label)" [ngTemplateOutlet]="label"></ng-template>
			</label>
			<div
				class="cds--number__input-wrapper"
				[ngClass]="{
					'cds--number__input-wrapper--warning': warn
				}">
				<input
					type="number"
					[id]="id"
					[value]="value"
					[attr.min]="min"
					[attr.max]="max"
					[attr.step]="step"
					[disabled]="disabled"
					[required]="required"
					[attr.aria-label]="ariaLabel"
					[attr.data-invalid]="invalid ? invalid : null"
					[placeholder]="placeholder"
					(change)="onNumberInputChange($event)"/>
				<svg
					*ngIf="!skeleton && !warn && invalid"
					cdsIcon="warning--filled"
					size="16"
					class="cds--number__invalid">
				</svg>
				<svg
					*ngIf="!skeleton && !invalid && warn"
					cdsIcon="warning--alt--filled"
					size="16"
					class="cds--number__invalid cds--number__invalid--warning">
				</svg>
				<div *ngIf="!skeleton" class="cds--number__controls">
					<button
						class="cds--number__control-btn down-icon"
						type="button"
						[attr.disabled]="disabled ? true : null"
						aria-live="polite"
						aria-atomic="true"
						[attr.aria-label]="getDecrementLabel() | async"
						(click)="onDecrement()">
						<svg cdsIcon="subtract" size="16"></svg>
					</button>
					<div class="cds--number__rule-divider"></div>
					<button
						class="cds--number__control-btn up-icon"
						type="button"
						[attr.disabled]="disabled ? true : null"
						aria-live="polite"
						aria-atomic="true"
						[attr.aria-label]="getIncrementLabel() | async"
						(click)="onIncrement()">
						<svg cdsIcon="add" size="16"></svg>
					</button>
					<div class="cds--number__rule-divider"></div>
				</div>
			</div>
			<div
				*ngIf="helperText && !invalid && !warn"
				class="cds--form__helper-text"
				[ngClass]="{
					'cds--form__helper-text--disabled': disabled
				}">
				<ng-container *ngIf="!isTemplate(helperText)">{{helperText}}</ng-container>
				<ng-template *ngIf="isTemplate(helperText)" [ngTemplateOutlet]="helperText"></ng-template>
			</div>
			<div *ngIf="!warn && invalid" class="cds--form-requirement">
				<ng-container *ngIf="!isTemplate(invalidText)">{{invalidText}}</ng-container>
				<ng-template *ngIf="isTemplate(invalidText)" [ngTemplateOutlet]="invalidText"></ng-template>
			</div>
			<div *ngIf="!invalid && warn" class="cds--form-requirement">
				<ng-container *ngIf="!isTemplate(warnText)">{{warnText}}</ng-container>
				<ng-template *ngIf="isTemplate(warnText)" [ngTemplateOutlet]="warnText"></ng-template>
			</div>
		</div>
	`,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: NumberComponent,
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i1.I18n
    }];
  }, {
    containerClass: [{
      type: HostBinding,
      args: ["class.cds--form-item"]
    }],
    theme: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    skeleton: [{
      type: Input
    }],
    invalid: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    required: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    helperText: [{
      type: Input
    }],
    invalidText: [{
      type: Input
    }],
    step: [{
      type: Input
    }],
    precision: [{
      type: Input
    }],
    warn: [{
      type: Input
    }],
    warnText: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    change: [{
      type: Output
    }],
    decrementLabel: [{
      type: Input
    }],
    incrementLabel: [{
      type: Input
    }],
    focusOut: [{
      type: HostListener,
      args: ["focusout"]
    }]
  });
})();

// modules
class NumberModule {}
NumberModule.ɵfac = function NumberModule_Factory(t) {
  return new (t || NumberModule)();
};
NumberModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NumberModule
});
NumberModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [FormsModule, CommonModule, I18nModule, IconModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumberModule, [{
    type: NgModule,
    args: [{
      declarations: [NumberComponent],
      exports: [NumberComponent],
      imports: [FormsModule, CommonModule, I18nModule, IconModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NumberComponent as Number, NumberChange, NumberComponent, NumberModule };
