import { Checkbox } from 'carbon-components-angular/checkbox';
import * as i0 from '@angular/core';
import { TemplateRef, Component, Input, HostBinding, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import * as i1 from 'carbon-components-angular/i18n';
import { I18nModule } from 'carbon-components-angular/i18n';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';

/**
 * Defines the set of states for a toggle component.
 */
function Toggle_ng_container_0_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r3.label);
  }
}
function Toggle_ng_container_0_5_ng_template_0_Template(rf, ctx) {}
function Toggle_ng_container_0_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Toggle_ng_container_0_5_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r4.label);
  }
}
function Toggle_ng_container_0__svg_svg_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(0, "svg", 11);
    i0.ɵɵelement(1, "path", 12);
    i0.ɵɵelementEnd();
  }
}
const _c0 = a0 => ({
  "cds--visually-hidden": a0
});
const _c1 = a0 => ({
  "cds--toggle__appearance--sm": a0
});
const _c2 = a0 => ({
  "cds--toggle__switch--checked": a0
});
function Toggle_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "button", 2);
    i0.ɵɵlistener("click", function Toggle_ng_container_0_Template_button_click_1_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r7 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r7.onClick($event));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "label", 3)(3, "span", 4);
    i0.ɵɵtemplate(4, Toggle_ng_container_0_ng_container_4_Template, 2, 1, "ng-container", 5)(5, Toggle_ng_container_0_5_Template, 1, 1, null, 5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 6)(7, "div", 7);
    i0.ɵɵtemplate(8, Toggle_ng_container_0__svg_svg_8_Template, 2, 0, "svg", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "span", 9);
    i0.ɵɵtext(10);
    i0.ɵɵpipe(11, "async");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r0.disabled)("id", ctx_r0.id);
    i0.ɵɵattribute("aria-checked", ctx_r0.checked);
    i0.ɵɵadvance();
    i0.ɵɵproperty("for", ctx_r0.id);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(13, _c0, ctx_r0.hideLabel));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.isTemplate(ctx_r0.label));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.isTemplate(ctx_r0.label));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(15, _c1, ctx_r0.size === "sm"));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(17, _c2, ctx_r0.checked));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.size === "sm");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.hideLabel ? ctx_r0.label : i0.ɵɵpipeBind1(11, 11, ctx_r0.getCheckedText()), " ");
  }
}
function Toggle_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 13)(1, "div", 14);
  }
}
var ToggleState;
(function (ToggleState) {
  ToggleState[ToggleState["Init"] = 0] = "Init";
  ToggleState[ToggleState["Checked"] = 1] = "Checked";
  ToggleState[ToggleState["Unchecked"] = 2] = "Unchecked";
})(ToggleState || (ToggleState = {}));
/**
 * Get started with importing the module:
 *
 * ```typescript
 * import { ToggleModule } from 'carbon-components-angular';
 * ```
 *
 * ```html
 * <cds-toggle [(ngModel)]="toggleState">Toggle</cds-toggle>
 * ```
 *
 * [See demo](../../?path=/story/components-toggle--basic)
 */
class Toggle extends Checkbox {
  /**
   * Creates an instance of Toggle.
   */
  constructor(changeDetectorRef, i18n) {
    super(changeDetectorRef);
    this.changeDetectorRef = changeDetectorRef;
    this.i18n = i18n;
    /**
     * Size of the toggle component.
     */
    this.size = "md";
    /**
     * Set to `true` to hide the toggle label & set toggle on/off text to label.
     */
    this.hideLabel = false;
    this.skeleton = false;
    this.toggleClass = true;
    /**
     * The unique id allocated to the `Toggle`.
     */
    this.id = "toggle-" + Toggle.toggleCount;
    this._offValues = this.i18n.getOverridable("TOGGLE.OFF");
    this._onValues = this.i18n.getOverridable("TOGGLE.ON");
    Toggle.toggleCount++;
  }
  /**
   * Text that is set on the left side of the toggle.
   */
  set offText(value) {
    this._offValues.override(value);
  }
  get offText() {
    return this._offValues.value;
  }
  /**
   * Text that is set on the right side of the toggle.
   */
  set onText(value) {
    this._onValues.override(value);
  }
  get onText() {
    return this._onValues.value;
  }
  get disabledClass() {
    return this.disabled;
  }
  get formItem() {
    return !this.skeleton;
  }
  /**
   * `ControlValueAccessor` method to programmatically disable the toggle input.
   *
   * ex: `this.formGroup.get("myToggle").disable();`
   *
   * @param isDisabled `true` to disable the input
   */
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  getOffText() {
    return this._offValues.subject;
  }
  getOnText() {
    return this._onValues.subject;
  }
  getCheckedText() {
    if (this.checked) {
      return this._onValues.subject;
    }
    return this._offValues.subject;
  }
  /**
   * Creates instance of `ToggleChange` used to propagate the change event.
   */
  emitChangeEvent() {
    this.checkedChange.emit(this.checked);
    this.propagateChange(this.checked);
  }
  isTemplate(value) {
    return value instanceof TemplateRef;
  }
}
/**
 * Variable used for creating unique ids for toggle components.
 */
Toggle.toggleCount = 0;
Toggle.ɵfac = function Toggle_Factory(t) {
  return new (t || Toggle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.I18n));
};
Toggle.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Toggle,
  selectors: [["cds-toggle"], ["ibm-toggle"]],
  hostVars: 8,
  hostBindings: function Toggle_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--toggle--skeleton", ctx.skeleton)("cds--toggle", ctx.toggleClass)("cds--toggle--disabled", ctx.disabledClass)("cds--form-item", ctx.formItem);
    }
  },
  inputs: {
    offText: "offText",
    onText: "onText",
    label: "label",
    size: "size",
    hideLabel: "hideLabel",
    skeleton: "skeleton"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: Toggle,
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 2,
  consts: [[4, "ngIf", "ngIfElse"], ["skeletonTemplate", ""], ["role", "switch", "type", "button", 1, "cds--toggle__button", 3, "disabled", "id", "click"], [1, "cds--toggle__label", 3, "for"], [1, "cds--toggle__label-text", 3, "ngClass"], [4, "ngIf"], [1, "cds--toggle__appearance", 3, "ngClass"], [1, "cds--toggle__switch", 3, "ngClass"], ["class", "cds--toggle__check", "width", "6px", "height", "5px", "viewBox", "0 0 6 5", 4, "ngIf"], [1, "cds--toggle__text"], [3, "ngTemplateOutlet"], ["width", "6px", "height", "5px", "viewBox", "0 0 6 5", 1, "cds--toggle__check"], ["d", "M2.2 2.7L5 0 6 1 2.2 5 0 2.7 1 1.5z"], [1, "cds--toggle__skeleton-circle"], [1, "cds--toggle__skeleton-rectangle"]],
  template: function Toggle_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, Toggle_ng_container_0_Template, 12, 19, "ng-container", 0)(1, Toggle_ng_template_1_Template, 2, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      const _r2 = i0.ɵɵreference(2);
      i0.ɵɵproperty("ngIf", !ctx.skeleton)("ngIfElse", _r2);
    }
  },
  dependencies: [i2.NgClass, i2.NgIf, i2.NgTemplateOutlet, i2.AsyncPipe],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Toggle, [{
    type: Component,
    args: [{
      selector: "cds-toggle, ibm-toggle",
      template: `
		<ng-container *ngIf="!skeleton; else skeletonTemplate;">
			<button
				class="cds--toggle__button"
				[disabled]="disabled"
				[id]="id"
				role="switch"
				type="button"
				[attr.aria-checked]="checked"
				(click)="onClick($event)">
			</button>
			<label
				class="cds--toggle__label"
				[for]="id">
				<span
					class="cds--toggle__label-text"
					[ngClass]="{
						'cds--visually-hidden': hideLabel
					}">
					<ng-container *ngIf="!isTemplate(label)">{{label}}</ng-container>
					<ng-template *ngIf="isTemplate(label)" [ngTemplateOutlet]="label"></ng-template>
				</span>
				<div
					class="cds--toggle__appearance"
					[ngClass]="{
						'cds--toggle__appearance--sm': size === 'sm'
					}">
					<div
						class="cds--toggle__switch"
						[ngClass]="{
							'cds--toggle__switch--checked': checked
						}">
						<svg
							*ngIf="size === 'sm'"
							class='cds--toggle__check'
							width="6px"
							height="5px"
							viewBox="0 0 6 5">
							<path d="M2.2 2.7L5 0 6 1 2.2 5 0 2.7 1 1.5z" />
						</svg>
					</div>
					<span class="cds--toggle__text">
						{{(hideLabel ? label : (getCheckedText() | async))}}
					</span>
				</div>
			</label>
		</ng-container>
		<ng-template #skeletonTemplate>
			<div class="cds--toggle__skeleton-circle"></div>
			<div class="cds--toggle__skeleton-rectangle"></div>
		</ng-template>
	`,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: Toggle,
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i1.I18n
    }];
  }, {
    offText: [{
      type: Input
    }],
    onText: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    hideLabel: [{
      type: Input
    }],
    skeleton: [{
      type: HostBinding,
      args: ["class.cds--toggle--skeleton"]
    }, {
      type: Input
    }],
    toggleClass: [{
      type: HostBinding,
      args: ["class.cds--toggle"]
    }],
    disabledClass: [{
      type: HostBinding,
      args: ["class.cds--toggle--disabled"]
    }],
    formItem: [{
      type: HostBinding,
      args: ["class.cds--form-item"]
    }]
  });
})();

// modules
class ToggleModule {}
ToggleModule.ɵfac = function ToggleModule_Factory(t) {
  return new (t || ToggleModule)();
};
ToggleModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ToggleModule
});
ToggleModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, FormsModule, I18nModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToggleModule, [{
    type: NgModule,
    args: [{
      declarations: [Toggle],
      exports: [Toggle],
      imports: [CommonModule, FormsModule, I18nModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Toggle, ToggleModule, ToggleState };
