import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output, ViewChild, HostListener, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';

/**
 * Defines the set of states for a checkbox component.
 */
const _c0 = ["inputCheckbox"];
const _c1 = a0 => ({
  "cds--skeleton": a0
});
const _c2 = a0 => ({
  "cds--visually-hidden": a0
});
const _c3 = ["*"];
var CheckboxState;
(function (CheckboxState) {
  CheckboxState[CheckboxState["Init"] = 0] = "Init";
  CheckboxState[CheckboxState["Indeterminate"] = 1] = "Indeterminate";
  CheckboxState[CheckboxState["Checked"] = 2] = "Checked";
  CheckboxState[CheckboxState["Unchecked"] = 3] = "Unchecked";
})(CheckboxState || (CheckboxState = {}));
/**
 * Get started with importing the module:
 *
 * ```typescript
 * import { CheckboxModule } from 'carbon-components-angular';
 * ```
 *
 * [See demo](../../?path=/story/components-checkbox--basic)
 */
class Checkbox {
  /**
   * Creates an instance of `Checkbox`.
   */
  constructor(changeDetectorRef) {
    this.changeDetectorRef = changeDetectorRef;
    /**
     * Set to `true` for a disabled checkbox.
     */
    this.disabled = false;
    /**
     * Set to `true` for a loading checkbox.
     */
    this.skeleton = false;
    /**
     * Set to `true` to hide the checkbox labels.
     */
    this.hideLabel = false;
    /**
     * The unique id for the checkbox component.
     */
    this.id = `checkbox-${Checkbox.checkboxCount}`;
    /**
     * Emits click event.
     */
    this.click = new EventEmitter();
    /**
     * Emits an event when the value of the checkbox changes.
     *
     * Allows double biding with the `checked` Input.
     */
    this.checkedChange = new EventEmitter();
    /**
     * Emits event notifying other classes when a change in state occurs specifically
     * on an indeterminate checkbox.
     */
    this.indeterminateChange = new EventEmitter();
    /**
     * Set to `true` if the input checkbox is selected (or checked).
     */
    this._checked = false;
    /**
     * Set to `true` if the input checkbox is in state indeterminate.
     */
    this._indeterminate = false;
    /**
     * Keeps a reference to the checkboxes current state, as defined in `CheckboxState`.
     */
    this.currentCheckboxState = CheckboxState.Init;
    /**
     * Called when checkbox is blurred. Needed to properly implement `ControlValueAccessor`.
     */
    this.onTouched = () => {};
    /**
     * Method set in `registerOnChange` to propagate changes back to the form.
     */
    this.propagateChange = _ => {};
    Checkbox.checkboxCount++;
  }
  /**
   * Set the checkbox's indeterminate state to match the parameter and transition the view to reflect the change.
   *
   * Allows double binding with the `indeterminateChange` Output.
   */
  set indeterminate(indeterminate) {
    if (indeterminate === this._indeterminate) {
      return;
    }
    this._indeterminate = indeterminate;
    if (this._indeterminate) {
      this.transitionCheckboxState(CheckboxState.Indeterminate);
    } else {
      this.transitionCheckboxState(this.checked ? CheckboxState.Checked : CheckboxState.Unchecked);
    }
    if (this.inputCheckbox && this.inputCheckbox.nativeElement) {
      this.inputCheckbox.nativeElement.indeterminate = indeterminate;
    }
    this.changeDetectorRef.markForCheck();
    this.indeterminateChange.emit(this._indeterminate);
  }
  /**
   * Reflects whether the checkbox state is indeterminate.
   */
  get indeterminate() {
    return this._indeterminate;
  }
  /**
   * Sets the `checked` state. `true` for checked, `false` for unchecked
   *
   * Allows double binding with the `checkedChange` Output.
   */
  set checked(checked) {
    this.setChecked(checked, false);
  }
  /**
   * Returns value `true` if state is selected for the checkbox.
   */
  get checked() {
    return this._checked;
  }
  /**
   * Toggle the selected state of the checkbox.
   */
  toggle() {
    // Flip checked and reset indeterminate
    this.setChecked(!this.checked, true);
  }
  /**
   * Writes a value from `ngModel` to the component.
   *
   * In this case the value is the `checked` property.
   *
   * @param value boolean, corresponds to the `checked` property.
   */
  writeValue(value) {
    // Set checked and reset indeterminate
    this.setChecked(!!value, true);
  }
  /**
   * Sets a method in order to propagate changes back to the form.
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  /**
   * Registers a callback to be triggered when the control has been touched.
   * @param fn Callback to be triggered when the checkbox is touched.
   */
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  /**
   * `ControlValueAccessor` method to programmatically disable the checkbox.
   *
   * ex: `this.formGroup.get("myCheckbox").disable();`
   *
   * @param isDisabled `true` to disable the checkbox
   */
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }
  focusOut() {
    this.onTouched();
  }
  /**
   * Executes on the event of a change within `Checkbox` to block propagation.
   */
  onChange(event) {
    event.stopPropagation();
  }
  /**
   * Handles click events on the `Checkbox` and emits changes to other classes.
   */
  onClick(event) {
    if (this.click.observers.length) {
      // Disable default checkbox activation behavior which flips checked and resets indeterminate.
      // This allows the parent component to control the checked/indeterminate properties.
      event.preventDefault();
      this.click.emit();
      return;
    }
    if (!this.disabled) {
      this.toggle();
      this.transitionCheckboxState(this._checked ? CheckboxState.Checked : CheckboxState.Unchecked);
      this.emitChangeEvent();
    }
  }
  /**
   * Handles changes between checkbox states.
   */
  transitionCheckboxState(newState) {
    this.currentCheckboxState = newState;
  }
  /**
   * Creates instance of `CheckboxChange` used to propagate the change event.
   */
  emitChangeEvent() {
    this.checkedChange.emit(this.checked);
    this.propagateChange(this.checked);
  }
  /**
   * Updates the checkbox if it is in the indeterminate state.
   */
  ngAfterViewInit() {
    if (this.indeterminate && this.inputCheckbox && this.inputCheckbox.nativeElement) {
      this.inputCheckbox.nativeElement.indeterminate = true;
    }
  }
  /**
   * Sets checked state and optionally resets indeterminate state.
   */
  setChecked(checked, resetIndeterminate) {
    if (checked === this._checked) {
      return;
    }
    this._checked = checked;
    // Reset indeterminate if requested
    if (resetIndeterminate && this._indeterminate) {
      this._indeterminate = false;
      Promise.resolve().then(() => {
        this.indeterminateChange.emit(this._indeterminate);
      });
    }
    this.changeDetectorRef.markForCheck();
  }
}
/**
 * Variable used for creating unique ids for checkbox components.
 */
Checkbox.checkboxCount = 0;
Checkbox.ɵfac = function Checkbox_Factory(t) {
  return new (t || Checkbox)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
Checkbox.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Checkbox,
  selectors: [["cds-checkbox"], ["ibm-checkbox"]],
  viewQuery: function Checkbox_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputCheckbox = _t.first);
    }
  },
  hostBindings: function Checkbox_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("focusout", function Checkbox_focusout_HostBindingHandler() {
        return ctx.focusOut();
      });
    }
  },
  inputs: {
    disabled: "disabled",
    skeleton: "skeleton",
    hideLabel: "hideLabel",
    name: "name",
    id: "id",
    required: "required",
    value: "value",
    ariaLabel: "ariaLabel",
    ariaLabelledby: "ariaLabelledby",
    indeterminate: "indeterminate",
    checked: "checked"
  },
  outputs: {
    click: "click",
    checkedChange: "checkedChange",
    indeterminateChange: "indeterminateChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: Checkbox,
    multi: true
  }])],
  ngContentSelectors: _c3,
  decls: 6,
  vars: 15,
  consts: [[1, "cds--form-item", "cds--checkbox-wrapper"], ["type", "checkbox", 1, "cds--checkbox", 3, "id", "value", "name", "required", "checked", "disabled", "change", "click"], ["inputCheckbox", ""], [1, "cds--checkbox-label", 3, "for", "ngClass"], [1, "cds--checkbox-label-text", 3, "ngClass"]],
  template: function Checkbox_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div", 0)(1, "input", 1, 2);
      i0.ɵɵlistener("change", function Checkbox_Template_input_change_1_listener($event) {
        return ctx.onChange($event);
      })("click", function Checkbox_Template_input_click_1_listener($event) {
        return ctx.onClick($event);
      });
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(3, "label", 3)(4, "span", 4);
      i0.ɵɵprojection(5);
      i0.ɵɵelementEnd()()();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("id", ctx.id + "_input")("value", ctx.value)("name", ctx.name)("required", ctx.required)("checked", ctx.checked)("disabled", ctx.disabled);
      i0.ɵɵattribute("aria-labelledby", ctx.ariaLabelledby);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("for", ctx.id + "_input")("ngClass", i0.ɵɵpureFunction1(11, _c1, ctx.skeleton));
      i0.ɵɵattribute("aria-label", ctx.ariaLabel);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(13, _c2, ctx.hideLabel));
    }
  },
  dependencies: [i1.NgClass],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Checkbox, [{
    type: Component,
    args: [{
      selector: "cds-checkbox, ibm-checkbox",
      template: `
		<div class="cds--form-item cds--checkbox-wrapper">
			<input
				#inputCheckbox
				class="cds--checkbox"
				type="checkbox"
				[id]="id + '_input'"
				[value]="value"
				[name]="name"
				[required]="required"
				[checked]="checked"
				[disabled]="disabled"
				[attr.aria-labelledby]="ariaLabelledby"
				(change)="onChange($event)"
				(click)="onClick($event)">
			<label
				[for]="id + '_input'"
				[attr.aria-label]="ariaLabel"
				class="cds--checkbox-label"
				[ngClass]="{
					'cds--skeleton' : skeleton
				}">
				<span [ngClass]="{'cds--visually-hidden' : hideLabel}" class="cds--checkbox-label-text">
					<ng-content></ng-content>
				</span>
			</label>
		</div>
	`,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: Checkbox,
        multi: true
      }],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }];
  }, {
    disabled: [{
      type: Input
    }],
    skeleton: [{
      type: Input
    }],
    hideLabel: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    required: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    ariaLabelledby: [{
      type: Input
    }],
    indeterminate: [{
      type: Input
    }],
    checked: [{
      type: Input
    }],
    click: [{
      type: Output
    }],
    checkedChange: [{
      type: Output
    }],
    indeterminateChange: [{
      type: Output
    }],
    inputCheckbox: [{
      type: ViewChild,
      args: ["inputCheckbox"]
    }],
    focusOut: [{
      type: HostListener,
      args: ["focusout"]
    }]
  });
})();

// modules
class CheckboxModule {}
CheckboxModule.ɵfac = function CheckboxModule_Factory(t) {
  return new (t || CheckboxModule)();
};
CheckboxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: CheckboxModule
});
CheckboxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, FormsModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckboxModule, [{
    type: NgModule,
    args: [{
      declarations: [Checkbox],
      exports: [Checkbox],
      imports: [CommonModule, FormsModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Checkbox, CheckboxModule, CheckboxState };
