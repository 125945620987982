import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Component, Optional, Output, Input, ViewChild, HostListener, Directive, HostBinding, ViewEncapsulation, ContentChild, NgModule } from '@angular/core';
import { tabbableSelector, getFocusElementList, cycleTabs, isFocusInFirstItem, isFocusInLastItem } from 'carbon-components-angular/common';
import * as i1 from 'carbon-components-angular/placeholder';
import { PlaceholderModule } from 'carbon-components-angular/placeholder';
import { Subscription } from 'rxjs';
import Position, { position } from '@carbon/utils-position';
import * as i2 from 'carbon-components-angular/utils';
import { closestAttr, UtilsModule } from 'carbon-components-angular/utils';
import * as i1$1 from 'carbon-components-angular/i18n';
import { I18nModule } from 'carbon-components-angular/i18n';
import * as i2$1 from 'carbon-components-angular/experimental';
import { ExperimentalModule } from 'carbon-components-angular/experimental';
import * as i2$2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from 'carbon-components-angular/icon';
import { IconModule } from 'carbon-components-angular/icon';

/**
 * An enum of the various reasons a dialog may close. For use with `CloseMeta` and `shouldClose`
 *
 * It's expected that `interaction` will be a common closure reason.
 */
const _c0 = ["dialog"];
function OverflowMenuPane_ng_template_2_Template(rf, ctx) {}
const _c1 = a0 => ({
  "cds--overflow-menu--flip": a0
});
const _c2 = a0 => ({
  overflowMenu: a0
});
function OverflowMenuCustomPane_ng_template_2_Template(rf, ctx) {}
function OverflowMenu_1_ng_template_0_Template(rf, ctx) {}
function OverflowMenu_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, OverflowMenu_1_ng_template_0_Template, 0, 0, "ng-template", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.customTrigger);
  }
}
function OverflowMenu_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
function OverflowMenu_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 5);
  }
}
const _c3 = a0 => ({
  "cds--overflow-menu--open": a0
});
const _c4 = ["*"];
function OverflowMenuOption_button_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function OverflowMenuOption_button_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 3);
    i0.ɵɵlistener("focus", function OverflowMenuOption_button_0_Template_button_focus_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.onFocus());
    })("blur", function OverflowMenuOption_button_0_Template_button_blur_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r7 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r7.onBlur());
    })("click", function OverflowMenuOption_button_0_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r8 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r8.onClick());
    });
    i0.ɵɵtemplate(1, OverflowMenuOption_button_0_ng_container_1_Template, 1, 0, "ng-container", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    const _r3 = i0.ɵɵreference(3);
    i0.ɵɵclassMapInterpolate1("cds--overflow-menu-options__btn ", ctx_r0.innerClass, "");
    i0.ɵɵproperty("tabindex", ctx_r0.tabIndex)("disabled", ctx_r0.disabled);
    i0.ɵɵattribute("title", ctx_r0.title);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", _r3);
  }
}
function OverflowMenuOption_a_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function OverflowMenuOption_a_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 5);
    i0.ɵɵlistener("focus", function OverflowMenuOption_a_1_Template_a_focus_0_listener() {
      i0.ɵɵrestoreView(_r11);
      const ctx_r10 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r10.onFocus());
    })("blur", function OverflowMenuOption_a_1_Template_a_blur_0_listener() {
      i0.ɵɵrestoreView(_r11);
      const ctx_r12 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r12.onBlur());
    })("click", function OverflowMenuOption_a_1_Template_a_click_0_listener() {
      i0.ɵɵrestoreView(_r11);
      const ctx_r13 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r13.onClick());
    });
    i0.ɵɵtemplate(1, OverflowMenuOption_a_1_ng_container_1_Template, 1, 0, "ng-container", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    const _r3 = i0.ɵɵreference(3);
    i0.ɵɵclassMapInterpolate1("cds--overflow-menu-options__btn ", ctx_r1.innerClass, "");
    i0.ɵɵproperty("tabindex", ctx_r1.tabIndex)("href", ctx_r1.href, i0.ɵɵsanitizeUrl);
    i0.ɵɵattribute("disabled", ctx_r1.disabled)("target", ctx_r1.target)("rel", ctx_r1.rel)("title", ctx_r1.title);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", _r3);
  }
}
function OverflowMenuOption_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵprojection(1);
    i0.ɵɵelementEnd();
  }
}
var CloseReasons;
(function (CloseReasons) {
  /**
   * For when the component is closed by being destroyed
   */
  CloseReasons[CloseReasons["destroyed"] = 0] = "destroyed";
  /**
   * For use in cases where the dialog closes for programmatic reasons other than destruction
   */
  CloseReasons[CloseReasons["programmatic"] = 1] = "programmatic";
  /**
   * interaction reasons will also provide a target for the interaction
   */
  CloseReasons[CloseReasons["interaction"] = 2] = "interaction";
  /**
   * For use in cases where the dialog closes due to being hidden
   */
  CloseReasons[CloseReasons["hidden"] = 3] = "hidden";
})(CloseReasons || (CloseReasons = {}));

/**
 * `Dialog` object to be injected into other components.
 */
class DialogService {
  /**
   * Creates an instance of `DialogService`.
   */
  constructor(injector, placeholderService) {
    this.injector = injector;
    this.placeholderService = placeholderService;
  }
  /**
   * Closes all known `Dialog`s. Does not focus any previous elements, since we can't know which would be correct
   */
  static closeAll() {
    DialogService.dialogRefs.forEach(ref => ref.instance.doClose({
      reason: CloseReasons.programmatic
    }));
    DialogService.dialogRefs.clear();
  }
  /**
   * If `dialogRef` is defined, the Dialog is already open. If
   * `dialogRef` is undefined, we create the `Dialog` component and reference to it.
   * A subscription is created to track if the `Dialog` should close.
   *
   * @param viewContainer a `ViewContainerRef` to instantiate the component against.
   * May be `null` if an `cds-placeholder` exists and `dialogConfig.appendInline` is false
   * @param dialogConfig the `DialogConfig` for the component
   */
  open(viewContainer, dialogConfig, component) {
    if (!component) {
      return;
    }
    let dialogRef;
    if (dialogConfig.appendInline) {
      // add our component to the view
      dialogRef = viewContainer.createComponent(component, {
        index: 0,
        injector: this.injector
      });
    } else if (!this.placeholderService.hasPlaceholderRef()) {
      dialogRef = viewContainer.createComponent(component, {
        index: 0,
        injector: this.injector
      });
      if (dialogRef) {
        setTimeout(() => {
          window.document.querySelector("body").appendChild(dialogRef.location.nativeElement);
        });
      }
    } else {
      dialogRef = this.placeholderService.createComponent(component, this.injector);
    }
    // keep track of all initialized dialogs
    DialogService.dialogRefs.add(dialogRef);
    // initialize some extra options
    dialogConfig["previouslyFocusedElement"] = document.activeElement;
    dialogRef.instance.dialogConfig = dialogConfig;
    dialogRef.instance.elementRef.nativeElement.focus();
    return dialogRef;
  }
  /**
   * On close of `Dialog` item, sets focus back to previous item, unsets
   * the current `dialogRef` item. Unsubscribes to the event of `Dialog` close.
   *
   * @param dialogRef the dialogRef to close
   */
  close(dialogRef) {
    // to handle the case where we have a null `this.dialogRef`
    if (!dialogRef) {
      return;
    }
    const elementToFocus = dialogRef.instance.dialogConfig["previouslyFocusedElement"];
    dialogRef.destroy();
    // update the globally tracked dialogRefs
    if (DialogService.dialogRefs.has(dialogRef)) {
      DialogService.dialogRefs.delete(dialogRef);
    }
    // Keeps the focus on the dialog trigger if there are no focusable elements. Change focus to previously focused element
    // if there are focusable elements in the dialog.
    if (!dialogRef.location.nativeElement.querySelectorAll(tabbableSelector)) {
      elementToFocus.focus();
    }
  }
  /**
   * Fix for safari hijacking clicks.
   *
   * Runs on `ngOnInit` of every dialog. Ensures we don't have multiple listeners
   * because having many of them could degrade performance in certain cases (and is
   * not necessary for our use case)
   *
   * This is an internally used function, can change at any point (even get removed)
   * and changes to it won't be considered a breaking change. Use at your own risk.
   */
  singletonClickListen() {
    if (!DialogService.listeningForBodyClicks) {
      document.body.firstElementChild.addEventListener("click", () => null, true);
      DialogService.listeningForBodyClicks = true;
    }
  }
}
/**
 * Used in `singletonClickListen`, don't count on its existence and values.
 */
DialogService.listeningForBodyClicks = false;
/**
 * A set of all known dialog components
 */
DialogService.dialogRefs = new Set();
DialogService.ɵfac = function DialogService_Factory(t) {
  return new (t || DialogService)(i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i1.PlaceholderService));
};
DialogService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: DialogService,
  factory: DialogService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DialogService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.Injector
    }, {
      type: i1.PlaceholderService
    }];
  }, null);
})();

/**
 * Implements a `Dialog` that can be positioned anywhere on the page.
 * Used to implement a popover or tooltip.
 */
class Dialog {
  /**
   * Creates an instance of `Dialog`.
   * @param elementRef
   * @param elementService
   */
  constructor(elementRef, elementService, animationFrameService = null) {
    this.elementRef = elementRef;
    this.elementService = elementService;
    this.animationFrameService = animationFrameService;
    /**
     * Emits event that handles the closing of a `Dialog` object.
     */
    this.close = new EventEmitter();
    /**
     * Stores the data received from `dialogConfig`.
     */
    this.data = {};
    this.visibilitySubscription = new Subscription();
    this.animationFrameSubscription = new Subscription();
    /**
     * Handles offsetting the `Dialog` item based on the defined position
     * to not obscure the content beneath.
     */
    this.addGap = {
      "left": pos => position.addOffset(pos, 0, -this.dialogConfig.gap),
      "right": pos => position.addOffset(pos, 0, this.dialogConfig.gap),
      "top": pos => position.addOffset(pos, -this.dialogConfig.gap),
      "bottom": pos => position.addOffset(pos, this.dialogConfig.gap),
      "left-bottom": pos => position.addOffset(pos, 0, -this.dialogConfig.gap),
      "right-bottom": pos => position.addOffset(pos, 0, this.dialogConfig.gap)
    };
    /**
     * Extra placements. Child classes can add to this for use in `placeDialog`.
     */
    this.placements = {};
  }
  /**
   * Initialize the `Dialog`, set the placement and gap, and add a `Subscription` to resize events.
   */
  ngOnInit() {
    this.placement = this.dialogConfig.placement.split(",")[0];
    this.data = this.dialogConfig.data;
    // run any additional initialization code that consuming classes may have
    this.onDialogInit();
  }
  /**
   * After the DOM is ready, focus is set and dialog is placed
   * in respect to the parent element.
   */
  ngAfterViewInit() {
    const dialogElement = this.dialog.nativeElement;
    // split the wrapper class list and apply separately to avoid IE
    // 1. throwing an error due to assigning a readonly property (classList)
    // 2. throwing a SyntaxError due to passing an empty string to `add`
    if (this.dialogConfig.wrapperClass) {
      for (const extraClass of this.dialogConfig.wrapperClass.split(" ")) {
        dialogElement.classList.add(extraClass);
      }
    }
    // only focus the dialog if there are focusable elements within the dialog
    if (getFocusElementList(this.dialog.nativeElement).length > 0) {
      dialogElement.focus();
    }
    const parentElement = this.dialogConfig.parentRef.nativeElement;
    if (this.animationFrameService) {
      this.animationFrameSubscription = this.animationFrameService.tick.subscribe(() => {
        this.placeDialog();
      });
    }
    if (this.dialogConfig.closeWhenHidden) {
      this.visibilitySubscription = this.elementService.visibility(parentElement, parentElement).subscribe(value => {
        this.placeDialog();
        if (!value.visible) {
          this.doClose({
            reason: CloseReasons.hidden
          });
        }
      });
    }
    this.placeDialog();
    // run afterDialogViewInit on the next tick
    setTimeout(() => this.afterDialogViewInit());
  }
  /**
   * Empty method to be overridden by consuming classes to run any additional initialization code.
   */
  onDialogInit() {}
  /**
   * Empty method to be overridden by consuming classes to run any additional initialization code after the view is available.
   * NOTE: this does _not_ guarantee the dialog will be positioned, simply that it will exist in the DOM
   */
  afterDialogViewInit() {}
  /**
   * Uses the position service to position the `Dialog` in screen space
   */
  placeDialog() {
    const positionService = new Position(this.placements);
    // helper to find the position based on the current/given environment
    const findPosition = (reference, target, placement) => {
      let pos;
      if (this.dialogConfig.appendInline) {
        pos = this.addGap[placement](positionService.findRelative(reference, target, placement));
      } else {
        pos = this.addGap[placement](positionService.findAbsolute(reference, target, placement));
      }
      if (this.dialogConfig.offset) {
        // Apply vertical and horizontal offsets given through the dialogConfig
        pos.top = pos.top + this.dialogConfig.offset.y;
        pos.left = pos.left + this.dialogConfig.offset.x;
      }
      return pos;
    };
    let parentEl = this.dialogConfig.parentRef.nativeElement;
    let el = this.dialog.nativeElement;
    let dialogPlacement = this.placement;
    // split always returns an array, so we can just use the auto position logic
    // for single positions too
    const placements = this.dialogConfig.placement.split(",");
    // find the best placement
    dialogPlacement = positionService.findBestPlacement(parentEl, el, placements);
    // calculate the final position
    const pos = findPosition(parentEl, el, dialogPlacement);
    // update the element
    positionService.setElement(el, pos);
    setTimeout(() => {
      this.placement = dialogPlacement;
    });
  }
  /**
   * Sets up a KeyboardEvent to close `Dialog` with Escape key.
   * @param event
   */
  escapeClose(event) {
    switch (event.key) {
      case "Escape":
        {
          event.stopImmediatePropagation();
          this.doClose({
            reason: CloseReasons.interaction,
            target: event.target
          });
          break;
        }
      case "Tab":
        {
          cycleTabs(event, this.elementRef.nativeElement);
          break;
        }
    }
  }
  /**
   * Sets up a event Listener to close `Dialog` if click event occurs outside
   * `Dialog` object.
   * @param event
   */
  clickClose(event) {
    if (!this.elementRef.nativeElement.contains(event.target) && !this.dialogConfig.parentRef.nativeElement.contains(event.target)) {
      this.doClose({
        reason: CloseReasons.interaction,
        target: event.target
      });
    }
  }
  /**
   * Closes `Dialog` object by emitting the close event upwards to parents.
   */
  doClose(meta = {
    reason: CloseReasons.interaction
  }) {
    this.close.emit(meta);
  }
  /**
   * At destruction of component, `Dialog` unsubscribes from all the subscriptions.
   */
  ngOnDestroy() {
    this.visibilitySubscription.unsubscribe();
    if (this.animationFrameSubscription) {
      this.animationFrameSubscription.unsubscribe();
    }
  }
}
Dialog.ɵfac = function Dialog_Factory(t) {
  return new (t || Dialog)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i2.ElementService), i0.ɵɵdirectiveInject(i2.AnimationFrameService, 8));
};
Dialog.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Dialog,
  selectors: [["cds-dialog"], ["ibm-dialog"]],
  viewQuery: function Dialog_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dialog = _t.first);
    }
  },
  hostBindings: function Dialog_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("keydown", function Dialog_keydown_HostBindingHandler($event) {
        return ctx.escapeClose($event);
      })("click", function Dialog_click_HostBindingHandler($event) {
        return ctx.clickClose($event);
      }, false, i0.ɵɵresolveDocument);
    }
  },
  inputs: {
    dialogConfig: "dialogConfig"
  },
  outputs: {
    close: "close"
  },
  decls: 0,
  vars: 0,
  template: function Dialog_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Dialog, [{
    type: Component,
    args: [{
      selector: "cds-dialog, ibm-dialog",
      template: ""
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i2.ElementService
    }, {
      type: i2.AnimationFrameService,
      decorators: [{
        type: Optional
      }]
    }];
  }, {
    close: [{
      type: Output
    }],
    dialogConfig: [{
      type: Input
    }],
    dialog: [{
      type: ViewChild,
      args: ["dialog"]
    }],
    escapeClose: [{
      type: HostListener,
      args: ["keydown", ["$event"]]
    }],
    clickClose: [{
      type: HostListener,
      args: ["document:click", ["$event"]]
    }]
  });
})();

/**
 * A generic directive that can be inherited from to create dialogs (for example, a tooltip or popover)
 *
 * This class contains the relevant initialization code, specific templates, options, and additional inputs
 * should be specified in the derived class.
 *
 * NOTE: All child classes should add `DialogService` as a provider, otherwise they will lose context that
 * the service relies on.
 */
class DialogDirective {
  /**
   * Creates an instance of DialogDirective.
   * @param elementRef
   * @param viewContainerRef
   * @param dialogService
   * @param eventService
   */
  constructor(elementRef, viewContainerRef, dialogService, eventService) {
    this.elementRef = elementRef;
    this.viewContainerRef = viewContainerRef;
    this.dialogService = dialogService;
    this.eventService = eventService;
    /**
     * Title for the dialog
     */
    this.title = "";
    /**
     * Defines how the Dialog is triggered.(Hover and click behave the same on mobile - both respond to a single tap).
     * Do not add focusable elements if trigger is `hover` or `mouseenter`.
     */
    this.trigger = "click";
    /**
     * Defines how the Dialog close event is triggered.
     *
     * [See here](https://developer.mozilla.org/en-US/docs/Web/API/Element/mouseleave_event)
     * for more on the difference between `mouseleave` and `mouseout`.
     *
     * Defaults to `click` when `trigger` is set to `click`.
     */
    this.closeTrigger = "mouseleave";
    /**
     * Placement of the dialog, usually relative to the element the directive is on.
     */
    this.placement = "left";
    /**
     * Spacing between the dialog and it's triggering element
     */
    this.gap = 0;
    /**
     * Set to `true` to open the dialog next to the triggering component
     */
    this.appendInline = false;
    /**
     * Optional data for templates
     */
    this.data = {};
    this.isOpen = false;
    /**
     * This prevents the dialog from being toggled
     */
    this.disabled = false;
    /**
     * Emits an event when the dialog is closed
     */
    this.onClose = new EventEmitter();
    /**
     * Emits an event when the dialog is opened
     */
    this.onOpen = new EventEmitter();
    /**
     * Emits an event when the state of `isOpen` changes. Allows `isOpen` to be double bound
     */
    this.isOpenChange = new EventEmitter();
    this.role = "button";
    this.hasPopup = true;
  }
  /**
   * @deprecated as of v5, use `cdsDialog` instead
   * Dialog body content.
   */
  set ibmDialog(body) {
    this.cdsDialog = body;
  }
  get ariaOwns() {
    return this.isOpen ? this.dialogConfig.compID : null;
  }
  ngOnChanges(changes) {
    // set the config object (this can [and should!] be added to in child classes depending on what they need)
    this.dialogConfig = {
      title: this.title,
      content: this.cdsDialog,
      placement: this.placement,
      parentRef: this.elementRef,
      gap: this.gap,
      trigger: this.trigger,
      closeTrigger: this.closeTrigger,
      shouldClose: this.shouldClose || (() => true),
      appendInline: this.appendInline,
      wrapperClass: this.wrapperClass,
      data: this.data,
      offset: this.offset,
      disabled: this.disabled
    };
    if (changes.isOpen) {
      if (changes.isOpen.currentValue) {
        this.open();
      } else if (!changes.isOpen.firstChange) {
        this.close({
          reason: CloseReasons.programmatic
        });
      }
    }
    // Run any code a child class may need.
    this.onDialogChanges(changes);
    this.updateConfig();
  }
  /**
   * Sets the config object and binds events for hovering or clicking before
   * running code from child class.
   */
  ngOnInit() {
    // fix for safari hijacking clicks
    this.dialogService.singletonClickListen();
    const element = this.elementRef.nativeElement;
    this.eventService.on(element, "keydown", event => {
      if (event.target === this.dialogConfig.parentRef.nativeElement && (event.key === "Tab" || event.key === "Tab" && event.shiftKey) || event.key === "Escape") {
        this.close({
          reason: CloseReasons.interaction,
          target: event.target
        });
      }
    });
    // bind events for hovering or clicking the host
    if (this.trigger === "hover" || this.trigger === "mouseenter") {
      this.eventService.on(element, "mouseenter", this.open.bind(this));
      this.eventService.on(element, this.closeTrigger, event => {
        this.close({
          reason: CloseReasons.interaction,
          target: event.target
        });
      });
      this.eventService.on(element, "focus", this.open.bind(this));
      this.eventService.on(element, "blur", event => {
        this.close({
          reason: CloseReasons.interaction,
          target: event.target
        });
      });
    } else {
      this.eventService.on(element, "click", event => {
        this.toggle({
          reason: CloseReasons.interaction,
          target: event.target
        });
      });
      this.eventService.on(element, "keydown", event => {
        if (event.key === "Enter" || event.key === " ") {
          setTimeout(() => {
            this.open();
          });
        }
      });
    }
    DialogDirective.dialogCounter++;
    this.dialogConfig.compID = "dialog-" + DialogDirective.dialogCounter;
    // run any code a child class may need
    this.onDialogInit();
    this.updateConfig();
  }
  /**
   * When the host dies, kill the popover.
   * - Useful for use in a modal or similar.
   */
  ngOnDestroy() {
    this.close({
      reason: CloseReasons.destroyed
    });
  }
  /**
   * Helper method to call dialogService 'open'.
   * - Enforce accessibility by updating an aria attr for nativeElement.
   */
  open(component) {
    // don't allow dialogs to be opened if they're already open
    if (this.dialogRef || this.disabled) {
      return;
    }
    // actually open the dialog, emit events, and set the open state
    this.dialogRef = this.dialogService.open(this.viewContainerRef, this.dialogConfig, component);
    this.isOpen = true;
    this.onOpen.emit();
    this.isOpenChange.emit(true);
    // Handles emitting all the close events to clean everything up
    // Also enforce accessibility on close by updating an aria attr on the nativeElement.
    this.dialogRef.instance.close.subscribe(meta => {
      if (!this.dialogRef) {
        return;
      }
      if (this.dialogConfig.shouldClose && this.dialogConfig.shouldClose(meta)) {
        // close the dialog, emit events, and clear out the open states
        this.dialogService.close(this.dialogRef);
        this.dialogRef = null;
        this.isOpen = false;
        this.onClose.emit();
        this.isOpenChange.emit(false);
      }
    });
    return this.dialogRef;
  }
  /**
   * Helper method to toggle the open state of the dialog
   */
  toggle(meta = {
    reason: CloseReasons.interaction
  }) {
    if (!this.isOpen) {
      this.open();
    } else {
      this.close(meta);
    }
  }
  /**
   * Helper method to close the dialogRef.
   */
  close(meta = {
    reason: CloseReasons.interaction
  }) {
    if (this.dialogRef) {
      this.dialogRef.instance.doClose(meta);
    }
  }
  /**
   * Empty method for child classes to override and specify additional init steps.
   * Run after DialogDirective completes it's ngOnInit.
   */
  onDialogInit() {}
  /**
   * Empty method for child to override and specify additional on changes steps.
   * run after DialogDirective completes it's ngOnChanges.
   */
  onDialogChanges(_changes) {}
  updateConfig() {}
}
DialogDirective.dialogCounter = 0;
DialogDirective.ɵfac = function DialogDirective_Factory(t) {
  return new (t || DialogDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(DialogService), i0.ɵɵdirectiveInject(i2.EventService));
};
DialogDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: DialogDirective,
  selectors: [["", "cdsDialog", ""], ["", "ibmDialog", ""]],
  hostVars: 4,
  hostBindings: function DialogDirective_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("aria-expanded", ctx.isOpen)("role", ctx.role)("aria-haspopup", ctx.hasPopup)("aria-owns", ctx.ariaOwns);
    }
  },
  inputs: {
    title: "title",
    ibmDialog: "ibmDialog",
    cdsDialog: "cdsDialog",
    trigger: "trigger",
    closeTrigger: "closeTrigger",
    placement: "placement",
    offset: "offset",
    wrapperClass: "wrapperClass",
    gap: "gap",
    appendInline: "appendInline",
    data: "data",
    isOpen: "isOpen",
    disabled: "disabled",
    shouldClose: "shouldClose"
  },
  outputs: {
    onClose: "onClose",
    onOpen: "onOpen",
    isOpenChange: "isOpenChange"
  },
  exportAs: ["dialog"],
  features: [i0.ɵɵProvidersFeature([DialogService]), i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DialogDirective, [{
    type: Directive,
    args: [{
      selector: "[cdsDialog], [ibmDialog]",
      exportAs: "dialog",
      providers: [DialogService]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.ViewContainerRef
    }, {
      type: DialogService
    }, {
      type: i2.EventService
    }];
  }, {
    title: [{
      type: Input
    }],
    ibmDialog: [{
      type: Input
    }],
    cdsDialog: [{
      type: Input
    }],
    trigger: [{
      type: Input
    }],
    closeTrigger: [{
      type: Input
    }],
    placement: [{
      type: Input
    }],
    offset: [{
      type: Input
    }],
    wrapperClass: [{
      type: Input
    }],
    gap: [{
      type: Input
    }],
    appendInline: [{
      type: Input
    }],
    data: [{
      type: Input
    }],
    isOpen: [{
      type: Input
    }, {
      type: HostBinding,
      args: ["attr.aria-expanded"]
    }],
    disabled: [{
      type: Input
    }],
    shouldClose: [{
      type: Input
    }],
    onClose: [{
      type: Output
    }],
    onOpen: [{
      type: Output
    }],
    isOpenChange: [{
      type: Output
    }],
    role: [{
      type: HostBinding,
      args: ["attr.role"]
    }],
    hasPopup: [{
      type: HostBinding,
      args: ["attr.aria-haspopup"]
    }],
    ariaOwns: [{
      type: HostBinding,
      args: ["attr.aria-owns"]
    }]
  });
})();

/**
 * Extend the `Dialog` component to create an overflow menu.
 *
 * Not used directly. See overflow-menu.component and overflow-menu.directive for more
 */
class OverflowMenuPane extends Dialog {
  constructor(elementRef, i18n, experimental, animationFrameService = null,
  // mark `elementService` as optional since making it mandatory would be a breaking change
  elementService = null) {
    super(elementRef, elementService, animationFrameService);
    this.elementRef = elementRef;
    this.i18n = i18n;
    this.experimental = experimental;
    this.animationFrameService = animationFrameService;
    this.elementService = elementService;
  }
  onDialogInit() {
    const positionOverflowMenu = pos => {
      let offset;
      /*
      * 20 is half the width of the overflow menu trigger element.
      * we also move the element by half of it's own width, since
      * position service will try and center everything
      */
      const closestRel = closestAttr("position", ["relative", "fixed", "absolute"], this.elementRef.nativeElement);
      const topFix = closestRel ? closestRel.getBoundingClientRect().top * -1 : 0;
      const leftFix = closestRel ? closestRel.getBoundingClientRect().left * -1 : 0;
      offset = Math.round(this.dialog.nativeElement.offsetWidth / 2) - 20;
      if (this.dialogConfig.flip) {
        return position.addOffset(pos, topFix, -offset + leftFix);
      }
      return position.addOffset(pos, topFix, offset + leftFix);
    };
    this.addGap["bottom"] = positionOverflowMenu;
    this.addGap["top"] = positionOverflowMenu;
    if (!this.dialogConfig.menuLabel) {
      this.dialogConfig.menuLabel = this.i18n.get().OVERFLOW_MENU.OVERFLOW;
    }
  }
  hostkeys(event) {
    const listItems = this.listItems();
    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        if (!isFocusInLastItem(event, listItems)) {
          const index = listItems.findIndex(item => item === event.target);
          listItems[index + 1].focus();
        } else {
          listItems[0].focus();
        }
        break;
      case "ArrowUp":
        event.preventDefault();
        if (!isFocusInFirstItem(event, listItems)) {
          const index = listItems.findIndex(item => item === event.target);
          listItems[index - 1].focus();
        } else {
          listItems[listItems.length - 1].focus();
        }
        break;
      case "Home":
        event.preventDefault();
        listItems[0].focus();
        break;
      case "End":
        event.preventDefault();
        listItems[listItems.length - 1].focus();
        break;
      case "Escape":
      case "Tab":
        event.stopImmediatePropagation();
        this.doClose({
          reason: CloseReasons.interaction,
          target: event.target
        });
        break;
      default:
        break;
    }
  }
  onClose(event) {
    this.doClose({
      reason: CloseReasons.interaction,
      target: event.target
    });
  }
  afterDialogViewInit() {
    const focusElementList = this.listItems();
    focusElementList.forEach(button => {
      // Allows user to set tabindex to 0.
      if (button.getAttribute("tabindex") === null) {
        button.tabIndex = -1;
      }
    });
    if (focusElementList[0]) {
      focusElementList[0].tabIndex = 0;
      focusElementList[0].focus();
    }
  }
  listItems() {
    const selector = ".cds--overflow-menu-options__option:not([disabled]) .cds--overflow-menu-options__btn";
    return Array.from(this.elementRef.nativeElement.querySelectorAll(selector));
  }
}
OverflowMenuPane.ɵfac = function OverflowMenuPane_Factory(t) {
  return new (t || OverflowMenuPane)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1$1.I18n), i0.ɵɵdirectiveInject(i2$1.ExperimentalService), i0.ɵɵdirectiveInject(i2.AnimationFrameService, 8), i0.ɵɵdirectiveInject(i2.ElementService, 8));
};
OverflowMenuPane.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OverflowMenuPane,
  selectors: [["cds-overflow-menu-pane"], ["ibm-overflow-menu-pane"]],
  hostBindings: function OverflowMenuPane_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("keydown", function OverflowMenuPane_keydown_HostBindingHandler($event) {
        return ctx.hostkeys($event);
      });
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 11,
  consts: [["role", "menu", 1, "cds--overflow-menu-options", "cds--overflow-menu-options--open", 3, "ngClass", "click"], ["dialog", ""], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
  template: function OverflowMenuPane_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "ul", 0, 1);
      i0.ɵɵlistener("click", function OverflowMenuPane_Template_ul_click_0_listener($event) {
        return ctx.onClose($event);
      });
      i0.ɵɵtemplate(2, OverflowMenuPane_ng_template_2_Template, 0, 0, "ng-template", 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(7, _c1, ctx.dialogConfig.flip));
      i0.ɵɵattribute("id", ctx.dialogConfig.compID)("aria-label", ctx.dialogConfig.menuLabel)("data-floating-menu-direction", ctx.placement ? ctx.placement : null)("aria-label", ctx.dialogConfig.menuLabel);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngTemplateOutlet", ctx.dialogConfig.content)("ngTemplateOutletContext", i0.ɵɵpureFunction1(9, _c2, ctx));
    }
  },
  dependencies: [i2$2.NgClass, i2$2.NgTemplateOutlet],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OverflowMenuPane, [{
    type: Component,
    args: [{
      selector: "cds-overflow-menu-pane, ibm-overflow-menu-pane",
      template: `
		<ul
			[attr.id]="dialogConfig.compID"
			[attr.aria-label]="dialogConfig.menuLabel"
			[attr.data-floating-menu-direction]="placement ? placement : null"
			[ngClass]="{'cds--overflow-menu--flip': dialogConfig.flip}"
			role="menu"
			#dialog
			class="cds--overflow-menu-options cds--overflow-menu-options--open"
			(click)="onClose($event)"
			[attr.aria-label]="dialogConfig.menuLabel">
			<ng-template
				[ngTemplateOutlet]="dialogConfig.content"
				[ngTemplateOutletContext]="{overflowMenu: this}">
			</ng-template>
		</ul>
	`
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i1$1.I18n
    }, {
      type: i2$1.ExperimentalService
    }, {
      type: i2.AnimationFrameService,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i2.ElementService,
      decorators: [{
        type: Optional
      }]
    }];
  }, {
    hostkeys: [{
      type: HostListener,
      args: ["keydown", ["$event"]]
    }]
  });
})();

/**
 * @deprecated as of v5
 * Use Toggletip or Popover components instead
 */
class OverflowMenuCustomPane extends Dialog {
  constructor(elementRef, i18n, animationFrameService = null,
  // mark `elementService` as optional since making it mandatory would be a breaking change
  elementService = null) {
    super(elementRef, elementService, animationFrameService);
    this.elementRef = elementRef;
    this.i18n = i18n;
    this.animationFrameService = animationFrameService;
    this.elementService = elementService;
  }
  onClick(event) {
    this.doClose({
      reason: CloseReasons.interaction,
      target: event.target
    });
  }
  onDialogInit() {
    const positionOverflowMenu = pos => {
      let offset;
      /*
      * 20 is half the width of the overflow menu trigger element.
      * we also move the element by half of it's own width, since
      * position service will try and center everything
      */
      const closestRel = closestAttr("position", ["relative", "fixed", "absolute"], this.elementRef.nativeElement);
      const topFix = closestRel ? closestRel.getBoundingClientRect().top * -1 : 0;
      const leftFix = closestRel ? closestRel.getBoundingClientRect().left * -1 : 0;
      offset = Math.round(this.dialog.nativeElement.offsetWidth / 2) - 20;
      if (this.dialogConfig.flip) {
        return position.addOffset(pos, topFix, -offset + leftFix);
      }
      return position.addOffset(pos, topFix, offset + leftFix);
    };
    this.addGap["bottom"] = positionOverflowMenu;
    this.addGap["top"] = positionOverflowMenu;
    if (!this.dialogConfig.menuLabel) {
      this.dialogConfig.menuLabel = this.i18n.get().OVERFLOW_MENU.OVERFLOW;
    }
  }
}
OverflowMenuCustomPane.ɵfac = function OverflowMenuCustomPane_Factory(t) {
  return new (t || OverflowMenuCustomPane)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1$1.I18n), i0.ɵɵdirectiveInject(i2.AnimationFrameService, 8), i0.ɵɵdirectiveInject(i2.ElementService, 8));
};
OverflowMenuCustomPane.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OverflowMenuCustomPane,
  selectors: [["cds-overflow-custom-menu-pane"], ["ibm-overflow-custom-menu-pane"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 11,
  consts: [["role", "menu", 1, "cds--overflow-menu-options", "cds--overflow-menu-options--open", 3, "ngClass", "click"], ["dialog", ""], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
  template: function OverflowMenuCustomPane_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0, 1);
      i0.ɵɵlistener("click", function OverflowMenuCustomPane_Template_div_click_0_listener($event) {
        return ctx.onClick($event);
      });
      i0.ɵɵtemplate(2, OverflowMenuCustomPane_ng_template_2_Template, 0, 0, "ng-template", 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(7, _c1, ctx.dialogConfig.flip));
      i0.ɵɵattribute("id", ctx.dialogConfig.compID)("aria-label", ctx.dialogConfig.menuLabel)("data-floating-menu-direction", ctx.placement ? ctx.placement : null)("aria-label", ctx.dialogConfig.menuLabel);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngTemplateOutlet", ctx.dialogConfig.content)("ngTemplateOutletContext", i0.ɵɵpureFunction1(9, _c2, ctx));
    }
  },
  dependencies: [i2$2.NgClass, i2$2.NgTemplateOutlet],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OverflowMenuCustomPane, [{
    type: Component,
    args: [{
      selector: "cds-overflow-custom-menu-pane, ibm-overflow-custom-menu-pane",
      template: `
		<div
			[attr.id]="dialogConfig.compID"
			[attr.aria-label]="dialogConfig.menuLabel"
			[attr.data-floating-menu-direction]="placement ? placement : null"
			[ngClass]="{'cds--overflow-menu--flip': dialogConfig.flip}"
			class="cds--overflow-menu-options cds--overflow-menu-options--open"
			role="menu"
			(click)="onClick($event)"
			#dialog
			[attr.aria-label]="dialogConfig.menuLabel">
			<ng-template
				[ngTemplateOutlet]="dialogConfig.content"
				[ngTemplateOutletContext]="{overflowMenu: this}">
			</ng-template>
		</div>
	`
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i1$1.I18n
    }, {
      type: i2.AnimationFrameService,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i2.ElementService,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Directive for extending `Dialog` to create overflow menus.
 *
 * class: OverflowMenuDirective (extends DialogDirective)
 *
 *
 * selector: `cdsOverflowMenu`
 *
 *
 * ```html
 * <div [cdsOverflowMenu]="templateRef"></div>
 * <ng-template #templateRef>
 * 	<!-- overflow menu options here -->
 * </ng-template>
 * ```
 *
 * ```html
 * <div [cdsOverflowMenu]="templateRef" [customPane]="true"></div>
 * <ng-template #templateRef>
 *  <!-- custom content goes here -->
 * </ng-template>
 * ```
 */
class OverflowMenuDirective extends DialogDirective {
  /**
   * Creates an instance of `OverflowMenuDirective`.
   */
  constructor(elementRef, viewContainerRef, dialogService, eventService) {
    super(elementRef, viewContainerRef, dialogService, eventService);
    this.elementRef = elementRef;
    this.viewContainerRef = viewContainerRef;
    this.dialogService = dialogService;
    this.eventService = eventService;
    /**
     * Controls wether the overflow menu is flipped
     */
    this.flip = false;
    /**
     * Classes to add to the dialog container
     */
    this.wrapperClass = "";
    /**
     * Set to true to for custom content
     */
    this.customPane = false;
  }
  /**
   * @deprecated as of v5
   * Takes a template ref of `OverflowMenuOptions`s
   */
  set ibmOverflowMenu(template) {
    this.cdsOverflowMenu = template;
  }
  updateConfig() {
    this.dialogConfig.content = this.cdsOverflowMenu;
    this.dialogConfig.flip = this.flip;
    this.dialogConfig.offset = this.offset;
    this.dialogConfig.wrapperClass = this.wrapperClass;
  }
  hostkeys(event) {
    switch (event.key) {
      case "Enter":
      case " ":
        event.preventDefault();
        break;
    }
  }
  open() {
    return super.open(this.customPane ? OverflowMenuCustomPane : OverflowMenuPane);
  }
}
OverflowMenuDirective.ɵfac = function OverflowMenuDirective_Factory(t) {
  return new (t || OverflowMenuDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(DialogService), i0.ɵɵdirectiveInject(i2.EventService));
};
OverflowMenuDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OverflowMenuDirective,
  selectors: [["", "cdsOverflowMenu", ""], ["", "ibmOverflowMenu", ""]],
  hostBindings: function OverflowMenuDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("keydown", function OverflowMenuDirective_keydown_HostBindingHandler($event) {
        return ctx.hostkeys($event);
      });
    }
  },
  inputs: {
    ibmOverflowMenu: "ibmOverflowMenu",
    cdsOverflowMenu: "cdsOverflowMenu",
    flip: "flip",
    offset: "offset",
    wrapperClass: "wrapperClass",
    customPane: "customPane"
  },
  exportAs: ["overflowMenu"],
  features: [i0.ɵɵProvidersFeature([DialogService]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OverflowMenuDirective, [{
    type: Directive,
    args: [{
      selector: "[cdsOverflowMenu], [ibmOverflowMenu]",
      exportAs: "overflowMenu",
      providers: [DialogService]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.ViewContainerRef
    }, {
      type: DialogService
    }, {
      type: i2.EventService
    }];
  }, {
    ibmOverflowMenu: [{
      type: Input
    }],
    cdsOverflowMenu: [{
      type: Input
    }],
    flip: [{
      type: Input
    }],
    offset: [{
      type: Input
    }],
    wrapperClass: [{
      type: Input
    }],
    customPane: [{
      type: Input
    }],
    hostkeys: [{
      type: HostListener,
      args: ["keydown", ["$event"]]
    }]
  });
})();

/**
 * The OverFlow menu component encapsulates the OverFlowMenu directive, and the menu iconography into one convienent component.
 *
 * Get started with importing the module:
 *
 * ```typescript
 * import { DialogModule } from 'carbon-components-angular';
 * ```
 *
 * ```html
 * <cds-overflow-menu>
 *	<cds-overflow-menu-option>Option 1</cds-overflow-menu-option>
 *	<cds-overflow-menu-option>Option 2</cds-overflow-menu-option>
 * </cds-overflow-menu>
 * ```
 *
 * [See demo](../../?path=/story/components-overflow-menu--basic)
 */
class OverflowMenu {
  constructor(elementRef, i18n) {
    this.elementRef = elementRef;
    this.i18n = i18n;
    this.buttonLabel = this.i18n.get().OVERFLOW_MENU.OVERFLOW;
    this.flip = false;
    this.placement = "bottom";
    this.open = false;
    this.openChange = new EventEmitter();
    this.wrapperClass = "";
    /**
     * This appends additional classes to the overflow trigger/button.
     */
    this.triggerClass = "";
  }
  handleOpenChange(event) {
    this.open = event;
    this.openChange.emit(event);
  }
}
OverflowMenu.ɵfac = function OverflowMenu_Factory(t) {
  return new (t || OverflowMenu)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1$1.I18n));
};
OverflowMenu.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OverflowMenu,
  selectors: [["cds-overflow-menu"], ["ibm-overflow-menu"]],
  contentQueries: function OverflowMenu_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, OverflowMenuDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.overflowMenuDirective = _t.first);
    }
  },
  inputs: {
    buttonLabel: "buttonLabel",
    flip: "flip",
    placement: "placement",
    open: "open",
    customTrigger: "customTrigger",
    offset: "offset",
    wrapperClass: "wrapperClass",
    triggerClass: "triggerClass"
  },
  outputs: {
    openChange: "openChange"
  },
  ngContentSelectors: _c4,
  decls: 6,
  vars: 15,
  consts: [["aria-haspopup", "true", "type", "button", 1, "cds--overflow-menu", 3, "cdsOverflowMenu", "ngClass", "flip", "isOpen", "offset", "wrapperClass", "placement", "isOpenChange"], [4, "ngIf", "ngIfElse"], ["options", ""], ["defaultIcon", ""], [3, "ngTemplateOutlet"], ["cdsIcon", "overflow-menu--vertical", "size", "16", 1, "cds--overflow-menu__icon"]],
  template: function OverflowMenu_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "button", 0);
      i0.ɵɵlistener("isOpenChange", function OverflowMenu_Template_button_isOpenChange_0_listener($event) {
        return ctx.handleOpenChange($event);
      });
      i0.ɵɵtemplate(1, OverflowMenu_1_Template, 1, 1, null, 1);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(2, OverflowMenu_ng_template_2_Template, 1, 0, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor)(4, OverflowMenu_ng_template_4_Template, 1, 0, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      const _r2 = i0.ɵɵreference(3);
      const _r4 = i0.ɵɵreference(5);
      i0.ɵɵclassMapInterpolate1("cds--overflow-menu ", ctx.triggerClass, "");
      i0.ɵɵproperty("cdsOverflowMenu", _r2)("ngClass", i0.ɵɵpureFunction1(13, _c3, ctx.open))("flip", ctx.flip)("isOpen", ctx.open)("offset", ctx.offset)("wrapperClass", ctx.wrapperClass)("placement", ctx.placement);
      i0.ɵɵattribute("aria-label", ctx.buttonLabel);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.customTrigger)("ngIfElse", _r4);
    }
  },
  dependencies: [i2$2.NgClass, i2$2.NgIf, i2$2.NgTemplateOutlet, i3.IconDirective, OverflowMenuDirective],
  styles: [".cds--overflow-menu--open{opacity:1}.cds--data-table-v2 .cds--overflow-menu{transform:rotate(90deg)}.cds--data-table-v2 .cds--overflow-menu__icon{transform:rotate(180deg)}\n"],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OverflowMenu, [{
    type: Component,
    args: [{
      selector: "cds-overflow-menu, ibm-overflow-menu",
      template: `
		<button
			[cdsOverflowMenu]="options"
			[ngClass]="{'cds--overflow-menu--open': open}"
			class="cds--overflow-menu {{triggerClass}}"
			[attr.aria-label]="buttonLabel"
			[flip]="flip"
			[isOpen]="open"
			(isOpenChange)="handleOpenChange($event)"
			[offset]="offset"
			[wrapperClass]="wrapperClass"
			aria-haspopup="true"
			class="cds--overflow-menu"
			type="button"
			[placement]="placement">
			<ng-template *ngIf="customTrigger; else defaultIcon" [ngTemplateOutlet]="customTrigger"></ng-template>
		</button>
		<ng-template #options>
			<ng-content></ng-content>
		</ng-template>
		<ng-template #defaultIcon>
			<svg cdsIcon="overflow-menu--vertical" size="16" class="cds--overflow-menu__icon"></svg>
		</ng-template>
	`,
      encapsulation: ViewEncapsulation.None,
      styles: [".cds--overflow-menu--open{opacity:1}.cds--data-table-v2 .cds--overflow-menu{transform:rotate(90deg)}.cds--data-table-v2 .cds--overflow-menu__icon{transform:rotate(180deg)}\n"]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i1$1.I18n
    }];
  }, {
    buttonLabel: [{
      type: Input
    }],
    flip: [{
      type: Input
    }],
    placement: [{
      type: Input
    }],
    open: [{
      type: Input
    }],
    openChange: [{
      type: Output
    }],
    customTrigger: [{
      type: Input
    }],
    offset: [{
      type: Input
    }],
    wrapperClass: [{
      type: Input
    }],
    triggerClass: [{
      type: Input
    }],
    overflowMenuDirective: [{
      type: ContentChild,
      args: [OverflowMenuDirective]
    }]
  });
})();

/**
 * Available HTML anchor targets
 */
var Target;
(function (Target) {
  Target["self"] = "_self";
  Target["blank"] = "_blank";
  Target["parent"] = "_parent";
  Target["top"] = "_top";
})(Target || (Target = {}));
/**
 * Security HTML anchor rel when target is set
 */
const REL = "noreferrer noopener";
/**
 * `OverflowMenuOption` represents a single option in an overflow menu
 *
 * Presently it has three possible states - normal, disabled, and danger:
 * ```
 * <cds-overflow-menu-option>Simple option</cds-overflow-menu-option>
 * <cds-overflow-menu-option disabled="true">Disabled</cds-overflow-menu-option>
 * <cds-overflow-menu-option type="danger">Danger option</cds-overflow-menu-option>
 * ```
 *
 * For content that expands beyond the overflow menu `OverflowMenuOption` automatically adds a title attribute.
 */
class OverflowMenuOption {
  constructor(elementRef) {
    this.elementRef = elementRef;
    this.optionClass = true;
    this.role = "presentation";
    /**
     * Set to `true` to display a dividing line above this option
     */
    this.divider = false;
    /**
     * toggles between `normal` and `danger` states
     */
    this.type = "normal";
    /**
     * disable/enable interactions
     */
    this.disabled = false;
    /**
     * Apply a custom class to the inner button/anchor
     */
    this.innerClass = "";
    this.selected = new EventEmitter();
    this.tabIndex = -1;
    // note: title must be a real attribute (i.e. not a getter) as of Angular@6 due to
    // change after checked errors
    this.title = null;
  }
  get isDanger() {
    return this.type === "danger";
  }
  get isDisabled() {
    return this.disabled;
  }
  /**
   * Allows to add a target to the anchor
   */
  set target(value) {
    if (!Object.values(Target).includes(value)) {
      console.warn(`\`target\` must have one of the following values: ${Object.values(Target).join(", ")}.
Please use the \`Target\` enum exported by carbon-components-angular`);
      return;
    }
    this._target = value;
  }
  get target() {
    return this._target;
  }
  /**
   * rel only returns its value if target is defined
   */
  get rel() {
    return this._target ? REL : null;
  }
  onClick() {
    this.selected.emit();
  }
  onFocus() {
    setTimeout(() => this.tabIndex = 0);
  }
  onBlur() {
    setTimeout(() => this.tabIndex = -1);
  }
  ngAfterViewInit() {
    const button = this.elementRef.nativeElement.querySelector("button, a");
    const textContainer = button.querySelector(".cds--overflow-menu-options__option-content");
    if (textContainer.scrollWidth > textContainer.offsetWidth) {
      this.title = button.textContent;
    }
  }
}
OverflowMenuOption.ɵfac = function OverflowMenuOption_Factory(t) {
  return new (t || OverflowMenuOption)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
OverflowMenuOption.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OverflowMenuOption,
  selectors: [["cds-overflow-menu-option"], ["ibm-overflow-menu-option"]],
  hostVars: 9,
  hostBindings: function OverflowMenuOption_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("role", ctx.role);
      i0.ɵɵclassProp("cds--overflow-menu-options__option", ctx.optionClass)("cds--overflow-menu-options__option--danger", ctx.isDanger)("cds--overflow-menu-options__option--disabled", ctx.isDisabled)("cds--overflow-menu--divider", ctx.divider);
    }
  },
  inputs: {
    divider: "divider",
    type: "type",
    disabled: "disabled",
    href: "href",
    target: "target",
    innerClass: "innerClass"
  },
  outputs: {
    selected: "selected"
  },
  ngContentSelectors: _c4,
  decls: 4,
  vars: 2,
  consts: [["role", "menuitem", 3, "class", "tabindex", "disabled", "focus", "blur", "click", 4, "ngIf"], ["role", "menuitem", 3, "class", "tabindex", "href", "focus", "blur", "click", 4, "ngIf"], ["tempOutlet", ""], ["role", "menuitem", 3, "tabindex", "disabled", "focus", "blur", "click"], [4, "ngTemplateOutlet"], ["role", "menuitem", 3, "tabindex", "href", "focus", "blur", "click"], [1, "cds--overflow-menu-options__option-content"]],
  template: function OverflowMenuOption_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵtemplate(0, OverflowMenuOption_button_0_Template, 2, 7, "button", 0)(1, OverflowMenuOption_a_1_Template, 2, 10, "a", 1)(2, OverflowMenuOption_ng_template_2_Template, 2, 0, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", !ctx.href);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.href);
    }
  },
  dependencies: [i2$2.NgIf, i2$2.NgTemplateOutlet],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OverflowMenuOption, [{
    type: Component,
    args: [{
      selector: "cds-overflow-menu-option, ibm-overflow-menu-option",
      template: `
		<button
			*ngIf="!href"
			class="cds--overflow-menu-options__btn {{innerClass}}"
			role="menuitem"
			[tabindex]="tabIndex"
			(focus)="onFocus()"
			(blur)="onBlur()"
			(click)="onClick()"
			[disabled]="disabled"
			[attr.title]="title">
			<ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
		</button>

		<a
			*ngIf="href"
			class="cds--overflow-menu-options__btn {{innerClass}}"
			role="menuitem"
			[tabindex]="tabIndex"
			(focus)="onFocus()"
			(blur)="onBlur()"
			(click)="onClick()"
			[attr.disabled]="disabled"
			[href]="href"
			[attr.target]="target"
			[attr.rel]="rel"
			[attr.title]="title">
			<ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
		</a>

		<ng-template #tempOutlet>
			<div class="cds--overflow-menu-options__option-content">
				<ng-content></ng-content>
			</div>
		</ng-template>
	`
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    optionClass: [{
      type: HostBinding,
      args: ["class.cds--overflow-menu-options__option"]
    }],
    role: [{
      type: HostBinding,
      args: ["attr.role"]
    }],
    isDanger: [{
      type: HostBinding,
      args: ["class.cds--overflow-menu-options__option--danger"]
    }],
    isDisabled: [{
      type: HostBinding,
      args: ["class.cds--overflow-menu-options__option--disabled"]
    }],
    divider: [{
      type: HostBinding,
      args: ["class.cds--overflow-menu--divider"]
    }, {
      type: Input
    }],
    type: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    href: [{
      type: Input
    }],
    target: [{
      type: Input
    }],
    innerClass: [{
      type: Input
    }],
    selected: [{
      type: Output
    }]
  });
})();

// modules
class DialogModule {}
DialogModule.ɵfac = function DialogModule_Factory(t) {
  return new (t || DialogModule)();
};
DialogModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DialogModule
});
DialogModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [DialogService],
  imports: [CommonModule, I18nModule, PlaceholderModule, ExperimentalModule, UtilsModule, IconModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DialogModule, [{
    type: NgModule,
    args: [{
      declarations: [Dialog, OverflowMenu, OverflowMenuPane, OverflowMenuCustomPane, DialogDirective, OverflowMenuDirective, OverflowMenuOption],
      exports: [Dialog, OverflowMenu, OverflowMenuPane, OverflowMenuCustomPane, DialogDirective, OverflowMenuDirective, OverflowMenuOption],
      providers: [DialogService],
      imports: [CommonModule, I18nModule, PlaceholderModule, ExperimentalModule, UtilsModule, IconModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CloseReasons, Dialog, DialogDirective, DialogModule, DialogService, OverflowMenu, OverflowMenuCustomPane, OverflowMenuDirective, OverflowMenuOption, OverflowMenuPane };
