import * as i0 from '@angular/core';
import { EventEmitter, TemplateRef, Component, Optional, Input, Output, HostBinding, HostListener, NgModule, ContentChildren, ViewEncapsulation } from '@angular/core';
import * as i2$1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i1$2 from 'carbon-components-angular/button';
import { BaseIconButton, ButtonModule } from 'carbon-components-angular/button';
import * as i1 from 'carbon-components-angular/i18n';
import { I18nModule } from 'carbon-components-angular/i18n';
import * as i3 from 'carbon-components-angular/icon';
import { IconModule } from 'carbon-components-angular/icon';
import * as i1$1 from '@angular/platform-browser';
import * as i2 from '@angular/router';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';

/**
 * A fixed header and navigation.
 * Header may contain a Hamburger menu to toggle the side navigation, navigation actions,
 * and global actions (generally in the form of `Panel`s).
 *
 * [See demo](../../?path=/story/components-ui-shell--header)
 */
function Header_a_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 4);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("href", ctx_r0.skipTo, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 2, ctx_r0.i18n.get("UI_SHELL.SKIP_TO")), " ");
  }
}
function Header_3_ng_template_0_Template(rf, ctx) {}
function Header_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Header_3_ng_template_0_Template, 0, 0, "ng-template", 5);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.brand);
  }
}
function Header_ng_container_4_a_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 9);
    i0.ɵɵlistener("click", function Header_ng_container_4_a_1_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r7);
      const ctx_r6 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r6.navigate($event));
    });
    i0.ɵɵelementStart(1, "span", 10);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("href", ctx_r4.href, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1("", ctx_r4.brand, "\xA0");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r4.name, " ");
  }
}
function Header_ng_container_4_a_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 11)(1, "span", 10);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("routerLink", ctx_r5.route);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1("", ctx_r5.brand, "\xA0");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r5.name, " ");
  }
}
function Header_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0, 6);
    i0.ɵɵtemplate(1, Header_ng_container_4_a_1_Template, 4, 3, "a", 7)(2, Header_ng_container_4_a_2_Template, 4, 3, "a", 8);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngSwitch", ctx_r2.useRouter);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", false);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", true);
  }
}
const _c0 = [[["cds-hamburger"], ["ibm-hamburger"]], "*"];
const _c1 = ["cds-hamburger,ibm-hamburger", "*"];
function HeaderItem_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
function HeaderItem_a_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c2 = a0 => ({
  "cds--header__menu-item--current": a0
});
function HeaderItem_a_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 4);
    i0.ɵɵlistener("click", function HeaderItem_a_3_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.navigate($event));
    });
    i0.ɵɵtemplate(1, HeaderItem_a_3_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    const _r1 = i0.ɵɵreference(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c2, ctx_r2.isCurrentPage))("href", ctx_r2.href, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", _r1);
  }
}
function HeaderItem_a_4_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c3 = () => ["cds--header__menu-item--current"];
function HeaderItem_a_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 6);
    i0.ɵɵtemplate(1, HeaderItem_a_4_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    const _r1 = i0.ɵɵreference(2);
    i0.ɵɵproperty("routerLinkActive", i0.ɵɵpureFunction0(5, _c3))("ngClass", i0.ɵɵpureFunction1(6, _c2, ctx_r3.isCurrentPage))("routerLink", ctx_r3.route)("routerLinkActive", ctx_r3.activeLinkClass);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", _r1);
  }
}
const _c4 = ["*"];
function HeaderMenu_2_ng_template_0_Template(rf, ctx) {}
function HeaderMenu_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, HeaderMenu_2_ng_template_0_Template, 0, 0, "ng-template", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.icon);
  }
}
function HeaderMenu_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(0, "svg", 6);
    i0.ɵɵelement(1, "path", 7);
    i0.ɵɵelementEnd();
  }
}
function HeaderMenu_ng_container_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "cds-header-item", 8);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const headerItem_r5 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("href", headerItem_r5.href)("route", headerItem_r5.route)("routeExtras", headerItem_r5.routeExtras);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", headerItem_r5.content, " ");
  }
}
function HeaderNavigation_ng_container_3_cds_header_item_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "cds-header-item", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const navigationItem_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("href", navigationItem_r1.href)("route", navigationItem_r1.route)("routeExtras", navigationItem_r1.routeExtras)("isCurrentPage", !!navigationItem_r1.isCurrentPage);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", navigationItem_r1.content, " ");
  }
}
function HeaderNavigation_ng_container_3_cds_header_menu_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "cds-header-menu", 6);
  }
  if (rf & 2) {
    const navigationItem_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("href", navigationItem_r1.href)("title", navigationItem_r1.title)("trigger", navigationItem_r1.trigger ? navigationItem_r1.trigger : "click")("headerItems", navigationItem_r1.menuItems);
  }
}
function HeaderNavigation_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, HeaderNavigation_ng_container_3_cds_header_item_1_Template, 2, 5, "cds-header-item", 3)(2, HeaderNavigation_ng_container_3_cds_header_menu_2_Template, 1, 4, "cds-header-menu", 4);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const navigationItem_r1 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", navigationItem_r1.type === "item");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", navigationItem_r1.type === "menu");
  }
}
const _c5 = a1 => ({
  "cds--header__action": true,
  "cds--header__action--active": a1
});
const _c6 = a0 => ({
  "aria-label": a0
});
function Hamburger__svg_svg_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 3);
  }
}
function Hamburger__svg_svg_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 4);
  }
}
const _c7 = a0 => ({
  "cds--header__action--active": a0
});
function SideNavItem_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵprojection(1, 1);
    i0.ɵɵelementEnd();
  }
}
const _c8 = ["*", [["svg"], ["", "icon", ""]]];
const _c9 = a0 => ({
  "cds--side-nav__item--active": a0
});
const _c10 = ["*", "svg, [icon]"];
function SideNavMenu_ng_container_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "cds-sidenav-item", 8);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const menuItem_r1 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("href", menuItem_r1.href)("route", menuItem_r1.route)("routeExtras", menuItem_r1.routeExtras)("isSubMenu", true);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", menuItem_r1.content, " ");
  }
}
const _c11 = [[["svg"], ["", "icon", ""]], "*"];
const _c12 = ["svg, [icon]", "*"];
function SideNav_ng_container_4_cds_sidenav_item_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "cds-sidenav-item", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const navigationItem_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("href", navigationItem_r2.href)("route", navigationItem_r2.route)("routeExtras", navigationItem_r2.routeExtras)("title", navigationItem_r2.title);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", navigationItem_r2.content, " ");
  }
}
function SideNav_ng_container_4_cds_sidenav_menu_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "cds-sidenav-menu", 9);
  }
  if (rf & 2) {
    const navigationItem_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("title", navigationItem_r2.title)("menuItems", navigationItem_r2.menuItems);
  }
}
function SideNav_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, SideNav_ng_container_4_cds_sidenav_item_1_Template, 2, 5, "cds-sidenav-item", 6)(2, SideNav_ng_container_4_cds_sidenav_menu_2_Template, 1, 2, "cds-sidenav-menu", 7);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const navigationItem_r2 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", navigationItem_r2.type === "item");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", navigationItem_r2.type === "menu");
  }
}
function SideNav_button_7__svg_svg_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(0, "svg", 14);
    i0.ɵɵelement(1, "path", 15);
    i0.ɵɵelementEnd();
  }
}
function SideNav_button_7__svg_svg_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(0, "svg", 14);
    i0.ɵɵelement(1, "path", 16);
    i0.ɵɵelementEnd();
  }
}
function SideNav_button_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 10);
    i0.ɵɵlistener("click", function SideNav_button_7_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r10);
      const ctx_r9 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r9.toggle());
    });
    i0.ɵɵpipe(1, "async");
    i0.ɵɵelementStart(2, "div", 11);
    i0.ɵɵtemplate(3, SideNav_button_7__svg_svg_3_Template, 2, 0, "svg", 12)(4, SideNav_button_7__svg_svg_4_Template, 2, 0, "svg", 12);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "span", 13);
    i0.ɵɵtext(6);
    i0.ɵɵpipe(7, "async");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(1, 4, ctx_r1.expanded ? ctx_r1.i18n.get("UI_SHELL.SIDE_NAV.TOGGLE_CLOSE") : ctx_r1.i18n.get("UI_SHELL.SIDE_NAV.TOGGLE_OPEN")));
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r1.expanded);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.expanded);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(7, 6, ctx_r1.expanded ? ctx_r1.i18n.get("UI_SHELL.SIDE_NAV.TOGGLE_CLOSE") : ctx_r1.i18n.get("UI_SHELL.SIDE_NAV.TOGGLE_OPEN")), " ");
  }
}
const _c13 = [[["cds-sidenav-header"], ["ibm-sidenav-header"]], "*"];
const _c14 = ["cds-sidenav-header,ibm-sidenav-header", "*"];
const _c15 = a0 => ({
  "cds--header-panel--expanded": a0
});
const _c16 = a0 => ({
  "cds--switcher__item-link--selected": a0
});
class Header {
  constructor(i18n, domSanitizer, router) {
    this.i18n = i18n;
    this.domSanitizer = domSanitizer;
    this.router = router;
    /**
     * Top level branding. Defaults to "IBM"
     */
    this.brand = "IBM";
    /**
     * Use the routerLink attribute on <a> tag for navigation instead of using event handlers
     */
    this.useRouter = false;
    /**
     * Emits the navigation status promise when the link is activated
     */
    this.navigation = new EventEmitter();
    this._href = "#";
  }
  /**
   * Optional link for the header
   */
  set href(v) {
    this._href = v;
  }
  get href() {
    return this.domSanitizer.bypassSecurityTrustUrl(this._href);
  }
  isTemplate(value) {
    return value instanceof TemplateRef;
  }
  navigate(event) {
    if (this.router && this.route) {
      event.preventDefault();
      const status = this.router.navigate(this.route, this.routeExtras);
      this.navigation.emit(status);
    } else if (this._href === "#") {
      event.preventDefault();
    }
  }
}
Header.ɵfac = function Header_Factory(t) {
  return new (t || Header)(i0.ɵɵdirectiveInject(i1.I18n), i0.ɵɵdirectiveInject(i1$1.DomSanitizer), i0.ɵɵdirectiveInject(i2.Router, 8));
};
Header.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Header,
  selectors: [["cds-header"], ["ibm-header"]],
  inputs: {
    skipTo: "skipTo",
    name: "name",
    brand: "brand",
    href: "href",
    route: "route",
    routeExtras: "routeExtras",
    useRouter: "useRouter"
  },
  outputs: {
    navigation: "navigation"
  },
  ngContentSelectors: _c1,
  decls: 6,
  vars: 4,
  consts: [[1, "cds--header"], ["class", "cds--skip-to-content", "tabindex", "0", 3, "href", 4, "ngIf"], [4, "ngIf"], [3, "ngSwitch", 4, "ngIf"], ["tabindex", "0", 1, "cds--skip-to-content", 3, "href"], [3, "ngTemplateOutlet"], [3, "ngSwitch"], ["class", "cds--header__name", 3, "href", "click", 4, "ngSwitchCase"], ["class", "cds--header__name", 3, "routerLink", 4, "ngSwitchCase"], [1, "cds--header__name", 3, "href", "click"], [1, "cds--header__name--prefix"], [1, "cds--header__name", 3, "routerLink"]],
  template: function Header_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c0);
      i0.ɵɵelementStart(0, "header", 0);
      i0.ɵɵtemplate(1, Header_a_1_Template, 3, 4, "a", 1);
      i0.ɵɵprojection(2);
      i0.ɵɵtemplate(3, Header_3_Template, 1, 1, null, 2)(4, Header_ng_container_4_Template, 3, 3, "ng-container", 3);
      i0.ɵɵprojection(5, 1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵattribute("aria-label", ctx.brand + " " + ctx.name);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.skipTo);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.isTemplate(ctx.brand));
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.isTemplate(ctx.brand));
    }
  },
  dependencies: [i2$1.NgIf, i2$1.NgTemplateOutlet, i2$1.NgSwitch, i2$1.NgSwitchCase, i2.RouterLinkWithHref, i2$1.AsyncPipe],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Header, [{
    type: Component,
    args: [{
      selector: "cds-header, ibm-header",
      template: `
		<header
			class="cds--header"
			[attr.aria-label]="brand + ' ' + name">
			<a
				*ngIf="skipTo"
				class="cds--skip-to-content"
				[href]="skipTo"
				tabindex="0">
				{{ i18n.get("UI_SHELL.SKIP_TO") | async }}
			</a>
			<ng-content select="cds-hamburger,ibm-hamburger"></ng-content>
			<ng-template
				*ngIf="isTemplate(brand)"
				[ngTemplateOutlet]="brand">
			</ng-template>
			<ng-container *ngIf="!isTemplate(brand)" [ngSwitch]="useRouter">
				<a
					*ngSwitchCase="false"
					class="cds--header__name"
					[href]="href"
					(click)="navigate($event)">
					<span class="cds--header__name--prefix">{{brand}}&nbsp;</span>
					{{name}}
				</a>
				<a
					*ngSwitchCase="true"
					class="cds--header__name"
					[routerLink]="route">
					<span class="cds--header__name--prefix">{{brand}}&nbsp;</span>
					{{name}}
				</a>
			</ng-container>
			<ng-content></ng-content>
		</header>
	`
    }]
  }], function () {
    return [{
      type: i1.I18n
    }, {
      type: i1$1.DomSanitizer
    }, {
      type: i2.Router,
      decorators: [{
        type: Optional
      }]
    }];
  }, {
    skipTo: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    brand: [{
      type: Input
    }],
    href: [{
      type: Input
    }],
    route: [{
      type: Input
    }],
    routeExtras: [{
      type: Input
    }],
    useRouter: [{
      type: Input
    }],
    navigation: [{
      type: Output
    }]
  });
})();

/**
 * Individual item in the header. May be used a direct child of `HeaderNavigation` or `HeaderMenu`
 */
class HeaderItem {
  constructor(domSanitizer, router) {
    this.domSanitizer = domSanitizer;
    this.router = router;
    this.role = "listitem";
    /**
     * Use the routerLink attribute on <a> tag for navigation instead of using event handlers
     */
    this.useRouter = false;
    /**
     * Emits the navigation status promise when the link is activated
     */
    this.navigation = new EventEmitter();
    this._href = "#";
  }
  set href(v) {
    // Needed when component is created dynamically with a model.
    if (v === undefined) {
      return;
    }
    this._href = v;
  }
  get href() {
    return this.domSanitizer.bypassSecurityTrustUrl(this._href);
  }
  navigate(event) {
    if (this.router && this.route) {
      event.preventDefault();
      const status = this.router.navigate(this.route, this.routeExtras);
      this.navigation.emit(status);
    } else if (this._href === "#") {
      event.preventDefault();
    }
  }
}
HeaderItem.ɵfac = function HeaderItem_Factory(t) {
  return new (t || HeaderItem)(i0.ɵɵdirectiveInject(i1$1.DomSanitizer), i0.ɵɵdirectiveInject(i2.Router, 8));
};
HeaderItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: HeaderItem,
  selectors: [["cds-header-item"], ["ibm-header-item"]],
  hostVars: 1,
  hostBindings: function HeaderItem_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("role", ctx.role);
    }
  },
  inputs: {
    href: "href",
    useRouter: "useRouter",
    activeLinkClass: "activeLinkClass",
    isCurrentPage: "isCurrentPage",
    route: "route",
    routeExtras: "routeExtras"
  },
  outputs: {
    navigation: "navigation"
  },
  ngContentSelectors: _c4,
  decls: 5,
  vars: 3,
  consts: [[3, "ngSwitch"], ["content", ""], ["class", "cds--header__menu-item", "tabindex", "0", 3, "ngClass", "href", "click", 4, "ngSwitchCase"], ["class", "cds--header__menu-item", "tabindex", "0", 3, "routerLinkActive", "ngClass", "routerLink", 4, "ngSwitchCase"], ["tabindex", "0", 1, "cds--header__menu-item", 3, "ngClass", "href", "click"], [4, "ngTemplateOutlet"], ["tabindex", "0", 1, "cds--header__menu-item", 3, "routerLinkActive", "ngClass", "routerLink"]],
  template: function HeaderItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementContainerStart(0, 0);
      i0.ɵɵtemplate(1, HeaderItem_ng_template_1_Template, 1, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor)(3, HeaderItem_a_3_Template, 2, 5, "a", 2)(4, HeaderItem_a_4_Template, 2, 8, "a", 3);
      i0.ɵɵelementContainerEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngSwitch", ctx.useRouter);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngSwitchCase", false);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngSwitchCase", true);
    }
  },
  dependencies: [i2$1.NgClass, i2$1.NgTemplateOutlet, i2$1.NgSwitch, i2$1.NgSwitchCase, i2.RouterLinkWithHref, i2.RouterLinkActive],
  styles: ["[_nghost-%COMP%]{display:list-item}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeaderItem, [{
    type: Component,
    args: [{
      selector: "cds-header-item, ibm-header-item",
      template: `
		<ng-container [ngSwitch]="useRouter">
			<ng-template #content><ng-content></ng-content></ng-template>
			<a
				*ngSwitchCase="false"
				class="cds--header__menu-item"
				tabindex="0"
				[ngClass]="{'cds--header__menu-item--current' : isCurrentPage}"
				[href]="href"
				(click)="navigate($event)">
				<ng-container *ngTemplateOutlet="content"></ng-container>
			</a>
			<a
				*ngSwitchCase="true"
				class="cds--header__menu-item"
				[routerLinkActive]="['cds--header__menu-item--current']"
				tabindex="0"
				[ngClass]="{'cds--header__menu-item--current' : isCurrentPage}"
				[routerLink]="route"
				[routerLinkActive]="activeLinkClass">
				<ng-container *ngTemplateOutlet="content"></ng-container>
			</a>
		</ng-container>
	`,
      styles: [":host{display:list-item}\n"]
    }]
  }], function () {
    return [{
      type: i1$1.DomSanitizer
    }, {
      type: i2.Router,
      decorators: [{
        type: Optional
      }]
    }];
  }, {
    role: [{
      type: HostBinding,
      args: ["attr.role"]
    }],
    href: [{
      type: Input
    }],
    useRouter: [{
      type: Input
    }],
    activeLinkClass: [{
      type: Input
    }],
    isCurrentPage: [{
      type: Input
    }],
    route: [{
      type: Input
    }],
    routeExtras: [{
      type: Input
    }],
    navigation: [{
      type: Output
    }]
  });
})();

/**
 * Dropdown menu container for navigation items.
 */
class HeaderMenu {
  constructor(domSanitizer, elementRef) {
    this.domSanitizer = domSanitizer;
    this.elementRef = elementRef;
    this.subMenu = true;
    this.role = "listitem";
    this.trigger = "click";
    this.expanded = false;
    this._href = "#";
  }
  set href(v) {
    // Needed when component is created dynamically with a model.
    if (v === undefined) {
      return;
    }
    this._href = v;
  }
  get href() {
    return this.domSanitizer.bypassSecurityTrustUrl(this._href);
  }
  onClick() {
    if (this.trigger === "click") {
      this.expanded = !this.expanded;
    }
  }
  onMouseOver() {
    if (this.trigger === "mouseover") {
      this.expanded = true;
    }
  }
  onMouseOut() {
    if (this.trigger === "mouseover") {
      this.expanded = false;
    }
  }
  onFocusOut(event) {
    if (!this.elementRef.nativeElement.contains(event.relatedTarget)) {
      this.expanded = false;
    }
  }
  navigate(event) {
    if (this._href === "#") {
      event.preventDefault();
    }
  }
}
HeaderMenu.ɵfac = function HeaderMenu_Factory(t) {
  return new (t || HeaderMenu)(i0.ɵɵdirectiveInject(i1$1.DomSanitizer), i0.ɵɵdirectiveInject(i0.ElementRef));
};
HeaderMenu.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: HeaderMenu,
  selectors: [["cds-header-menu"], ["ibm-header-menu"]],
  hostVars: 3,
  hostBindings: function HeaderMenu_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function HeaderMenu_click_HostBindingHandler() {
        return ctx.onClick();
      })("mouseover", function HeaderMenu_mouseover_HostBindingHandler() {
        return ctx.onMouseOver();
      })("mouseout", function HeaderMenu_mouseout_HostBindingHandler() {
        return ctx.onMouseOut();
      })("focusout", function HeaderMenu_focusout_HostBindingHandler($event) {
        return ctx.onFocusOut($event);
      });
    }
    if (rf & 2) {
      i0.ɵɵattribute("role", ctx.role);
      i0.ɵɵclassProp("cds--header__submenu", ctx.subMenu);
    }
  },
  inputs: {
    title: "title",
    href: "href",
    trigger: "trigger",
    headerItems: "headerItems",
    icon: "icon"
  },
  ngContentSelectors: _c4,
  decls: 8,
  vars: 7,
  consts: [["tabindex", "0", "aria-haspopup", "menu", 1, "cds--header__menu-item", "cds--header__menu-title", 3, "href", "click"], [4, "ngIf", "ngIfElse"], ["defaultIcon", ""], [1, "cds--header__menu"], [4, "ngFor", "ngForOf"], [3, "ngTemplateOutlet"], ["width", "12", "height", "7", "aria-hidden", "true", 1, "cds--header__menu-arrow"], ["d", "M6.002 5.55L11.27 0l.726.685L6.003 7 0 .685.726 0z"], [3, "href", "route", "routeExtras"]],
  template: function HeaderMenu_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "a", 0);
      i0.ɵɵlistener("click", function HeaderMenu_Template_a_click_0_listener($event) {
        return ctx.navigate($event);
      });
      i0.ɵɵtext(1);
      i0.ɵɵtemplate(2, HeaderMenu_2_Template, 1, 1, null, 1)(3, HeaderMenu_ng_template_3_Template, 2, 0, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(5, "div", 3);
      i0.ɵɵprojection(6);
      i0.ɵɵtemplate(7, HeaderMenu_ng_container_7_Template, 3, 4, "ng-container", 4);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      const _r2 = i0.ɵɵreference(4);
      i0.ɵɵproperty("href", ctx.href, i0.ɵɵsanitizeUrl);
      i0.ɵɵattribute("aria-expanded", ctx.expanded);
      i0.ɵɵadvance();
      i0.ɵɵtextInterpolate1(" ", ctx.title, " ");
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.icon)("ngIfElse", _r2);
      i0.ɵɵadvance(3);
      i0.ɵɵattribute("aria-label", ctx.title);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngForOf", ctx.headerItems);
    }
  },
  dependencies: [i2$1.NgForOf, i2$1.NgIf, i2$1.NgTemplateOutlet, HeaderItem],
  styles: ["[_nghost-%COMP%]{display:list-item}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeaderMenu, [{
    type: Component,
    args: [{
      selector: "cds-header-menu, ibm-header-menu",
      template: `
		<a
			class="cds--header__menu-item cds--header__menu-title"
			[href]="href"
			tabindex="0"
			aria-haspopup="menu"
			[attr.aria-expanded]="expanded"
			(click)="navigate($event)">
			{{title}}
			<ng-template *ngIf="icon; else defaultIcon" [ngTemplateOutlet]="icon"></ng-template>
			<ng-template #defaultIcon>
				<svg class="cds--header__menu-arrow" width="12" height="7" aria-hidden="true">
					<path d="M6.002 5.55L11.27 0l.726.685L6.003 7 0 .685.726 0z" />
				</svg>
			</ng-template>
		</a>
		<div class="cds--header__menu" [attr.aria-label]="title">
			<ng-content></ng-content>
			<ng-container *ngFor="let headerItem of headerItems">
				<cds-header-item
					[href]="headerItem.href"
					[route]="headerItem.route"
					[routeExtras]="headerItem.routeExtras">
					{{ headerItem.content }}
				</cds-header-item>
			</ng-container>
		</div>
	`,
      styles: [":host{display:list-item}\n"]
    }]
  }], function () {
    return [{
      type: i1$1.DomSanitizer
    }, {
      type: i0.ElementRef
    }];
  }, {
    subMenu: [{
      type: HostBinding,
      args: ["class.cds--header__submenu"]
    }],
    role: [{
      type: HostBinding,
      args: ["attr.role"]
    }],
    title: [{
      type: Input
    }],
    href: [{
      type: Input
    }],
    trigger: [{
      type: Input
    }],
    headerItems: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    onClick: [{
      type: HostListener,
      args: ["click"]
    }],
    onMouseOver: [{
      type: HostListener,
      args: ["mouseover"]
    }],
    onMouseOut: [{
      type: HostListener,
      args: ["mouseout"]
    }],
    onFocusOut: [{
      type: HostListener,
      args: ["focusout", ["$event"]]
    }]
  });
})();

/**
 * Container for header navigation items
 */
class HeaderNavigation {
  constructor() {
    this.height = 100;
  }
}
HeaderNavigation.ɵfac = function HeaderNavigation_Factory(t) {
  return new (t || HeaderNavigation)();
};
HeaderNavigation.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: HeaderNavigation,
  selectors: [["cds-header-navigation"], ["ibm-header-navigation"]],
  hostVars: 2,
  hostBindings: function HeaderNavigation_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵstyleProp("height", ctx.height, "%");
    }
  },
  inputs: {
    ariaLabel: "ariaLabel",
    navigationItems: "navigationItems"
  },
  ngContentSelectors: _c4,
  decls: 4,
  vars: 2,
  consts: [[1, "cds--header__nav"], ["role", "list", 1, "cds--header__menu-bar"], [4, "ngFor", "ngForOf"], [3, "href", "route", "routeExtras", "isCurrentPage", 4, "ngIf"], [3, "href", "title", "trigger", "headerItems", 4, "ngIf"], [3, "href", "route", "routeExtras", "isCurrentPage"], [3, "href", "title", "trigger", "headerItems"]],
  template: function HeaderNavigation_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "nav", 0)(1, "div", 1);
      i0.ɵɵprojection(2);
      i0.ɵɵtemplate(3, HeaderNavigation_ng_container_3_Template, 3, 2, "ng-container", 2);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵattribute("aria-label", ctx.ariaLabel);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngForOf", ctx.navigationItems);
    }
  },
  dependencies: [i2$1.NgForOf, i2$1.NgIf, HeaderItem, HeaderMenu],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeaderNavigation, [{
    type: Component,
    args: [{
      selector: "cds-header-navigation, ibm-header-navigation",
      template: `
		<nav class="cds--header__nav" [attr.aria-label]="ariaLabel">
			<div class="cds--header__menu-bar" role="list">
				<ng-content></ng-content>
				<ng-container *ngFor="let navigationItem of navigationItems">
					<cds-header-item
						*ngIf="navigationItem.type === 'item'"
						[href]="navigationItem.href"
						[route]="navigationItem.route"
						[routeExtras]="navigationItem.routeExtras"
						[isCurrentPage]="!!navigationItem.isCurrentPage">
						{{ navigationItem.content }}
					</cds-header-item>
					<cds-header-menu
						*ngIf="navigationItem.type === 'menu'"
						[href]="navigationItem.href"
						[title]="navigationItem.title"
						[trigger]="navigationItem.trigger ? navigationItem.trigger : 'click'"
						[headerItems]="navigationItem.menuItems">
					</cds-header-menu>
				</ng-container>
			</div>
		</nav>
	`
    }]
  }], null, {
    height: [{
      type: HostBinding,
      args: ["style.height.%"]
    }],
    ariaLabel: [{
      type: Input
    }],
    navigationItems: [{
      type: Input
    }]
  });
})();

/**
 * Container for `HeaderAction`s.
 */
class HeaderGlobal {
  constructor() {
    this.hostClass = true;
  }
}
HeaderGlobal.ɵfac = function HeaderGlobal_Factory(t) {
  return new (t || HeaderGlobal)();
};
HeaderGlobal.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: HeaderGlobal,
  selectors: [["cds-header-global"], ["ibm-header-global"]],
  hostVars: 2,
  hostBindings: function HeaderGlobal_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--header__global", ctx.hostClass);
    }
  },
  ngContentSelectors: _c4,
  decls: 1,
  vars: 0,
  template: function HeaderGlobal_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeaderGlobal, [{
    type: Component,
    args: [{
      selector: "cds-header-global, ibm-header-global",
      template: `
		<ng-content></ng-content>
	`
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ["class.cds--header__global"]
    }]
  });
})();

/**
 * Contained by `HeaderGlobal`. Generally used to trigger `Panel`s
 */
class HeaderAction extends BaseIconButton {
  constructor() {
    super(...arguments);
    /**
     * Toggles the active state. May be used to toggle a `Panel`s active state.
     */
    this.active = false;
    /**
     * "Change" emitter to allow double binding to the `active` Input.
     */
    this.activeChange = new EventEmitter();
    /**
     * Emits when the action has been clicked.
     */
    this.selected = new EventEmitter();
  }
  onClick() {
    this.active = !this.active;
    this.selected.emit(this.active);
    this.activeChange.emit(this.active);
  }
}
HeaderAction.ɵfac = /* @__PURE__ */(() => {
  let ɵHeaderAction_BaseFactory;
  return function HeaderAction_Factory(t) {
    return (ɵHeaderAction_BaseFactory || (ɵHeaderAction_BaseFactory = i0.ɵɵgetInheritedFactory(HeaderAction)))(t || HeaderAction);
  };
})();
HeaderAction.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: HeaderAction,
  selectors: [["cds-header-action"], ["ibm-header-action"]],
  inputs: {
    description: "description",
    ariaLabel: "ariaLabel",
    active: "active"
  },
  outputs: {
    activeChange: "activeChange",
    selected: "selected"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c4,
  decls: 2,
  vars: 14,
  consts: [[3, "buttonNgClass", "align", "caret", "dropShadow", "highContrast", "isOpen", "enterDelayMs", "leaveDelayMs", "description", "buttonAttributes", "click"]],
  template: function HeaderAction_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "cds-icon-button", 0);
      i0.ɵɵlistener("click", function HeaderAction_Template_cds_icon_button_click_0_listener() {
        return ctx.onClick();
      });
      i0.ɵɵprojection(1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("buttonNgClass", i0.ɵɵpureFunction1(10, _c5, ctx.active))("align", ctx.align)("caret", ctx.caret)("dropShadow", ctx.dropShadow)("highContrast", ctx.highContrast)("isOpen", ctx.isOpen)("enterDelayMs", ctx.enterDelayMs)("leaveDelayMs", ctx.leaveDelayMs)("description", ctx.description)("buttonAttributes", i0.ɵɵpureFunction1(12, _c6, ctx.ariaLabel));
    }
  },
  dependencies: [i1$2.IconButton],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeaderAction, [{
    type: Component,
    args: [{
      selector: "cds-header-action, ibm-header-action",
      template: `
		<cds-icon-button
			[buttonNgClass]="{
				'cds--header__action': true,
				'cds--header__action--active': active
			}"
			(click)="onClick()"
			[align]="align"
			[caret]="caret"
			[dropShadow]="dropShadow"
			[highContrast]="highContrast"
			[isOpen]="isOpen"
			[enterDelayMs]="enterDelayMs"
			[leaveDelayMs]="leaveDelayMs"
			[description]="description"
			[buttonAttributes]="{
				'aria-label': ariaLabel
			}">
			<ng-content></ng-content>
		</cds-icon-button>
	`
    }]
  }], null, {
    description: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    active: [{
      type: Input
    }],
    activeChange: [{
      type: Output
    }],
    selected: [{
      type: Output
    }]
  });
})();

/**
 * A toggle for the side navigation
 */
class Hamburger {
  constructor(i18n) {
    this.i18n = i18n;
    /**
     * Controls the active/selected state for the `Hamburger` menu.
     */
    this.active = false;
    /**
     * `EventEmitter` to notify parent components of menu click events.
     */
    this.selected = new EventEmitter();
  }
  /**
   * Emit the Hamburger click event upwards.
   */
  doClick() {
    this.selected.emit(this.active);
  }
}
Hamburger.ɵfac = function Hamburger_Factory(t) {
  return new (t || Hamburger)(i0.ɵɵdirectiveInject(i1.I18n));
};
Hamburger.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Hamburger,
  selectors: [["cds-hamburger"], ["ibm-hamburger"]],
  inputs: {
    active: "active"
  },
  outputs: {
    selected: "selected"
  },
  decls: 7,
  vars: 15,
  consts: [["type", "button", 1, "cds--header__menu-trigger", "cds--header__action", "cds--header__menu-toggle", 3, "ngClass", "click"], ["cdsIcon", "menu", "size", "20", 4, "ngIf"], ["cdsIcon", "close", "size", "20", 4, "ngIf"], ["cdsIcon", "menu", "size", "20"], ["cdsIcon", "close", "size", "20"]],
  template: function Hamburger_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "button", 0);
      i0.ɵɵlistener("click", function Hamburger_Template_button_click_0_listener() {
        return ctx.doClick();
      });
      i0.ɵɵpipe(1, "async");
      i0.ɵɵpipe(2, "async");
      i0.ɵɵpipe(3, "async");
      i0.ɵɵpipe(4, "async");
      i0.ɵɵtemplate(5, Hamburger__svg_svg_5_Template, 1, 0, "svg", 1)(6, Hamburger__svg_svg_6_Template, 1, 0, "svg", 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(13, _c7, ctx.active));
      i0.ɵɵattribute("aria-label", ctx.active ? i0.ɵɵpipeBind1(1, 5, ctx.i18n.get("UI_SHELL.HEADER.CLOSE_MENU")) : i0.ɵɵpipeBind1(2, 7, ctx.i18n.get("UI_SHELL.HEADER.OPEN_MENU")))("title", ctx.active ? i0.ɵɵpipeBind1(3, 9, ctx.i18n.get("UI_SHELL.HEADER.CLOSE_MENU")) : i0.ɵɵpipeBind1(4, 11, ctx.i18n.get("UI_SHELL.HEADER.OPEN_MENU")));
      i0.ɵɵadvance(5);
      i0.ɵɵproperty("ngIf", !ctx.active);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.active);
    }
  },
  dependencies: [i2$1.NgClass, i2$1.NgIf, i3.IconDirective, i2$1.AsyncPipe],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Hamburger, [{
    type: Component,
    args: [{
      selector: "cds-hamburger, ibm-hamburger",
      template: `
		<button
			type="button"
			(click)="doClick()"
			[ngClass]="{'cds--header__action--active': active}"
			class="cds--header__menu-trigger cds--header__action cds--header__menu-toggle"
			[attr.aria-label]="active
				? (i18n.get('UI_SHELL.HEADER.CLOSE_MENU') | async)
				: (i18n.get('UI_SHELL.HEADER.OPEN_MENU') | async)"
			[attr.title]="active
				? (i18n.get('UI_SHELL.HEADER.CLOSE_MENU') | async)
				: (i18n.get('UI_SHELL.HEADER.OPEN_MENU') | async)">
			<svg *ngIf="!active" cdsIcon="menu" size="20"></svg>
			<svg *ngIf="active" cdsIcon="close" size="20"></svg>
		</button>
	`
    }]
  }], function () {
    return [{
      type: i1.I18n
    }];
  }, {
    active: [{
      type: Input
    }],
    selected: [{
      type: Output
    }]
  });
})();
class HeaderModule {}
HeaderModule.ɵfac = function HeaderModule_Factory(t) {
  return new (t || HeaderModule)();
};
HeaderModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: HeaderModule
});
HeaderModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, ButtonModule, I18nModule, IconModule, RouterModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeaderModule, [{
    type: NgModule,
    args: [{
      declarations: [Header, HeaderItem, HeaderMenu, HeaderNavigation, HeaderGlobal, HeaderAction, Hamburger],
      imports: [CommonModule, ButtonModule, I18nModule, IconModule, RouterModule],
      exports: [Header, HeaderItem, HeaderMenu, HeaderNavigation, HeaderGlobal, HeaderAction, Hamburger]
    }]
  }], null, null);
})();

/**
 * `SideNavItem` can either be a child of `SideNav` or `SideNavMenu`
 */
class SideNavItem {
  constructor(domSanitizer, router) {
    this.domSanitizer = domSanitizer;
    this.router = router;
    /**
     * Toggles the active (current page) state for the link.
     */
    this.active = false;
    this.isSubMenu = false;
    /**
     * Emits the navigation status promise when the link is activated
     */
    this.navigation = new EventEmitter();
    /**
     * Emits when `active` input is changed. This is mainly used to indicate to any parent menu items that a
     * child sidenav item is active or not active.
     */
    this.selected = new EventEmitter();
    this.role = "listitem";
    this._href = "#";
  }
  /**
   * Link for the item. NOTE: *do not* pass unsafe or untrusted values, this has the potential to open you up to XSS attacks
   */
  set href(v) {
    // Needed when component is created dynamically with a model.
    if (v === undefined) {
      return;
    }
    this._href = v;
  }
  get href() {
    return this.domSanitizer.bypassSecurityTrustUrl(this._href);
  }
  get sideNav() {
    return !this.isSubMenu;
  }
  get menuItem() {
    return this.isSubMenu;
  }
  ngOnChanges(changes) {
    if (changes.active) {
      this.selected.emit(this.active);
    }
  }
  navigate(event) {
    if (this.router && this.route) {
      event.preventDefault();
      const status = this.router.navigate(this.route, this.routeExtras);
      this.navigation.emit(status);
    } else if (this._href === "#") {
      event.preventDefault();
    }
  }
}
SideNavItem.ɵfac = function SideNavItem_Factory(t) {
  return new (t || SideNavItem)(i0.ɵɵdirectiveInject(i1$1.DomSanitizer), i0.ɵɵdirectiveInject(i2.Router, 8));
};
SideNavItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SideNavItem,
  selectors: [["cds-sidenav-item"], ["ibm-sidenav-item"]],
  hostVars: 5,
  hostBindings: function SideNavItem_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("role", ctx.role);
      i0.ɵɵclassProp("cds--side-nav__item", ctx.sideNav)("cds--side-nav__menu-item", ctx.menuItem);
    }
  },
  inputs: {
    href: "href",
    active: "active",
    route: "route",
    isSubMenu: "isSubMenu",
    routeExtras: "routeExtras",
    title: "title"
  },
  outputs: {
    navigation: "navigation",
    selected: "selected"
  },
  features: [i0.ɵɵNgOnChangesFeature],
  ngContentSelectors: _c10,
  decls: 4,
  vars: 7,
  consts: [[1, "cds--side-nav__link", 3, "ngClass", "href", "click"], ["class", "cds--side-nav__icon", 4, "ngIf"], [1, "cds--side-nav__link-text"], [1, "cds--side-nav__icon"]],
  template: function SideNavItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c8);
      i0.ɵɵelementStart(0, "a", 0);
      i0.ɵɵlistener("click", function SideNavItem_Template_a_click_0_listener($event) {
        return ctx.navigate($event);
      });
      i0.ɵɵtemplate(1, SideNavItem_div_1_Template, 2, 0, "div", 1);
      i0.ɵɵelementStart(2, "span", 2);
      i0.ɵɵprojection(3);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(5, _c9, ctx.active))("href", ctx.href, i0.ɵɵsanitizeUrl);
      i0.ɵɵattribute("aria-current", ctx.active ? "page" : null)("title", ctx.title ? ctx.title : null);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.isSubMenu);
    }
  },
  dependencies: [i2$1.NgClass, i2$1.NgIf],
  styles: ["[_nghost-%COMP%]{display:list-item}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SideNavItem, [{
    type: Component,
    args: [{
      selector: "cds-sidenav-item, ibm-sidenav-item",
      template: `
		<a
			class="cds--side-nav__link"
			[ngClass]="{
				'cds--side-nav__item--active': active
			}"
			[href]="href"
			[attr.aria-current]="(active ? 'page' : null)"
			[attr.title]="title ? title : null"
			(click)="navigate($event)">
			<div *ngIf="!isSubMenu" class="cds--side-nav__icon">
				<ng-content select="svg, [icon]"></ng-content>
			</div>
			<span class="cds--side-nav__link-text">
				<ng-content></ng-content>
			</span>
		</a>
	`,
      styles: [":host{display:list-item}\n"]
    }]
  }], function () {
    return [{
      type: i1$1.DomSanitizer
    }, {
      type: i2.Router,
      decorators: [{
        type: Optional
      }]
    }];
  }, {
    href: [{
      type: Input
    }],
    sideNav: [{
      type: HostBinding,
      args: ["class.cds--side-nav__item"]
    }],
    menuItem: [{
      type: HostBinding,
      args: ["class.cds--side-nav__menu-item"]
    }],
    active: [{
      type: Input
    }],
    route: [{
      type: Input
    }],
    isSubMenu: [{
      type: Input
    }],
    routeExtras: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    navigation: [{
      type: Output
    }],
    selected: [{
      type: Output
    }],
    role: [{
      type: HostBinding,
      args: ["attr.role"]
    }]
  });
})();

/**
 * `SideNavMenu` provides a method to group `SideNavItem`s under a common heading.
 */
class SideNavMenu {
  constructor() {
    this.navItem = true;
    this.navItemIcon = true;
    this.role = "listitem";
    /**
     * Controls the visibility of the child `SideNavItem`s
     */
    this.expanded = false;
    /**
     * Controls the active status indicator on the menu if there is an active
     * child sidenav item.
     */
    this.hasActiveChild = false;
    this.activeItemsSubscription = new Subscription();
  }
  get navItemActive() {
    return this.hasActiveChild;
  }
  ngAfterContentInit() {
    setTimeout(() => {
      this.sidenavItems.forEach(item => {
        item.isSubMenu = true;
        this.findActiveChildren();
        const activeItemSubscription = item.selected.subscribe(() => {
          this.findActiveChildren();
        });
        this.activeItemsSubscription.add(activeItemSubscription);
      });
      this.sidenavItems.changes.subscribe(() => {
        this.sidenavItems.forEach(item => {
          item.isSubMenu = true;
          this.findActiveChildren();
          const activeItemSubscription = item.selected.subscribe(() => {
            this.findActiveChildren();
          });
          this.activeItemsSubscription.add(activeItemSubscription);
        });
      });
    });
  }
  ngOnDestroy() {
    this.activeItemsSubscription.unsubscribe();
  }
  toggle() {
    this.expanded = !this.expanded;
  }
  findActiveChildren() {
    this.hasActiveChild = this.sidenavItems.some(item => item.active);
  }
}
SideNavMenu.ɵfac = function SideNavMenu_Factory(t) {
  return new (t || SideNavMenu)();
};
SideNavMenu.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SideNavMenu,
  selectors: [["cds-sidenav-menu"], ["ibm-sidenav-menu"]],
  contentQueries: function SideNavMenu_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, SideNavItem, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.sidenavItems = _t);
    }
  },
  hostVars: 7,
  hostBindings: function SideNavMenu_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("role", ctx.role);
      i0.ɵɵclassProp("cds--side-nav__item", ctx.navItem)("cds--side-nav__item--icon", ctx.navItemIcon)("cds--side-nav__item--active", ctx.navItemActive);
    }
  },
  inputs: {
    title: "title",
    expanded: "expanded",
    hasActiveChild: "hasActiveChild",
    menuItems: "menuItems"
  },
  ngContentSelectors: _c12,
  decls: 11,
  vars: 3,
  consts: [["aria-haspopup", "true", "type", "button", 1, "cds--side-nav__submenu", 3, "click"], [1, "cds--side-nav__icon"], [1, "cds--side-nav__submenu-title"], [1, "cds--side-nav__icon", "cds--side-nav__icon--small", "cds--side-nav__submenu-chevron"], ["focusable", "false", "preserveAspectRatio", "xMidYMid meet", "xmlns", "http://www.w3.org/2000/svg", "width", "20", "height", "20", "viewBox", "0 0 32 32", "aria-hidden", "true", 2, "will-change", "transform"], ["d", "M16 22L6 12l1.4-1.4 8.6 8.6 8.6-8.6L26 12z"], ["role", "list", 1, "cds--side-nav__menu"], [4, "ngFor", "ngForOf"], [3, "href", "route", "routeExtras", "isSubMenu"]],
  template: function SideNavMenu_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c11);
      i0.ɵɵelementStart(0, "button", 0);
      i0.ɵɵlistener("click", function SideNavMenu_Template_button_click_0_listener() {
        return ctx.toggle();
      });
      i0.ɵɵelementStart(1, "div", 1);
      i0.ɵɵprojection(2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(3, "span", 2);
      i0.ɵɵtext(4);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(5, "div", 3);
      i0.ɵɵnamespaceSVG();
      i0.ɵɵelementStart(6, "svg", 4);
      i0.ɵɵelement(7, "path", 5);
      i0.ɵɵelementEnd()()();
      i0.ɵɵnamespaceHTML();
      i0.ɵɵelementStart(8, "div", 6);
      i0.ɵɵprojection(9, 1);
      i0.ɵɵtemplate(10, SideNavMenu_ng_container_10_Template, 3, 5, "ng-container", 7);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵattribute("aria-expanded", ctx.expanded);
      i0.ɵɵadvance(4);
      i0.ɵɵtextInterpolate(ctx.title);
      i0.ɵɵadvance(6);
      i0.ɵɵproperty("ngForOf", ctx.menuItems);
    }
  },
  dependencies: [i2$1.NgForOf, SideNavItem],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SideNavMenu, [{
    type: Component,
    args: [{
      selector: "cds-sidenav-menu, ibm-sidenav-menu",
      template: `
		<button
			(click)="toggle()"
			class="cds--side-nav__submenu"
			aria-haspopup="true"
			[attr.aria-expanded]="expanded"
			type="button">
			<div class="cds--side-nav__icon">
				<ng-content select="svg, [icon]"></ng-content>
			</div>
			<span class="cds--side-nav__submenu-title">{{title}}</span>
			<div class="cds--side-nav__icon cds--side-nav__icon--small cds--side-nav__submenu-chevron">
				<svg
					focusable="false"
					preserveAspectRatio="xMidYMid meet"
					style="will-change: transform;"
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="20"
					viewBox="0 0 32 32"
					aria-hidden="true">
					<path d="M16 22L6 12l1.4-1.4 8.6 8.6 8.6-8.6L26 12z"></path>
				</svg>
			</div>
		</button>
		<div class="cds--side-nav__menu" role="list">
			<ng-content></ng-content>
			<ng-container *ngFor="let menuItem of menuItems">
				<cds-sidenav-item
					[href]="menuItem.href"
					[route]="menuItem.route"
					[routeExtras]="menuItem.routeExtras"
					[isSubMenu]="true">
					{{ menuItem.content }}
				</cds-sidenav-item>
			</ng-container>
		</div>
	`
    }]
  }], null, {
    navItem: [{
      type: HostBinding,
      args: ["class.cds--side-nav__item"]
    }],
    navItemIcon: [{
      type: HostBinding,
      args: ["class.cds--side-nav__item--icon"]
    }],
    navItemActive: [{
      type: HostBinding,
      args: ["class.cds--side-nav__item--active"]
    }],
    role: [{
      type: HostBinding,
      args: ["attr.role"]
    }],
    title: [{
      type: Input
    }],
    expanded: [{
      type: Input
    }],
    hasActiveChild: [{
      type: Input
    }],
    menuItems: [{
      type: Input
    }],
    sidenavItems: [{
      type: ContentChildren,
      args: [SideNavItem]
    }]
  });
})();

/**
 * `Sidenav` is a fixed left navigation that may contain `SideNavItem`s or `SideNavMenu`s
 *
 * [See demo](../../?path=/story/components-ui-shell--side-navigation)
 */
class SideNav {
  constructor(i18n) {
    this.i18n = i18n;
    this.hostClass = true;
    this.ariaLabel = "Side navigation";
    /**
     * Controls the expanded (`true`) or collapsed (`false`) state when on a small screen.
     */
    this.expanded = true;
    /**
     * Controls the hidden (`true`) or visible (`false`) state
     */
    this.hidden = false;
    this.rail = false;
    this.ux = true;
    this.allowExpansion = false;
  }
  toggle() {
    this.expanded = !this.expanded;
  }
}
SideNav.ɵfac = function SideNav_Factory(t) {
  return new (t || SideNav)(i0.ɵɵdirectiveInject(i1.I18n));
};
SideNav.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SideNav,
  selectors: [["cds-sidenav"], ["ibm-sidenav"]],
  hostVars: 10,
  hostBindings: function SideNav_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--side-nav", ctx.hostClass)("cds--side-nav--expanded", ctx.expanded)("cds--side-nav--hidden", ctx.hidden)("cds--side-nav--rail", ctx.rail)("cds--side-nav__navigation", ctx.ux);
    }
  },
  inputs: {
    ariaLabel: "ariaLabel",
    expanded: "expanded",
    hidden: "hidden",
    rail: "rail",
    allowExpansion: "allowExpansion",
    navigationItems: "navigationItems"
  },
  ngContentSelectors: _c14,
  decls: 8,
  vars: 3,
  consts: [[1, "cds--side-nav__items"], ["role", "list"], [1, "cds--side-nav__header-navigation", "cds--side-nav__header-divider"], [4, "ngFor", "ngForOf"], [1, "cds--side-nav__footer"], ["class", "cds--side-nav__toggle", "type", "button", 3, "title", "click", 4, "ngIf"], [3, "href", "route", "routeExtras", "title", 4, "ngIf"], [3, "title", "menuItems", 4, "ngIf"], [3, "href", "route", "routeExtras", "title"], [3, "title", "menuItems"], ["type", "button", 1, "cds--side-nav__toggle", 3, "title", "click"], [1, "cds--side-nav__icon"], ["focusable", "false", "preserveAspectRatio", "xMidYMid meet", "style", "will-change: transform;", "xmlns", "http://www.w3.org/2000/svg", "width", "20", "height", "20", "viewBox", "0 0 32 32", "aria-hidden", "true", 4, "ngIf"], [1, "cds--assistive-text"], ["focusable", "false", "preserveAspectRatio", "xMidYMid meet", "xmlns", "http://www.w3.org/2000/svg", "width", "20", "height", "20", "viewBox", "0 0 32 32", "aria-hidden", "true", 2, "will-change", "transform"], ["d", "M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4l6.6 6.6L8 22.6 9.4 24l6.6-6.6 6.6 6.6 1.4-1.4-6.6-6.6L24 9.4z"], ["d", "M22 16L12 26l-1.4-1.4 8.6-8.6-8.6-8.6L12 6z"]],
  template: function SideNav_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c13);
      i0.ɵɵelementStart(0, "nav", 0);
      i0.ɵɵprojection(1);
      i0.ɵɵelementStart(2, "div", 1)(3, "div", 2);
      i0.ɵɵtemplate(4, SideNav_ng_container_4_Template, 3, 2, "ng-container", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵprojection(5, 1);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(6, "footer", 4);
      i0.ɵɵtemplate(7, SideNav_button_7_Template, 8, 8, "button", 5);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵattribute("aria-label", ctx.ariaLabel);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("ngForOf", ctx.navigationItems);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", ctx.allowExpansion);
    }
  },
  dependencies: [i2$1.NgForOf, i2$1.NgIf, SideNavItem, SideNavMenu, i2$1.AsyncPipe],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SideNav, [{
    type: Component,
    args: [{
      selector: "cds-sidenav, ibm-sidenav",
      template: `
		<nav class="cds--side-nav__items" [attr.aria-label]="ariaLabel">
			<ng-content select="cds-sidenav-header,ibm-sidenav-header"></ng-content>
			<div role="list">
				<div class="cds--side-nav__header-navigation cds--side-nav__header-divider">
					<ng-container *ngFor="let navigationItem of navigationItems">
						<cds-sidenav-item
							*ngIf="navigationItem.type === 'item'"
							[href]="navigationItem.href"
							[route]="navigationItem.route"
							[routeExtras]="navigationItem.routeExtras"
							[title]="navigationItem.title">
							{{ navigationItem.content }}
						</cds-sidenav-item>
						<cds-sidenav-menu
							*ngIf="navigationItem.type === 'menu'"
							[title]="navigationItem.title"
							[menuItems]="navigationItem.menuItems">
						</cds-sidenav-menu>
					</ng-container>
				</div>
				<ng-content></ng-content>
			</div>
			<footer class="cds--side-nav__footer">
				<button
					*ngIf="allowExpansion"
					class="cds--side-nav__toggle"
					type="button"
					[title]="(expanded ? i18n.get('UI_SHELL.SIDE_NAV.TOGGLE_CLOSE') : i18n.get('UI_SHELL.SIDE_NAV.TOGGLE_OPEN')) | async"
					(click)="toggle()">
					<div class="cds--side-nav__icon">
						<svg
							*ngIf="expanded"
							focusable="false"
							preserveAspectRatio="xMidYMid meet"
							style="will-change: transform;"
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 32 32"
							aria-hidden="true">
							<path d="M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4l6.6 6.6L8 22.6 9.4 24l6.6-6.6 6.6 6.6 1.4-1.4-6.6-6.6L24 9.4z"></path>
						</svg>
						<svg
							*ngIf="!expanded"
							focusable="false"
							preserveAspectRatio="xMidYMid meet"
							style="will-change: transform;"
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 32 32"
							aria-hidden="true">
							<path d="M22 16L12 26l-1.4-1.4 8.6-8.6-8.6-8.6L12 6z"></path>
						</svg>
					</div>
					<span class="cds--assistive-text">
						{{(expanded ? i18n.get('UI_SHELL.SIDE_NAV.TOGGLE_CLOSE') : i18n.get('UI_SHELL.SIDE_NAV.TOGGLE_OPEN')) | async}}
					</span>
				</button>
			</footer>
		</nav>
	`,
      encapsulation: ViewEncapsulation.None
    }]
  }], function () {
    return [{
      type: i1.I18n
    }];
  }, {
    hostClass: [{
      type: HostBinding,
      args: ["class.cds--side-nav"]
    }],
    ariaLabel: [{
      type: Input
    }],
    expanded: [{
      type: HostBinding,
      args: ["class.cds--side-nav--expanded"]
    }, {
      type: Input
    }],
    hidden: [{
      type: HostBinding,
      args: ["class.cds--side-nav--hidden"]
    }, {
      type: Input
    }],
    rail: [{
      type: HostBinding,
      args: ["class.cds--side-nav--rail"]
    }, {
      type: Input
    }],
    ux: [{
      type: HostBinding,
      args: ["class.cds--side-nav__navigation"]
    }],
    allowExpansion: [{
      type: Input
    }],
    navigationItems: [{
      type: Input
    }]
  });
})();
class SideNavModule {}
SideNavModule.ɵfac = function SideNavModule_Factory(t) {
  return new (t || SideNavModule)();
};
SideNavModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SideNavModule
});
SideNavModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, I18nModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SideNavModule, [{
    type: NgModule,
    args: [{
      declarations: [SideNav, SideNavItem, SideNavMenu],
      imports: [CommonModule, I18nModule],
      exports: [SideNav, SideNavItem, SideNavMenu]
    }]
  }], null, null);
})();

/**
 * `Panel` is a component that can be used to display content on the right side of the screen.
 * `Panel`s are generally activated by and linked to a `HeaderAction` component.
 */
class Panel {
  constructor() {
    /**
     * Controls the visibility of the panel
     */
    this.expanded = false;
  }
}
Panel.ɵfac = function Panel_Factory(t) {
  return new (t || Panel)();
};
Panel.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Panel,
  selectors: [["cds-panel"], ["ibm-panel"]],
  inputs: {
    expanded: "expanded",
    ariaLabel: "ariaLabel"
  },
  ngContentSelectors: _c4,
  decls: 2,
  vars: 4,
  consts: [[1, "cds--header-panel", 3, "ngClass"]],
  template: function Panel_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵprojection(1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(2, _c15, ctx.expanded));
      i0.ɵɵattribute("aria-label", ctx.ariaLabel);
    }
  },
  dependencies: [i2$1.NgClass],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Panel, [{
    type: Component,
    args: [{
      selector: "cds-panel, ibm-panel",
      template: `
		<div
			class="cds--header-panel"
			[attr.aria-label]="ariaLabel"
			[ngClass]="{
				'cds--header-panel--expanded': expanded
			}">
			<ng-content></ng-content>
		</div>
	`
    }]
  }], null, {
    expanded: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }]
  });
})();

/**
 * Container for switcher items.
 *
 * ```html
 * <cds-switcher-list>
 * 	<cds-switcher-list-item>one</cds-switcher-list-item>
 * 	<cds-switcher-list-item [active]="true">two</cds-switcher-list-item>
 * 	<cds-switcher-list-item>three</cds-switcher-list-item>
 * </cds-switcher-list>
 * ```
 */
class SwitcherList {
  constructor() {
    this.switcher = true;
    this.role = "list";
  }
}
SwitcherList.ɵfac = function SwitcherList_Factory(t) {
  return new (t || SwitcherList)();
};
SwitcherList.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SwitcherList,
  selectors: [["cds-switcher-list"], ["ibm-switcher-list"]],
  hostVars: 3,
  hostBindings: function SwitcherList_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("role", ctx.role);
      i0.ɵɵclassProp("cds--switcher", ctx.switcher);
    }
  },
  ngContentSelectors: _c4,
  decls: 1,
  vars: 0,
  template: function SwitcherList_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  styles: ["[_nghost-%COMP%]{display:block}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SwitcherList, [{
    type: Component,
    args: [{
      selector: "cds-switcher-list, ibm-switcher-list",
      template: `
			<ng-content></ng-content>
	`,
      styles: [":host{display:block}\n"]
    }]
  }], null, {
    switcher: [{
      type: HostBinding,
      args: ["class.cds--switcher"]
    }],
    role: [{
      type: HostBinding,
      args: ["attr.role"]
    }]
  });
})();

/**
 * Represents an item in a switcher list.
 */
class SwitcherListItem {
  constructor(domSanitizer, router) {
    this.domSanitizer = domSanitizer;
    this.router = router;
    /**
     * Enables the "active" state for an item. Commonly used to indicate the current page or selection.
     */
    this.active = false;
    /**
     * Emits the navigation status promise when the link is activated
     */
    this.navigation = new EventEmitter();
    this.itemClass = true;
    this.itemRole = "listitem";
    this._href = "#";
    this._target = "";
  }
  /**
   * Optional link for the underlying anchor.
   */
  set href(value) {
    this._href = value;
  }
  get href() {
    return this.domSanitizer.bypassSecurityTrustUrl(this._href);
  }
  /**
   * Optional target for the underlying anchor.
   */
  set target(value) {
    this._target = value;
  }
  get target() {
    return this._target;
  }
  navigate(event) {
    if (this.router && this.route) {
      event.preventDefault();
      const status = this.router.navigate(this.route, this.routeExtras);
      this.navigation.emit(status);
    } else if (this._href === "#") {
      event.preventDefault();
    }
  }
}
SwitcherListItem.ɵfac = function SwitcherListItem_Factory(t) {
  return new (t || SwitcherListItem)(i0.ɵɵdirectiveInject(i1$1.DomSanitizer), i0.ɵɵdirectiveInject(i2.Router, 8));
};
SwitcherListItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SwitcherListItem,
  selectors: [["cds-switcher-list-item"], ["ibm-switcher-list-item"]],
  hostVars: 3,
  hostBindings: function SwitcherListItem_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("role", ctx.itemRole);
      i0.ɵɵclassProp("cds--switcher__item", ctx.itemClass);
    }
  },
  inputs: {
    active: "active",
    route: "route",
    routeExtras: "routeExtras",
    href: "href",
    target: "target"
  },
  outputs: {
    navigation: "navigation"
  },
  ngContentSelectors: _c4,
  decls: 2,
  vars: 5,
  consts: [[1, "cds--switcher__item-link", 3, "ngClass", "href", "target", "click"]],
  template: function SwitcherListItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "a", 0);
      i0.ɵɵlistener("click", function SwitcherListItem_Template_a_click_0_listener($event) {
        return ctx.navigate($event);
      });
      i0.ɵɵprojection(1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c16, ctx.active))("href", ctx.href, i0.ɵɵsanitizeUrl)("target", ctx.target);
    }
  },
  dependencies: [i2$1.NgClass],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SwitcherListItem, [{
    type: Component,
    args: [{
      selector: "cds-switcher-list-item, ibm-switcher-list-item",
      template: `
		<a
			class="cds--switcher__item-link"
			[ngClass]="{
				'cds--switcher__item-link--selected': active
			}"
			[href]="href"
			[target]="target"
			(click)="navigate($event)">
			<ng-content></ng-content>
		</a>
	`
    }]
  }], function () {
    return [{
      type: i1$1.DomSanitizer
    }, {
      type: i2.Router,
      decorators: [{
        type: Optional
      }]
    }];
  }, {
    active: [{
      type: Input
    }],
    route: [{
      type: Input
    }],
    routeExtras: [{
      type: Input
    }],
    href: [{
      type: Input
    }],
    navigation: [{
      type: Output
    }],
    target: [{
      type: Input
    }],
    itemClass: [{
      type: HostBinding,
      args: ["class.cds--switcher__item"]
    }],
    itemRole: [{
      type: HostBinding,
      args: ["attr.role"]
    }]
  });
})();
class PanelModule {}
PanelModule.ɵfac = function PanelModule_Factory(t) {
  return new (t || PanelModule)();
};
PanelModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: PanelModule
});
PanelModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, I18nModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PanelModule, [{
    type: NgModule,
    args: [{
      declarations: [Panel, SwitcherList, SwitcherListItem],
      imports: [CommonModule, I18nModule],
      exports: [Panel, SwitcherList, SwitcherListItem]
    }]
  }], null, null);
})();
class UIShellModule {}
UIShellModule.ɵfac = function UIShellModule_Factory(t) {
  return new (t || UIShellModule)();
};
UIShellModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: UIShellModule
});
UIShellModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, I18nModule, HeaderModule, SideNavModule, PanelModule, HeaderModule, SideNavModule, PanelModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UIShellModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, I18nModule, HeaderModule, SideNavModule, PanelModule],
      exports: [HeaderModule, SideNavModule, PanelModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Hamburger, Header, HeaderAction, HeaderGlobal, HeaderItem, HeaderMenu, HeaderModule, HeaderNavigation, Panel, PanelModule, SideNav, SideNavItem, SideNavMenu, SideNavModule, SwitcherList, SwitcherListItem, UIShellModule };
