import * as i0 from '@angular/core';
import { Directive, Input, HostBinding, ContentChildren, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
const MAX_LEVEL = 2;
/**
 * Applies layering styles to the div container it is applied to. Get started with importing the module:
 *
 * ```typescript
 * import { LayerModule } from 'carbon-components-angular';
 * ```
 *
 * [See demo](../../?path=/story/components-layer--basic)
 */
class LayerDirective {
  /**
   * @deprecated as of v5 - Use `cdsLayer` input property instead
   */
  set ibmLayer(level) {
    this.cdsLayer = level;
  }
  /**
   * Override layer level
   * Empty string has been added as an option for Angular 16+ to resolve type errors
   */
  set cdsLayer(level) {
    if (typeof level === "number") {
      this._passedLevel = level;
      this.layer = level;
    }
  }
  get cdsLayer() {
    return this._passedLevel;
  }
  /**
   * If cdsLayer is undefined, auto increment & iterate level
   */
  set layer(level) {
    if (typeof level === "number") {
      this._level = Math.max(0, Math.min(level, MAX_LEVEL));
      if (this.layerChildren) {
        this.layerChildren.forEach(layer => {
          // Ignore self to prevent infinite recursion
          if (layer === this) {
            return;
          }
          layer.layer = typeof layer._passedLevel === "number" ? layer._passedLevel : this.layer + 1;
        });
      }
    }
  }
  get layer() {
    return this._level;
  }
  /**
   * Using host bindings with classes to ensure we do not
   * overwrite user added classes
   */
  get layerOneClass() {
    return this.layer === 0;
  }
  get layerTwoClass() {
    return this.layer === 1;
  }
  get layerThreeClass() {
    return this.layer === 2;
  }
  ngAfterContentInit() {
    if (typeof this.cdsLayer !== "number") {
      this.layer = 1;
    }
  }
}
LayerDirective.ɵfac = function LayerDirective_Factory(t) {
  return new (t || LayerDirective)();
};
LayerDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LayerDirective,
  selectors: [["", "cdsLayer", ""], ["", "ibmLayer", ""]],
  contentQueries: function LayerDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, LayerDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.layerChildren = _t);
    }
  },
  hostVars: 6,
  hostBindings: function LayerDirective_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--layer-one", ctx.layerOneClass)("cds--layer-two", ctx.layerTwoClass)("cds--layer-three", ctx.layerThreeClass);
    }
  },
  inputs: {
    ibmLayer: "ibmLayer",
    cdsLayer: "cdsLayer"
  },
  exportAs: ["layer"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LayerDirective, [{
    type: Directive,
    args: [{
      selector: "[cdsLayer], [ibmLayer]",
      exportAs: "layer"
    }]
  }], null, {
    ibmLayer: [{
      type: Input
    }],
    cdsLayer: [{
      type: Input
    }],
    layerOneClass: [{
      type: HostBinding,
      args: ["class.cds--layer-one"]
    }],
    layerTwoClass: [{
      type: HostBinding,
      args: ["class.cds--layer-two"]
    }],
    layerThreeClass: [{
      type: HostBinding,
      args: ["class.cds--layer-three"]
    }],
    layerChildren: [{
      type: ContentChildren,
      args: [LayerDirective, {
        descendants: false
      }]
    }]
  });
})();
class LayerModule {}
LayerModule.ɵfac = function LayerModule_Factory(t) {
  return new (t || LayerModule)();
};
LayerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: LayerModule
});
LayerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LayerModule, [{
    type: NgModule,
    args: [{
      declarations: [LayerDirective],
      exports: [LayerDirective],
      imports: [CommonModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { LayerDirective, LayerModule };
