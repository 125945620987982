import * as i0 from '@angular/core';
import { Injectable, ViewContainerRef, Component, Input, ViewChild, Optional, SkipSelf, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Singleton service used to register the container for out-of-flow components to insert into.
 * Also used to insert/remove components from that view.
 */
const _c0 = ["placeholder"];
class PlaceholderService {
  constructor() {
    /**
     * Main `ViewContainerRef` to insert components into
     */
    this.viewContainerRef = null;
    /**
     * Map of id's to secondary `ViewContainerRef`s
     */
    this.viewContainerMap = new Map();
  }
  /**
   * Used by `Placeholder` to register view-container reference.
   */
  registerViewContainerRef(vcRef, id) {
    if (id) {
      this.viewContainerMap.set(id, vcRef);
    } else {
      this.viewContainerRef = vcRef;
    }
  }
  /**
   * Creates and returns component in the view.
   */
  createComponent(component, injector, id) {
    if (id) {
      if (!this.viewContainerMap.has(id)) {
        console.error(`No view container with id ${id} found`);
        return;
      }
      return this.viewContainerMap.get(id).createComponent(component, {
        index: this.viewContainerMap.size,
        injector
      });
    }
    if (!this.viewContainerRef) {
      console.error("No view container defined! Likely due to a missing `cds-placeholder`");
      return;
    }
    return this.viewContainerRef.createComponent(component, {
      index: this.viewContainerRef.length,
      injector
    });
  }
  destroyComponent(component) {
    component.destroy();
  }
  hasComponentRef(component, id) {
    if (id) {
      return !(this.viewContainerMap.get(id).indexOf(component.hostView) < 0);
    }
    return !(this.viewContainerRef.indexOf(component.hostView) < 0);
  }
  hasPlaceholderRef(id) {
    if (id) {
      return this.viewContainerMap.has(id);
    }
    return !!this.viewContainerRef;
  }
  appendElement(element, id) {
    if (id) {
      return this.viewContainerMap.get(id).element.nativeElement.appendChild(element);
    }
    return this.viewContainerRef.element.nativeElement.appendChild(element);
  }
  removeElement(element, id) {
    if (id) {
      return this.viewContainerMap.get(id).element.nativeElement.removeChild(element);
    }
    return this.viewContainerRef.element.nativeElement.removeChild(element);
  }
  hasElement(element, id) {
    if (id) {
      return this.viewContainerMap.get(id).element.nativeElement.contains(element);
    }
    return this.viewContainerRef.element.nativeElement.contains(element);
  }
}
PlaceholderService.ɵfac = function PlaceholderService_Factory(t) {
  return new (t || PlaceholderService)();
};
PlaceholderService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: PlaceholderService,
  factory: PlaceholderService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PlaceholderService, [{
    type: Injectable
  }], null, null);
})();

/**
 * Using a modal, dialog (Tooltip, OverflowMenu), or any other component that draws out of the normal page flow
 * in your application *requires* this component (`cds-placeholder`).
 * It would generally be placed near the end of your root app component template
 * (app.component.ts or app.component.html) as:
 *
 * ```
 * <cds-placeholder></cds-placeholder>
 * ```
 */
class Placeholder {
  /**
   * Creates an instance of `Placeholder`.
   */
  constructor(placeholderService) {
    this.placeholderService = placeholderService;
  }
  /**
   * Registers the components view with `PlaceholderService`
   */
  ngOnInit() {
    // TODO use `id` to register with the placeholderService
    this.placeholderService.registerViewContainerRef(this.viewContainerRef);
  }
}
Placeholder.ɵfac = function Placeholder_Factory(t) {
  return new (t || Placeholder)(i0.ɵɵdirectiveInject(PlaceholderService));
};
Placeholder.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Placeholder,
  selectors: [["cds-placeholder"], ["ibm-placeholder"]],
  viewQuery: function Placeholder_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7, ViewContainerRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.viewContainerRef = _t.first);
    }
  },
  inputs: {
    id: "id"
  },
  decls: 2,
  vars: 0,
  consts: [["placeholder", ""]],
  template: function Placeholder_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "div", null, 0);
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Placeholder, [{
    type: Component,
    args: [{
      selector: "cds-placeholder, ibm-placeholder",
      template: `<div #placeholder></div>`
    }]
  }], function () {
    return [{
      type: PlaceholderService
    }];
  }, {
    id: [{
      type: Input
    }],
    viewContainerRef: [{
      type: ViewChild,
      args: ["placeholder", {
        read: ViewContainerRef,
        static: true
      }]
    }]
  });
})();

// modules
// either provides a new instance of PlaceholderService, or returns the parent
function PLACEHOLDER_SERVICE_PROVIDER_FACTORY(parentService) {
  return parentService || new PlaceholderService();
}
// placeholder service *must* be a singleton to ensure the placeholder viewRef is accessible globally
const PLACEHOLDER_SERVICE_PROVIDER = {
  provide: PlaceholderService,
  deps: [[new Optional(), new SkipSelf(), PlaceholderService]],
  useFactory: PLACEHOLDER_SERVICE_PROVIDER_FACTORY
};
class PlaceholderModule {}
PlaceholderModule.ɵfac = function PlaceholderModule_Factory(t) {
  return new (t || PlaceholderModule)();
};
PlaceholderModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: PlaceholderModule
});
PlaceholderModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [PLACEHOLDER_SERVICE_PROVIDER],
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PlaceholderModule, [{
    type: NgModule,
    args: [{
      declarations: [Placeholder],
      exports: [Placeholder],
      providers: [PLACEHOLDER_SERVICE_PROVIDER],
      imports: [CommonModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PLACEHOLDER_SERVICE_PROVIDER, PLACEHOLDER_SERVICE_PROVIDER_FACTORY, Placeholder, PlaceholderModule, PlaceholderService };
